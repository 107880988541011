import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";



function EditPartnersComp() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const [notify, setNotify] = useState(' ');
    const [partnersinfo, setPartnersInfo] = useState({
        name: " ",
        website: " ",
        logo: " ",
        mou: " ",
    });

    console.log(partnersinfo);

    useEffect(() => {
        const partnerFetcher = () => {
            const partner = `${process.env.REACT_APP_BASE_URL}/api/singlecenterpartners/${id}`
            const getpartner = axios.get(partner);


            axios.all([getpartner]).then(
                axios.spread((...allData) => {
                    // const allcourses = allData[0].data.session;
                    const singses = allData[0].data;
                    // setsession(allcourses)


                    setPartnersInfo({
                        name: singses.name,
                        website: singses.web_url,
                    })
                })
            )

        }

        partnerFetcher();
    }, [])
    const handleAddPartners = async (e) => {
        e.preventDefault()

        var form = new FormData();
        form.append("id", id);
        form.append("name", partnersinfo.name);
        form.append("website", partnersinfo.website);

        var requestOptionsNew = {
            method: 'POST',
            body: form,
            redirect: 'follow'
        };
        setNotify('loading')

        const addSession = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/editcenterpartner`, requestOptionsNew)
            const data = await response.json()
            const status = response.status;
            if (status == 200) {
                setNotify('Partner Updated Succesfully')

                var formdata = new FormData();
                if (dets.permission_type == 'supper') {
                    formdata.append("username", "Super Admin");
                } else {
                    formdata.append("username", dets.name);
                }
                formdata.append("activity", `Edited partner ${partnersinfo.name} `);
                formdata.append("center_id", dets.uniq_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                Swal.fire({
                    title: 'Partner Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                navigate('/partners/all')
            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close'
                })
            }
        }
        addSession()
    };



    return (<>
        {
            notify == 'loading' && (
                <p className="text-success text-center fw-bold"><CircularProgress /></p>
            )
        }
        {
            notify != ' ' && (
                <p className="text-success text-center fw-bold">{notify}</p>)
        }
        <h3 className="py-4">
            Edit Partner
        </h3>
        <form className="card p-4" action="" onSubmit={handleAddPartners}>
            <div className="mb-3">
                <label htmlFor="name">Name</label>
                <input value={partnersinfo.name} onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, name: e.target.value })} placeholder="enter partner name" required type="text" name="partners" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="website">Website</label>
                <input value={partnersinfo.website} onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, website: e.target.value })} required type="text" placeholder="enter partners website" name="website" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="logo">Logo</label>
                <input onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, logo: e.target.files[0] })} disabled type="file" name="logo" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="logo">MOU</label>
                <input onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, mou: e.target.files[0] })} disabled type="file" name="mou" className="form-control" />
            </div>
            <div className="col-5 m-auto singleSubmits">
                <button type="submit" className="btn rounded-0  text-info w-100"> Edit Partner</button>
            </div>
        </form>
    </>);
}

export default EditPartnersComp;