
import Dashboard from './pages/centers/dashboard';
import ErrorPage from "./error-page";
import CreateCertificate from "./pages/centers/certificates";
import AllCertificates from "./pages/centers/certificates/all";
import CreateCourse from "./pages/centers/course";
import AllCoursesCre from "./pages/centers/course/allcourses";
import CourseEdit from "./pages/centers/course/[id]";
import Otp from "./pages/centers/Otp";
import LoginComponent from "./pages/Login";
import AllCoursesLaunched from './pages/centers/launchcourse/launchedcourses';
import LaunchProgram from './pages/centers/launchcourse';
import CertEdit from './pages/centers/certificates/[id]';
import ViewCourseDet from './pages/centers/registered/[id]';
import EditLaunchProgram from './pages/centers/launchcourse/[id]';
import Students from './pages/centers/studentlist';
import RegisterStudents from './pages/centers/register';
import StudentInfo from './pages/centers/studentlist/[id]';
import EditStudents from './pages/centers/studentlist/edit/[id]';
import RegisterLecturers from './pages/centers/lecturers/register';
import Lecturers from './pages/centers/lecturers';
import LecturerInfo from './pages/centers/lecturers/[id]';
import EditFacl from './pages/centers/lecturers/edit/[id]';
import TraineeCourseReg from './pages/centers/coursereg';
import FacultyCourseReg from './pages/centers/courseregfaculty';
import Session from './pages/centers/session';
import AddSession from './pages/centers/session/create';
import EditSes from './pages/centers/session/[id]';
import TrackA from './pages/centers/trackreports';
import StudentsImport from './pages/centers/import/students';
import SettingsPage from './pages/centers/settings';
import NewUser from './pages/centers/settings/addusers';
import Feedback from './pages/studentfeedback/FeedBack';
import Ratings from './pages/studentfeedback/ratings';
import ActivityPage from './pages/centers/settings/activity';
import Partners from './pages/centers/partners';
import AddPartners from './pages/centers/partners/create';
import EditPartners from './pages/centers/partners/[id]';
import GraduatingList from './pages/centers/graduatingstatus/graduatingstudents';
import CurrentLevelItem from './pages/centers/currentlevel/currentlevel';
import Offerings from './pages/centers/offerings';



export const routesCreated =
    [
        {
            path: '/',
            element: <LoginComponent />,
            errorElement: <ErrorPage />
        },
        {
            path: '/offerings',
            element: <Offerings />,
            errorElement: <ErrorPage />
        },
        {
            path: '/otp',
            element: <Otp />,
            errorElement: <ErrorPage />
        },
        {
            path: '/dashboard',
            element: < Dashboard />,
            errorElement: <ErrorPage />
        },
        {
            path: '/course',
            element: < CreateCourse />,
            errorElement: <ErrorPage />
        },
        {
            path: '/editcourse/:id',
            element: < CourseEdit />,
            errorElement: <ErrorPage />
        },
        {
            path: '/allcourses',
            element: < AllCoursesCre />,
            errorElement: <ErrorPage />
        },
        {
            path: '/certificates/all',
            element: < AllCertificates />,
            errorElement: <ErrorPage />
        },
        {
            path: '/certificates',
            element: < CreateCertificate />,
            errorElement: <ErrorPage />
        },
        {
            path: '/editcertificates/:id',
            element: < CertEdit />,
            errorElement: <ErrorPage />
        },
        {
            path: '/launchcourse',
            element: < LaunchProgram />,
            errorElement: <ErrorPage />
        },
        {
            path: '/launchcourse/launchedcourses',
            element: < AllCoursesLaunched />,
            errorElement: <ErrorPage />
        },
        {
            path: '/editlaunchedcourse/:id',
            element: < EditLaunchProgram />,
            errorElement: <ErrorPage />
        },
        {
            path: '/registered/:id',
            element: < ViewCourseDet />,
            errorElement: <ErrorPage />
        },
        {
            path: '/trainees',
            element: < RegisterStudents />,
            errorElement: <ErrorPage />
        },
        {
            path: '/trainees/all',
            element: < Students />,
            errorElement: <ErrorPage />
        },
        {
            path: '/edittrainee/:id',
            element: < EditStudents />,
            errorElement: <ErrorPage />
        },
        {
            path: '/viewtrainee/:id',
            element: < StudentInfo />,
            errorElement: <ErrorPage />
        },
        {
            path: '/faculties',
            element: < RegisterLecturers />,
            errorElement: <ErrorPage />
        },
        {
            path: '/faculties/all',
            element: < Lecturers />,
            errorElement: <ErrorPage />
        },
        {
            path: '/editfaculty/:id',
            element: < EditFacl />,
            errorElement: <ErrorPage />
        },
        {
            path: '/viewfaculty/:id',
            element: < LecturerInfo />,
            errorElement: <ErrorPage />
        },
        {
            path: '/studentcoursereg',
            element: < TraineeCourseReg />,
            errorElement: <ErrorPage />
        },
        {
            path: '/facultycoursereg',
            element: < FacultyCourseReg />,
            errorElement: <ErrorPage />
        },
        {
            path: '/session',
            element: < AddSession />,
            errorElement: <ErrorPage />
        },
        {
            path: '/editsession/:id',
            element: < EditSes />,
            errorElement: <ErrorPage />
        },
        {
            path: '/session/all',
            element: < Session />,
            errorElement: <ErrorPage />
        },
        {
            path: '/partners',
            element: < AddPartners />,
            errorElement: <ErrorPage />
        },
        {
            path: '/editpartners/:id',
            element: < EditPartners />,
            errorElement: <ErrorPage />
        },
        {
            path: '/partners/all',
            element: < Partners />,
            errorElement: <ErrorPage />
        },
        {
            path: '/trackreports',
            element: < TrackA />,
            errorElement: <ErrorPage />
        },
        {
            path: '/importdata',
            element: <StudentsImport />,
            errorElement: <ErrorPage />
        },
        {
            path: '/settings',
            element: <SettingsPage />,
            errorElement: <ErrorPage />
        },
        {
            path: '/activitylogs',
            element: <ActivityPage />,
            errorElement: <ErrorPage />
        },
        {
            path: '/settings/addusers',
            element: <NewUser />,
            errorElement: <ErrorPage />
        },
        {
            path: '/studentfeedback',
            element: < Feedback />,
            errorElement: <ErrorPage />
        },
        {
            path: '/studentfeedback/ratings',
            element: < Ratings />,
            errorElement: <ErrorPage />
        },
        {
            path: '/change-level',
            element: <CurrentLevelItem />,
            errorElement: <ErrorPage />
        },
        {
            path: '/graduating-students',
            element: <GraduatingList />,
            errorElement: <ErrorPage />
        },

    ]

