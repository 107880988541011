import React, { useEffect, useState } from 'react'

import speseeLogo from '../../image/spesee.png'
import { CCarousel, CCarouselItem } from '@coreui/react'

import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'


function Feedback() {
    const navigate = useNavigate()
    const [logindet, setLogindet] = useState({ email: '', last_name: '' })


    const [otp, setOtp] = useState('');
    const [notify, setNotify] = useState(' ')
    const [display, setDisplay] = useState('form')
    const [filled, setFilled] = useState([])
    useEffect(() => {
        setOtp(filled.join(''))
    }, [filled])
    // console.log(otp)
    let inputArray = []
    function handleInput(e) {
        let inputVal = e.target.value;
        if (inputVal != [] && inputVal.length == 1) {
            if (e.target.nextSibling != null) {
                e.target.nextSibling.focus()
                setFilled(filled.concat(inputVal))

            } else {
                inputArray.push(inputVal)
                setFilled(filled.concat(inputVal))
                // setFilled(filled.join(''))
            }
        } else {
            if (e.target.previousSibling != null) {
                e.target.previousSibling.focus()
            } else {
                setFilled([])
            }
        }
    }

    function handleLogin(e) {
        e.preventDefault();

        var formdata = new FormData();
        formdata.append("email", logindet.email);
        formdata.append("last_name", logindet.last_name);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/studentComments`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result == 'correct details') {
                    setDisplay('otp')
                    setNotify(result)

                } else {
                    setNotify(result)
                }
            })
            .catch(error => console.log('error', error));
    }
    const verifyOtp = async () => {

        var formdata = new FormData();
        formdata.append("email", logindet.email);
        formdata.append("last_name", logindet.last_name);
        formdata.append("otp", otp);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            // redirect: 'follow'
        };

        // console.log(formdata);

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/otpverification`, requestOptions)
        const data = await response.json()
        // console.log(response.status)
        const status = response.status;
        if (status == 200) {
            setDisplay('otp');
            setNotify(data.status);
            Swal.fire({
                title: 'Success',
                text: 'Otp correct',
                timer: 2000
            })
            localStorage.setItem('ratingsFeed', logindet.email)
            navigate(`/studentfeedback/ratings`);
        } else {
            setDisplay('otp');
            setNotify(data.status);
            Swal.fire({
                title: 'Success',
                text: 'Incorrect OTP',
                timer: 2000
            })
        }
        // .then(response => response.json())
        // .then(result => {
        //     // if (result == 'correct details') {
        //     //     // setDisplay('otp')
        //     //     setNotify(result)

        //     // } else {
        //     //     setNotify(result)
        //     // }
        //     console.log(result);
        // })
        // .catch(error => console.log('error', error));
    }
    return (
        <>
            <div className='row align-items-center justify-content-center'>

                <div className="col-12 logopart col-lg-6 d-flex justify-content-center my-sm-0  py-sm-0 my-lg-5 py-lg-5">
                    <div className="row justify-content-between align-items-center py-sm-0 py-lg-3">

                        <img height={400} width={400} src={speseeLogo} className="img-fluid" alt="SPESSE logo" />

                    </div>
                </div>

                <div className='col-10 loginpart d-flex justify-content-center m-auto pt-5 col-lg-6'>
                    <div className='py-5 mt-5'>
                        <h1>Student Rating Portal</h1>
                        {display == 'form' ? <p>Sign In with your Email Address and Last Name</p> : <p>Enter the OTP sent to your email address</p>}

                        <div className='pe-4'>
                            <form onSubmit={handleLogin} className={display == 'form' ? ' ' : 'd-none'}>
                                <div className='mb-3'>
                                    <label>Email Address</label>
                                    <input onChange={(e) => setLogindet(
                                        { ...logindet, email: e.target.value })} type="email" className='form-control' placeholder='enter your email address' />
                                </div>
                                <div className='mb-3'>
                                    <label>Last Name</label>
                                    <input onChange={(e) => setLogindet(
                                        { ...logindet, last_name: e.target.value })} type="text" className='form-control' placeholder='enter your last name' />
                                </div>
                                <div>
                                    <button className='btn btn-dark'>Login</button>
                                </div>
                            </form>


                            <div className={display == 'otp' ? 'mb-3 ' : 'd-none'}>
                                <div className='py-4'>
                                    {notify}
                                </div>
                                <div className="d-flex OtpComponent mb-3">
                                    <input maxLength={1} onChange={handleInput} className="" type="text" />
                                    <input maxLength={1} onChange={handleInput} className="" type="text" />
                                    <input maxLength={1} onChange={handleInput} className="" type="text" />
                                    <input maxLength={1} onChange={handleInput} className="" type="text" />
                                    <input maxLength={1} onChange={handleInput} className="" type="text" />
                                </div>

                                <div className='btn-group'>
                                    <button onClick={verifyOtp} class='btn btn-outline-dark'>Verify</button>
                                    <button class='btn btn-outline-dark'>Resend OTP</button>
                                </div>
                            </div>




                        </div>
                    </div>

                </div>
            </div>


        </>
    )
}

export default Feedback
