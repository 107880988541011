import axios from "axios";
import { useEffect, useState } from "react";
import TotalactiveUsers from "./dashboardone/activeusers";
import TotalCourse from "./dashboardone/totalcourses";
import Totalfaculty from "./dashboardone/totalfaculty";
import Totalfemale from "./dashboardone/totalfemale";


function TopPilsItems() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [fac, setFac] = useState('');
    const [cour, setCour] = useState('');
    const [stud, setStud] = useState('');
    const [active, setActive] = useState('');
    useEffect(() => {
        const fetchData = () => {
            const TotalFaculties = `${process.env.REACT_APP_BASE_URL}/api/totalfalcuty/${dets.uniq_id}`
            const getTotalFaculties = axios.get(TotalFaculties);
            axios.all([getTotalFaculties]).then(
                axios.spread((...allData) => {
                    const allFacultiesData = allData[0].data;
                    setStud(allFacultiesData.students)
                    setCour(allFacultiesData.courses)
                    setFac(allFacultiesData.faculties)
                    setActive(allFacultiesData.users)
                })
            )
        }
        fetchData();
    }, [])
    return (<>
        <div className="row g-2 pillTop justify-content-between align-items-center mt-3">
            {/* Top Pills */}
            <div className="col-5 col-lg-3">
                <Totalfaculty data={fac} />
            </div>
            <div className="col-5 col-lg-3">
                <TotalactiveUsers data={active} />
            </div>
            <div className="col-5 col-lg-3">
                <TotalCourse data={cour} />
            </div>
            <div className="col-5 col-lg-3">
                <Totalfemale data={stud} />
            </div>
        </div>
    </>);
}

export default TopPilsItems;