import { CircularProgress } from '@mui/material';
import axios from 'axios';

import React, { useEffect, useState } from 'react'

function TracksData() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const [notify, setNotify] = useState(' ');
    const [searchinfo, setSearchInfo] = useState({
        tracks: '',
        schema: '',
    })
    const [searchResult, setSearchResult] = useState('');

    // console.log(searchResult);
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [Session, setSession] = useState('');
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState('');
    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;
            const allSession = `${process.env.REACT_APP_BASE_URL}/api/getallsession/${dets.uniq_id}`;

            // const allCourses = `${process.env.REACT_APP_BASE_URL}/api/courses`;
            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);
            const getallSession = axios.get(allSession);

            // const getallCourse = axios.get(allCourses);
            axios.all([getallSchema, getallTracks, getallSession]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    const allsession = allData[2].data;
                    setTracks(alltracks)
                    setSchema(allScema)
                    setSession(allsession)
                })
            )
        }
        fetchData()
    }, [])
    function Query() {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("schema_id", searchinfo.schema);
        urlencoded.append("track_id", searchinfo.tracks);
        // urlencoded.append("offering", searchinfo.offering);
        // urlencoded.append("session", searchinfo.session);
        urlencoded.append("center", dets.uniq_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getcoursesinprog`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false);
                setSearchResult(result)
            })
            .catch(error => console.log('error', error));

    }
    return (
        <>

            <div className='pb-5'>
                Select Track To View Calculated Report
            </div>
            <div className='input-group pb-3'>
                <select onChange={(e) => setSearchInfo(
                    { ...searchinfo, tracks: e.target.value })}

                    className='form-control' placeholder='---Select Track---'>
                    <option value="none">--select tracks ---</option>

                    {Array.isArray(tracks) && tracks.map(tracks => {
                        return (
                            <option value={tracks.id} key={tracks.id}>{tracks.title}</option>

                        )
                    })
                    }
                </select>

                <select onChange={(e) => setSearchInfo(
                    { ...searchinfo, schema: e.target.value })} className='form-control' placeholder='---Select Track---'>
                    <option value="none">--select schematic areas ---</option>

                    {Array.isArray(schema) && schema.map(schema => {
                        return (
                            <option value={schema.id} key={schema.id}>{schema.title}</option>

                        )
                    })
                    }

                </select>
                <button onClick={Query} className='btn btn-success'>{loading == true ? <CircularProgress /> : 'Search'}</button>
            </div>
            <div className='card p-4'>
                <div className='text-dark'>
                    <table className='table table-sm table-hover'>
                        <thead>
                            <th>Course No</th>
                            <th>Course Title</th>
                            <th>Date Announced </th>
                            <th>Offerings Per year</th>
                            <th>actions</th>
                        </thead>
                        <tbody>
                            {Array.isArray(searchResult) && (searchResult.length == 0 ? <tr>
                                <td colSpan={5} className="text-center p-4">No data Available</td>
                            </tr> : searchResult.map(Result => {
                                return (
                                    <tr key={Result.id}>
                                        <td>
                                            {searchResult.indexOf(Result) + 1}
                                        </td>
                                        <td>
                                            {Result.course_title}
                                        </td>

                                        <td>
                                            {Result.announcement_date}
                                        </td>
                                        <td>{Result.offerings}</td>
                                        <td>
                                            <div className='btn-group'>
                                                <a className='btn btn-success btn-sm' href={'/registered/' + Result.launched_id}>
                                                    view registered
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            )
                            }
                        </tbody>
                    </table>
                </div>

                <div>

                </div>
            </div>
        </>
    )
}

export default TracksData
