import React, { useEffect, useState } from "react";
import AllNavs from "../../components/allNavs";
import NewtopNAv from "../../components/centers/dashboardtwo/newtopNav";
import Secondnav from "../../components/centers/dashboardtwo/secondsidenav";
import OfferingsPage from "../../components/centers/offerings/offeringsPage";
import TopPilsItems from "../../components/centers/toppills";
import FooterComp from "../../components/footer";

function Offerings() {
    const [showNav, setShowNav] = useState(false)
    function navState(ClickedNav) {
        setShowNav(ClickedNav)
    }

    return <>
        <div className="container-fluid">
            <div>
                <div className="p-3">
                    <NewtopNAv naviState={navState} />
                </div>
            </div>
            <div className="row ">
                <div className={(showNav == true) ? `d-block d-lg-none col-md-3 d-flex allNavSide` : `d-none`}>
                    <AllNavs />
                </div>
                <div className="col-1 subNav row">
                    <AllNavs />
                </div>
                <div className="col-12 col-lg-11 regMain">
                    <div className="pb-4 px-2">
                        <TopPilsItems />
                    </div>
                    <div className="row pt-3">
                        <div className="d-none d-lg-block col-2 border bg-info border-1">
                            <Secondnav />
                        </div>
                        <div className="col-12 col-lg-10 p-lg-3">

                            <OfferingsPage />
                        </div>
                    </div>
                </div>
                <div className="container text-center mt5  pt-5">
                    <FooterComp />
                </div>

            </div>
        </div>
    </>

}

export default Offerings
