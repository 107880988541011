import React from 'react'


import { useEffect, useState } from "react";
import { Delete, Edit, Email, Phone } from "@mui/icons-material";
import { Avatar, CircularProgress } from "@mui/material";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { toOrdinal } from '../../utilities/fetchutil';

function EditFacultydets() {

    const { id } = useParams()
    const [Lecturer, setLecturer] = useState(' ')
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [registeredCourses, setRegisteredCourses] = useState('')

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/view_faculty/${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data;
                    setLecturer(data.faculty)
                    setRegisteredCourses(data.regcourse)
                    return data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData()
    }, [])
    return (

        <div className="col-12">
            <div className="bg-info p-5 shadow">

                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <Avatar
                            alt={Lecturer.first_name ?? ' '}
                            src="/static/images/avatar/1.jpg"
                            sx={{ width: 100, height: 100 }}
                        />
                    </div>
                    <div>
                        <h2 className="">
                            {Lecturer.first_name ?? ' '}  {Lecturer.last_name ?? ' '}
                        </h2>
                        <p><Email
                        /> {Lecturer.email_address ?? ' '}</p>
                        <p><Phone /> {Lecturer.phone_number ?? ' '}</p>

                        <div className="mb-2">
                            <a href={`/editfaculty/${Lecturer.id}`}>
                                <button className="btn btn-sm btn-success">
                                    <Edit /> Edit Profile
                                </button>
                            </a>
                        </div>
                        <div className="mb-2">
                            <a href={`/centers/studentlist/edit/${Lecturer.id}`}>
                                <button className="btn btn-sm btn-danger">
                                    <Delete /> Delete Profile
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    <div className="col">
                        <h6>Gender</h6>
                        <p>{Lecturer.gender}</p>
                    </div>
                    <div className="col">
                        <h6>Age</h6>
                        <p>{Lecturer.age}</p>
                    </div>

                </div>
            </div>
            <div className="bg-info mt-4 p-5 shadow">
                <div className="row">
                    <div className="col-3">
                        <h6>Center</h6>
                        <p>{Lecturer.center_id}</p>
                    </div>
                    <div className="col-3">
                        <h6>Nationality</h6>
                        <p>{Lecturer.nationality}</p>
                    </div>
                    <div className="col-3">
                        <h6>Highest Qualification</h6>
                        <p>{Lecturer.highest_qual}</p>
                    </div>
                    <div className="col-3">
                        <h6>Partnership Type</h6>
                        <p>{Lecturer.partnership}</p>
                    </div>

                </div>
            </div>
            <div className='bg-info mt-4 p-5 shadow'>
                <div>
                    <h2>Courses Registered</h2>
                </div>
                <div>
                    <table className="tableData table table-striped table-sm table-hover  ">
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>OFFERING</th>
                                <th>COURSE TITLE</th>
                                <th>TRACK TITLE</th>
                                <th>THEMATIC AREA</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                Array.isArray(registeredCourses) &&
                                registeredCourses.map(course => {
                                    return (
                                        <tr key={registeredCourses.indexOf(course)} className='align-items-center '>
                                            <td>{registeredCourses.indexOf(course) + 1}</td>
                                            <td>{toOrdinal(course.launch_offering)}</td>
                                            <td>{course.course_title}</td>
                                            <td>{course.track_title}</td>

                                            <td>{course.schematic_title}</td>
                                            <td>{course.grade}</td>

                                            <td >
                                                <div className='btn-group'>
                                                    <a href={`/registered/${course.launch_id}`} >
                                                        <button className='btn btn-sm btn-success'>View</button>
                                                    </a>
                                                </div>

                                            </td>

                                        </tr>
                                    )
                                })

                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default EditFacultydets
