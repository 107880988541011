import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

function OfferingsPage() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [value, setValue] = useState(0)
    const [rand, setRandom] = useState(0)
    const [currentOffering, setCurrentOffering] = useState(0);

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/get_offer/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result.offers.offering_number);
                if (result.offers == null) {
                    setCurrentOffering(0)
                } else {
                    setCurrentOffering(result.offers.offering_number)
                }
            })
            .catch(error => console.log('error', error));
    }, [rand]);

    function updateOffering() {
        var urlencoded = new URLSearchParams();
        urlencoded.append("center_id", dets.uniq_id);
        urlencoded.append("number", value);
        var requestOptions = {
            method: 'POST',
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/edit_offer`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === 'success') {
                    Swal.fire({
                        icon: 'success',
                        title: 'Offerings Number Updated!',
                        text: `${result.message}`,
                        timer: 1000,
                    })
                    setRandom(Math.random());
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Update Error!',
                        text: `${result.message}`,
                        timer: 1000,
                    })
                }
            })
            .catch(error => console.log('error', error));
    }

    return (
        <>
            <div className="bg-white p-5">
                Available Offerings = {currentOffering}
                <div>

                    <label className="mb-2">Enter Offering number</label>

                    <input
                        className="form-control mb-3"
                        onChange={(e) => {
                            setValue(e.target.value)
                        }
                        }
                        type="number"
                    />

                    <div>
                        <button onClick={() => { updateOffering() }} className="btn btn-success">Update Offering</button>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OfferingsPage
