import { Female, People } from '@mui/icons-material';

function Totalfemale(props) {
    const { data } = props;

    return (
        <div className="row topPills shadow text-center align-items-center">
            <div className="col-12 col-md-8">
                <p>Total Trainees</p>
                <p className="fw-bold num">{
                    data === 0 ? '---' : data}</p>
            </div>
            <div className="col-12 col-md-4 text-center ">
                <span className='text-center shadow-sm '><People /></span>

            </div>
        </div>
    );
}
export default Totalfemale;
