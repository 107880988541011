import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

function EditLect(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [notify, setNotify] = useState(' ');
    const [lecturerInfo, setLecturerInfo] = useState({
        names: " ",
        other_name: " ",
        email: " ",
        last_name: " ",
        phone: " ",
        gender: " ",
        nationality: " ",
        heighest_qualification: " ",
        partnership_type: " ",
        age: " "
    });
    useEffect(() => {
        const singlect = async () => {
            var requestOptions = {
                method: 'get',
                redirect: 'follow'
            };
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/single_fac/${id}`, requestOptions)
            const data = await response.json()
            if (response.status == 200) {
                setLecturerInfo({
                    last_name: data.last_name,
                    other_name: data.other_name,
                    names: data.first_name,
                    email: data.email_address,
                    phone: data.phone_number,
                    heighest_qualification: data.highest_qual,
                    age: data.age,
                    gender: data.gender,
                    nationality: data.nationality,
                    partnership_type: data.partnership,
                })

            } else {

            }
        }
        singlect()
    }, [])
    const editLect = async (e) => {
        e.preventDefault()

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("first_name", lecturerInfo.names);
        urlencoded.append("other_name", lecturerInfo.other_name);
        urlencoded.append("phone_number", lecturerInfo.phone);
        urlencoded.append("last_name", lecturerInfo.last_name);
        urlencoded.append("email_address", lecturerInfo.email);
        urlencoded.append("gender", lecturerInfo.gender);
        urlencoded.append("age", lecturerInfo.age);
        urlencoded.append("nationality", lecturerInfo.nationality);
        urlencoded.append("highest_qual", lecturerInfo.heighest_qualification);
        urlencoded.append("partnership_type", lecturerInfo.partnership_type);
        urlencoded.append("center_id", dets.uniq_id);
        urlencoded.append("user_id", id);



        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        setNotify('loading')

        const addLecturer = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/update_faculty`, requestOptions)
            const data = await response.json()
            const status = response.status;
            if (status == 200) {
                setNotify('Faculty Updated Succesfully')
                Swal.fire({
                    title: 'Faculty Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 2000,
                })
                navigate('/faculties/all');

            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'Faculty Details Exists',
                    icon: 'error',
                    confirmButtonText: 'close',
                    timer: 2000,
                })
            }
        }
        addLecturer()
    };
    return (
        <>
            {
                notify == 'loading' && (
                    <p className="text-success  text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="py-4">
                Faculty/Trainer Registration Information
            </h3>
            <form className="card p-4" action="" onSubmit={editLect} >
                <div className="row">
                    <div className=" col-6 mb-3">
                        <label htmlFor="fullname">First Name</label>
                        <input value={lecturerInfo.names} onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, names: e.target.value })}
                            type="text" name="fullname" className="form-control" />
                    </div>
                    <div className=" col-6 mb-3">
                        <label htmlFor="fullname">Other Name</label>
                        <input value={lecturerInfo.other_name} onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, other_name: e.target.value })}
                            type="text" name="fullname" className="form-control" />
                    </div>
                    <div className=" col-6 mb-3">
                        <label htmlFor="fullname">Last Name</label>
                        <input onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, last_name: e.target.value })}
                            value={lecturerInfo.last_name} type="text" name="fullname" className="form-control" />
                    </div>
                    <div className=" col-6 mb-3">
                        <label htmlFor="phone">Telephone number</label>
                        <input onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, phone: e.target.value })} value={lecturerInfo.phone} type="number" name="phone" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="email">Email</label>
                        <input onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, email: e.target.value })} value={lecturerInfo.email} type="text" name="email" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="age">Age</label>
                        <input onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, age: e.target.value })} value={lecturerInfo.age} type="number" name="age" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="gender">Gender</label>
                        <select onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, gender: e.target.value })} value={lecturerInfo.gender} type="text" name="" className="form-control" >
                            <option value="none" selected>Select your gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>


                        </select>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="age">Nationality</label>
                        <div className="mb-3 row ">
                            <select value={lecturerInfo.nationality} required name="nationality" onChange={(e) => setLecturerInfo(
                                { ...lecturerInfo, nationality: e.target.value })} class="form-select" aria-label="Default select example"  >
                                <option selected value={0}>Select your Nationality</option>
                                <option value={'Nigeria'}>Nigeria</option>
                                <option value={'Foreign'}>Foreign</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-6 mb-3">
                        <label htmlFor="address">Address</label>
                        <input value={lecturerInfo.address} onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, address: e.target.value })} type="text" name="address" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="academic">Highest Qualification</label>
                        <select value={lecturerInfo.heighest_qualification} required name="highest_qualifcation" onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, heighest_qualification: e.target.value }
                        )} class="form-select" aria-label="Default select example">

                            <option value={'None'}>Select your qualification</option>
                            <option value="SSCE">SSCE(Senior School Certificate Examination)</option>
                            <option value="ND">ND(National Diploma)</option>
                            <option value="HND">HND(Higher National Diploma)</option>
                            <option value="BTECH">BTECH(Bachelor of Technology)</option>
                            <option value="BENG">BENG(Bachelor of Engineering)</option>
                            <option value="BSC">BSC(Bachelor of Science)</option>
                            <option value="MSC">MSC(Master of Science)</option>
                            <option value="MBA">MBA(Master of Business Adminstration)</option>
                            <option value="PHD">PHD(Philosophiae Doctor)</option>
                        </select>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="academic">Partnership Type</label>
                        <select value={lecturerInfo.partnership_type} required name="partnership_type" onChange={(e) => setLecturerInfo(
                            { ...lecturerInfo, partnership_type: e.target.value }
                        )} class="form-select" aria-label="Default select example">

                            <option value={'None'}>Select Partnership</option>
                            <option value="Industrial">Industrial</option>
                            <option value="Regional">Regional</option>
                            <option value="International">International</option>
                        </select>
                    </div>


                </div>

                <div className="col-5 m-auto singleSubmits">
                    <button type="submit" className="btn rounded-0  text-info w-100"> Submit</button>
                </div>
            </form>
        </>
    );
}
export default EditLect;