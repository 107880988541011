// import styles from '/styles/sidenav.module.css'
import { Apartment, AppRegistration, CalendarMonth, Handshake, Home, HouseSiding, Key, LocationCityRounded, LocationDisabled, MapSharp, Monitor, NoteAddOutlined, PeopleOutlineOutlined, Person2Outlined, PersonOffOutlined, RocketLaunch, School, SettingsAccessibility } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import { CDropdown, CDropdownDivider, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import { useState } from 'react';
import { Link } from 'react-router-dom';


function Sidenav() {

    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [visible, setVisible] = useState({
        manageStud: false,
        manageLect: false,
        manageFac: false,
        manageCou: false,
        LauncPro: false,
        manageDept: false,
        manageNode: false,
        manageSes: false,
        manageParts: false,
        manageReg: false,
        manageImport: false,

    })

    return (
        <div className="sidenavBody py-4 ps-4">
            <div>
                <ul>
                    {/* DashBoard a */}
                    <li>
                        <Link to='/dashboard'>
                            <span><HomeIcon /></span> Dashboard
                        </Link>
                    </li>
                    <li>
                        <Link to="/course">
                            <span><RocketLaunch /></span>Launch Course
                        </Link>
                    </li>
                    {/* Manage Students a */}
                    <li onClick={(e) => setVisible(
                        { ...visible, manageStud: !visible.manageStud })} onMouseLeave={(e) => setVisible(
                            { ...visible, manageStud: false })}>
                        <span><PeopleOutlineOutlined /></span>
                        Manage Trainees
                        <div className={(visible.manageStud ? 'd-block' : 'd-none')}>
                            <ul className='sub-as'>
                                <li>
                                    <Link to="/trainees/all">
                                        View All Trainee
                                    </Link>
                                </li>
                                {dets.permission_type != 'View' &&

                                    <li>
                                        <Link to="/trainees">
                                            Add Trainee
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </li>
                    {/* Manage Lecturer a */}
                    <li onClick={(e) => setVisible(
                        { ...visible, manageLect: !visible.manageLect })} onMouseLeave={(e) => setVisible(
                            { ...visible, manageLect: false })}>
                        <span><School /></span>
                        Manage Faculties
                        <div className={(visible.manageLect ? 'd-block' : 'd-none')}>
                            <ul className='sub-as'>
                                <li>
                                    <Link to="/faculties/all">
                                        View All Faculties
                                    </Link>
                                </li>
                                {dets.permission_type != 'View' &&

                                    <li>
                                        <Link to="/faculties">
                                            Add Faculty
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </li>
                    {/* Manage Session a */}
                    <li onClick={(e) => setVisible(
                        { ...visible, manageSes: !visible.manageSes })} onMouseLeave={(e) => setVisible(
                            { ...visible, manageSes: false })}>
                        <span><CalendarMonth /></span>
                        Manage Session
                        <div className={(visible.manageSes ? 'd-block' : 'd-none')}>
                            <ul className='sub-as'>
                                <li>
                                    <Link to='/session/all'>
                                        View All Sessions
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/session">
                                        Add Sessions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li onClick={(e) => setVisible(
                        { ...visible, manageParts: !visible.manageParts })} onMouseLeave={(e) => setVisible(
                            { ...visible, manageParts: false })}>
                        <span><Handshake /></span>
                        Manage Partners
                        <div className={(visible.manageParts ? 'd-block' : 'd-none')}>
                            <ul className='sub-as'>
                                <li>
                                    <Link to="/partners/all">
                                        View All Partners
                                    </Link>
                                </li>
                                {
                                    dets.permission_type != 'View' &&

                                    <li>
                                        <Link to="/partners">
                                            Add Partners
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </li>

                    <li onClick={(e) => setVisible(
                        { ...visible, manageReg: !visible.manageReg })} onMouseLeave={(e) => setVisible(
                            { ...visible, manageReg: false })}>
                        <span><AppRegistration /></span>
                        Course Registration
                        <div className={(visible.manageReg ? 'd-block' : 'd-none')}>
                            <ul className='sub-as'>
                                <li>
                                    <Link to="/studentcoursereg">
                                        Students Course Registration
                                    </Link>
                                </li>
                                {
                                    dets.permission_type != 'View' &&
                                    <li>
                                        <Link to="/facultycoursereg">
                                            Faculty Course Registration
                                        </Link>
                                    </li>
                                }
                            </ul>
                        </div>
                    </li>

                </ul>
            </div>

            <div >
                <ul>
                    <li>
                        Account Pages
                    </li>
                    <li>
                        <Link to={'/settings'}>
                            <span><Person2Outlined /></span>Profile
                        </Link>
                    </li>

                    <li onClick={() => {
                        window.localStorage.removeItem('user');
                        window.location.reload();
                    }} >
                        <span><Key /></span>Sign Out
                    </li>

                </ul>
            </div>
        </div >
    )
}
export default Sidenav