import '../styles/main.min.css'
import '../styles/style.css'
import '../styles/sidenav.css'
import '../styles/dashmain.css'
import '../styles/otp.css'
import '../styles/register.css'
import '../styles/secondNav.css'
import '../styles/otpcomp.css'
import '../styles/chatbot.css'




// import { SessionProvider } from 'next-auth/react'



function MyApp({ Component, pageProps }) {

    return (

        <Component {...pageProps} />
    )

}

export default MyApp






