
import { CircularProgress, Input } from '@mui/material';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { toOrdinal } from '../../utilities/fetchutil';



function AllLaunchedProg(props) {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [launchedCourse, setLaunchedCourse] = useState(' ')
    const [update, setUpdate] = useState('');



    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        console.log(dets.uniq_id)
        fetch(`${process.env.REACT_APP_BASE_URL}/api/launchcourses/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setLaunchedCourse(result.reverse()))
            .catch(error => console.log('error', error));

    }, [update])


    function deletelaunched(param) {
        console.log(param)

        var requestOptions = {
            method: 'get',
            redirect: 'follow'
        };
        const fetcher = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/deletelaunchedcourse/${param}`, requestOptions)
            const data = await response.json()
            // setLoading(false)
            if (response.status == 200) {
                Swal.fire({
                    title: 'Courses Deleted Succesfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                setUpdate(Math.random())
            }
            return data;

        }
        fetcher()
    }


    function searchCourse(e) {
        const search = e.target.value
        if (search == null || search == '') {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/api/launchcourses/${dets.uniq_id}`, requestOptions)
                .then(response => response.json())
                .then(result => setLaunchedCourse(result))
                .catch(error => console.log('error', error));
        } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("center", dets.uniq_id);
            urlencoded.append("query", search);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
            fetch(`${process.env.REACT_APP_BASE_URL}/api/getcoursesbyscheme`, requestOptions)
                .then(response => response.json())
                .then(result => setLaunchedCourse(result))
                .catch(error => console.log('error', error));
        }
    }


    return (
        <div>
            <div className='d-flex align-items-center justify-content-between py-4'>
                <p>All Launched Courses</p>

                <input onChange={searchCourse} type="text" className='form-control w-50' placeholder='search for course' />
            </div>
            <div className="bg-info p-4 shadow rounded-0  table-responsive ">

                <div>
                    <h6 className="fw-bold">Total No of Launched Courses: {launchedCourse.length}</h6>
                </div>
                <table className="tableData table-hover table-striped table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>COURSES NAME</th>
                            <th>TRACK</th>
                            <th>THEMATIC AREA</th>
                            <th>START DATE</th>
                            <th>END DATE</th>
                            <th>ANNOUNCEMENT DATE</th>
                            <th>ANNOUNCEMENT LINK</th>
                            <th>OTHER MEDIA LINK</th>
                            <th>ACTIONS</th>

                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(launchedCourse) &&
                            launchedCourse.map(programme => {
                                return (
                                    <tr className='align-items-center '>
                                        <td>{launchedCourse.indexOf(programme) + 1}</td>
                                        <td>{programme.course_title} -- {toOrdinal(programme.offering)}</td>
                                        <td>{programme.track_title}</td>
                                        <td>{programme.schematic_title}</td>
                                        <td>{programme.start_date}</td>
                                        <td>{programme.end_date}</td>
                                        <td>{programme.announcement_date} </td>
                                        <td>{programme.announcement_link}</td>
                                        <td>{programme.other_media_link}</td>
                                        <td>
                                            <a href={`/registered/${programme.launch_id}`}>
                                                <button className='btn btn-success btn-sm'>
                                                    View Registered
                                                </button>
                                            </a>
                                        </td>
                                        {
                                            dets.permission_type != 'View' &&
                                            <td>
                                                <div className='btn-group btn-sm'>
                                                    <a href={`/editlaunchedcourse/${programme.launch_id}`}>
                                                        <button className='btn btn-sm btn-success'>
                                                            Edit
                                                        </button>
                                                    </a>
                                                    <button onClick={() =>
                                                        deletelaunched(`${programme.launch_id}`)} className='btn btn-sm btn-danger'>
                                                        Delete
                                                    </button>
                                                </div>

                                            </td>
                                        }
                                        {/* <td><button className='btn btn-primary btn-sm'>view</button></td> */}
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </div >);
}
export default AllLaunchedProg;