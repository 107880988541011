import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import axios from 'axios';

function Recentregisterd(props) {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [students, setStudents] = useState(' ')
    const { error, setErrors } = useState({})

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/recentlyRegistered?center_id=${dets.uniq_id}`,
            headers: {
                // 'Authorization': `Bearer ${bearer}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data;
                    setStudents(data.registerd.reverse())
                    return data;
                })
                .catch(function (error) {
                    // setErrors(error);
                });
        }
        fetchData()
    }, []);
    if (students === ' ') return <CircularProgress />
    return (<div>

        <div className="bg-info p-4 shadow rounded-3 table-responsive">
            <div>
                <h6 className="fw-bold">Recent Registered  Students: {students.length}</h6>
            </div>
            <table className="tableData table table-hover table-sm table-striped  table-borderless">
                <thead>
                    <tr>
                        <th>STUDENT'S NAME</th>
                        <th>EMAIl</th>
                        <th>PHONE</th>
                        <th>GENDER</th>
                        <th>EMPLOYMENT STATUS</th>
                        <th>QUALIFICATION</th>
                        {/* <th>OCCUPATION</th> */}


                    </tr>
                </thead>
                <tbody>
                    {
                        students.map(data => {
                            return (
                                <tr className='align-items-center '>
                                    <td><span><img src="" alt="" /></span> {data.first_name} - {data.last_name} </td>
                                    <td>{data.email_address}</td>
                                    <td>{data.phone_number}</td>
                                    <td>{data.gender} </td>
                                    <td>{data.employment_stat}</td>
                                    <td>{data.highest_qual}</td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    </div>);
}

export default Recentregisterd;