import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";


function AddPartnersComp() {

    const navigate = useNavigate()
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const [notify, setNotify] = useState(' ');
    const [partnersinfo, setPartnersInfo] = useState({
        name: " ",
        website: " ",
        logo: " ",
        mou: " ",
    });

    const handleAddPartners = async (e) => {
        e.preventDefault()

        var form = new FormData();
        form.append("center_id", dets.uniq_id);
        form.append("name", partnersinfo.name);
        form.append("website", partnersinfo.website);
        form.append("logo", partnersinfo.logo);
        form.append("mou", partnersinfo.mou);

        var requestOptionsNew = {
            method: 'POST',
            body: form,
            redirect: 'follow'
        };
        setNotify('loading')

        const addSession = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/addcenterpartner`, requestOptionsNew)
            const data = await response.json()
            const status = response.status;
            if (status == 200) {
                setNotify('Partner Added Succesfully')

                var formdata = new FormData();
                if (dets.permission_type == 'supper') {
                    formdata.append("username", "Super Admin");
                } else {
                    formdata.append("username", dets.name);
                }
                formdata.append("activity", `Added a new partner ${partnersinfo.name} `);
                formdata.append("center_id", dets.uniq_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                Swal.fire({
                    title: 'Session Added Successfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                navigate('/partners/all')
            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close'
                })
            }
        }
        addSession()
    };


    return (<>
        {
            notify == 'loading' && (
                <p className="text-success text-center fw-bold"><CircularProgress /></p>
            )
        }
        {
            notify != ' ' && (
                <p className="text-success text-center fw-bold">{notify}</p>)
        }
        <h3 className="py-4">
            Add Partners
        </h3>
        <form className="card p-4" action="" onSubmit={handleAddPartners}>
            <div className="mb-3">
                <label htmlFor="name">Name</label>
                <input onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, name: e.target.value })} placeholder="enter partner name" required type="text" name="partners" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="website">Website</label>
                <input onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, website: e.target.value })} required type="text" placeholder="enter partners website" name="website" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="logo">Logo</label>
                <input onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, logo: e.target.files[0] })} required type="file" name="logo" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="logo">MOU</label>
                <input onChange={(e) => setPartnersInfo(
                    { ...partnersinfo, mou: e.target.files[0] })} required type="file" name="mou" className="form-control" />
            </div>
            <div className="col-5 m-auto singleSubmits">
                <button type="submit" className="btn rounded-0  text-info w-100"> Add Partner</button>
            </div>
        </form>
    </>);
}

export default AddPartnersComp;