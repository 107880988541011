

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function AddnewUser() {
    const navigate = useNavigate();
    const [display, SetDisplay] = useState('user');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [ident, setId] = useState('');
    const [users, setUsers] = useState('')
    const [rand, setRand] = useState('')
    const username = JSON.parse(localStorage.getItem('admin'))

    const [editor, setEditor] = useState(false);
    const [correct, setCorrect] = useState(false)
    const [profile, setProfile] = useState({
        name: dets.name,
        email: dets.email,
        Password: '',
        address: dets.address,
        zone: dets.zone,
        phone_number: dets.phone_number,
    })
    const [newuser, SetUser] = useState({
        name: '',
        email: ' ',
        password: ' ',
        type: 'View',
        address: dets.address,
        zone: dets.zone,
        phone_number: '',
    })
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getcenteruser/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setUsers(result.data)
            })
            .catch(error => console.log('error', error));

    }, [rand]);
    function userchange() {
        navigate('/settings/addusers');
        // SetDisplay('user');
    }
    function profileChange() {
        navigate('/settings');
        // SetDisplay('profile');
    }
    function con_Password(e) {
        let query = e.target.value

        if (query != profile.Password) {
            setCorrect(false)
        } else {
            setCorrect(true)

        }
    }
    function editProfile(event) {
        event.preventDefault()
        var formdata = new FormData();
        formdata.append("email", profile.email);
        formdata.append("id", dets.uniq_id);
        formdata.append("name", profile.name);
        formdata.append("password", profile.Password);
        formdata.append("address", profile.address);
        formdata.append("phone_number", profile.phone_number);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/updateProfileCenters`, requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem("user", JSON.stringify(result.data));
            }
            )
            .catch(error => console.log('error', error));
    }
    function handleReg(e) {
        e.preventDefault()
        var formdata = new FormData();
        formdata.append("name", newuser.name);
        formdata.append("email", newuser.email);
        formdata.append("address", dets.address);
        formdata.append("phone_number", newuser.phone_number);
        formdata.append("zone", dets.zone);
        formdata.append("password", newuser.password);
        formdata.append("id", dets.uniq_id);
        formdata.append("type", newuser.type);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/creatCenterUser`, requestOptions)
            .then(response => response.json())
            .then(result => {

                Swal.fire({
                    icon: 'success',
                    title: 'user added',
                    text: 'user added ',
                    timer: 1000,

                })
                setRand(Math.random())
            }
            )
            .catch(error => console.log('error', error));
    }
    function suspend(id) {

        Swal.fire({
            title: 'Are you sure?',
            text: "Kindly confirm this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Suspend!'
        }).then((result) => {
            if (result.isConfirmed) {
                var requestOptions = {
                    method: 'get',
                    // body: formdata,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/centersuspenduser/${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        Swal.fire(
                            'Suspended!',
                            'User has been suspended.',
                            'success'
                        )
                        setRand(Math.random())
                    }
                    )
                    .catch(error => console.log('error', error));

            }
        })


    }
    function unsuspend(id) {

        Swal.fire({
            title: 'Are you sure?',
            text: "Kindly confirm this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Unsuspend!'
        }).then((result) => {
            if (result.isConfirmed) {
                var requestOptions = {
                    method: 'get',
                    // body: formdata,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/centerunsuspenduser/${id}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        Swal.fire(
                            'Unsuspended!',
                            'User has been unsuspended.',
                            'success'
                        )
                        setRand(Math.random())
                    }
                    )
                    .catch(error => console.log('error', error));

            }
        })


    }
    function deleteuser(id) {
        // var formdata = new FormData();
        // formdata.append("email", profile.email);
        // formdata.append("id", username.id);
        // formdata.append("name", profile.name);
        // formdata.append("password", profile.Password);


        var requestOptions = {
            method: 'get',
            // body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/deleteCenterUser/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setRand(Math.random())

                Swal.fire({
                    icon: 'success',
                    title: 'Successful',
                    text: 'User Deleted',
                    timer: 1000,

                })
            }
            )
            .catch(error => console.log('error', error));
    }
    function handleedit(e) {
        e.preventDefault()

        var formdata = new FormData();
        formdata.append("name", newuser.name);
        formdata.append("email", newuser.email);
        formdata.append("address", dets.address);
        formdata.append("phone_number", newuser.phone_number);
        formdata.append("zone", dets.zone);
        formdata.append("password", newuser.password);
        formdata.append("id", dets.uniq_id);
        formdata.append("type", newuser.type);


        formdata.append("user_id", ident);


        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/updateCenterUser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire({
                    icon: 'success',
                    title: 'User Update',
                    text: 'User Updated Successfully',
                    timer: 1000,

                })
                setRand(Math.random())
            }
            )
            .catch(error => console.log('error', error));
    }
    function edituser(itemId, index) {
        setEditor(true);
        setId(itemId);
        SetUser({
            name: users[index].name,
            email: users[index].email,
            type: users[index].permission_type,
        })
    }
    return (
        <>
            <div className='my-5 py-4 row bg-white shadow'>
                <div className='col-2'>

                    <p className={display === 'profile' ? 'border-end rounded-2 border-2 border-success p-2' : ' '} onClick={profileChange}>
                        Profile Settings
                    </p>
                    {dets.permission_type != 'View' &&
                        <>
                            <p className={display === 'profile' ? ' ' : 'border-end rounded-2 border-2 border-success p-2'} onClick={userchange}>
                                User Permission
                            </p>
                            <p className={display === 'activity' && 'border-end rounded-2 border-2 border-success p-2'} onClick={() => {
                                navigate('/activitylogs')
                            }}>
                                Activity Logs
                            </p>
                        </>

                    }
                </div>
                <div className='col-9'>

                    <div className={
                        display == 'user' ? ' ' : 'd-none'
                    }>
                        {/* User Permission */}

                        <div className='row'>

                            <div className='col-3'>
                                {editor == true ? <h5>Edit user</h5> : <h5>Add new user</h5>}

                                <form className='' onSubmit={editor == true ? handleedit : handleReg}>
                                    <div className='mb-3'>
                                        <label>Name</label>
                                        <input onChange={(e) => SetUser(
                                            { ...newuser, name: e.target.value })} value={newuser.name} type="text"
                                            className={editor == true ? 'form-control border-danger border ' : 'form-control'}
                                            required placeholder='enter name' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Email</label>
                                        <input type="email" onChange={(e) => SetUser(
                                            { ...newuser, email: e.target.value })} value={newuser.email} className={editor == true ? 'form-control border-danger border ' : 'form-control'} required placeholder='enter email address' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Password</label>
                                        <input type="text" onChange={(e) => SetUser(
                                            { ...newuser, password: e.target.value })} className={editor == true ? 'form-control border-danger border ' : 'form-control'} required placeholder='enter your password' />
                                    </div>
                                    <div className='mb-3'>
                                        <label>Type</label>
                                        <select type="text" onChange={(e) => SetUser(
                                            { ...newuser, type: e.target.value })} value={newuser.type} className={editor == true ? 'form-control border-danger border ' : 'form-control'} required placeholder='select user type' >
                                            <option selected value="View">View</option>
                                            <option value="Edit">Edit</option>
                                            <option value="supper">Super</option>
                                            {/* <option value="view_only" key="">IVA</option> */}

                                        </select>
                                    </div>

                                    {editor == true ? <button className='btn btn-primary'>Edit</button> : <button className='btn btn-primary'>Save</button>}


                                </form>
                            </div>



                            <div className='col-9 table-responsive'>
                                <h5> Admin Users</h5>

                                <table className='table table-sm table-hover'>
                                    <thead>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Email
                                        </th>
                                        <th>
                                            Permission type
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.isArray(users) && users.map((row, key) => {
                                                return (
                                                    <tr key="">
                                                        <td>
                                                            {row.name}
                                                        </td>
                                                        <td>
                                                            {row.email}
                                                        </td>
                                                        <td>
                                                            {row.permission_type == 'supper' ? 'Super' : row.permission_type}
                                                        </td>

                                                        <td>
                                                            <div className='btn-group'>
                                                                <button onClick={() => edituser(row.id, users.indexOf(row))} className='btn   btn-sm btn-primary'>Edit</button>
                                                                {
                                                                    row.status != 'suspend' ? <button onClick={() => suspend(row.id)} className=' btn-sm btn btn-danger'>Suspend</button> : <button onClick={() => unsuspend(row.id)} className=' btn-sm btn btn-success'>Unsuspend</button>
                                                                }
                                                                <button onClick={() => deleteuser(row.id)} className=' btn-sm btn btn-danger'>Delete</button>
                                                            </div>


                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>
            </div >
        </>
    )
}

export default AddnewUser
