import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";


function AddSessionComp() {
    const navigate = useNavigate()
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const [notify, setNotify] = useState(' ');
    const [sesInfo, setsesInfo] = useState({
        center_id: " ",
        session: " ",
        session_start: " ",
        session_end: " ",
    });


    const handleAddSession = async (e) => {
        e.preventDefault()


        const addSession = async () => {

            var urlencoded = new URLSearchParams();
            urlencoded.append("center_id", dets.uniq_id);
            urlencoded.append("session", sesInfo.session);
            urlencoded.append("session_start", sesInfo.session_start);
            urlencoded.append("session_end", sesInfo.session_end);
            // urlencoded.append("Authorization", `Bearer ${bearer}`);

            var requestOptionsData = {
                method: 'POST',
                body: urlencoded,
                redirect: 'follow'
            };
            setNotify('loading')

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/addsession`, requestOptionsData)
            const data = await response.json()
            const status = response.status;
            if (status == 200) {
                setNotify('Session Added Succesfully')
                var formdata = new FormData();
                if (dets.permission_type == 'supper') {
                    formdata.append("username", "Super Admin");
                } else {
                    formdata.append("username", dets.name);
                }
                formdata.append("activity", `Added a new session `);
                formdata.append("center_id", dets.uniq_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));
                Swal.fire({
                    title: 'Session Added Successfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                navigate('/session/all')
            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close'
                })
            }
        }
        addSession()
    };


    return (<>
        {
            notify == 'loading' && (
                <p className="text-success text-center fw-bold"><CircularProgress /></p>
            )
        }
        {
            notify != ' ' && (
                <p className="text-success text-center fw-bold">{notify}</p>)
        }
        <h3 className="py-4">
            Add Session
        </h3>
        <form className="card p-4" action="" onSubmit={handleAddSession}>
            <div className="mb-3">
                <label htmlFor="session">Session</label>
                <input onChange={(e) => setsesInfo(
                    { ...sesInfo, session: e.target.value })} placeholder="20**/20**" required type="text" name="session" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="session_start">Session Start</label>
                <input onChange={(e) => setsesInfo(
                    { ...sesInfo, session_start: e.target.value })} required type="date" name="session_start" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="session_end">Session End</label>
                <input onChange={(e) => setsesInfo(
                    { ...sesInfo, session_end: e.target.value })} required type="date" name="session_end" className="form-control" />
            </div>
            <div className="col-5 m-auto singleSubmits">
                <button type="submit" className="btn rounded-0  text-info w-100"> Add Session</button>
            </div>
        </form>
    </>);
}

export default AddSessionComp;