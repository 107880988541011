import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Edit, Email, Phone } from "@mui/icons-material";
import { Avatar, CircularProgress } from "@mui/material";
import { useParams } from 'react-router-dom';


function EditStudentdet() {
    const { id } = useParams();

    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [stdData, setStdData] = useState(' ')
    const [registeredCourses, setRegisteredCourses] = useState(' ')
    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/view_student/${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data;
                    setStdData(data.students)
                    setRegisteredCourses(data.regcourse)
                    return data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        fetchData()
    }, [])
    return (
        <>
            {stdData == null ? <p><CircularProgress /></p> :
                <>
                    <div className="p-5  bg-info shadow">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <Avatar
                                    alt={stdData.first_name + ' ' + stdData.last_name}
                                    src="/static/images/avatar/1.jpg"
                                    sx={{ width: 100, height: 100 }}
                                />
                            </div>
                            <div>
                                <h2 className="">
                                    {stdData.first_name} {stdData.other_name}  {stdData.last_name}
                                </h2>
                                <p><Email
                                /> {stdData.email_address}</p>
                                <p><Phone /> {stdData.phone_number}</p>
                                {
                                    dets.permission_type != 'View' &&
                                    <div className="mb-2 btn btn-outline-dark">
                                        <a href={`/edittrainee/${stdData.id}`}>
                                            <Edit /> Edit Profile
                                        </a>
                                    </div>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex justify-content-between">
                            <div className="col">
                                <h6>Gender</h6>
                                <p>{stdData.gender}</p>
                            </div>
                            <div className="col">
                                <h6>Age</h6>
                                <p>{stdData.age}</p>
                            </div>

                            <div className="col">
                                <h6>Track Registered On</h6>
                                <p>{stdData.track_title}</p>
                            </div>
                            <div className="col">
                                <h6>Current Level</h6>
                                <p>{stdData.track === 5 ? stdData.current_level : 'N/A'}</p>

                                {/* <p>{stdData.current_level}</p> */}
                            </div>
                            {/* <div className="col">
                        <h6>Address</h6>
                        <p>{stdData.address}</p>
                    </div> */}
                        </div>
                    </div>
                    <div className="bg-info mt-4 p-5 shadow">
                        <div className="row">
                            <div className="col-3">
                                <h6>Center</h6>
                                <p>{stdData.center_id}</p>
                            </div>
                            <div className="col-3">
                                <h6>Employer</h6>
                                <p>{stdData.employer}</p>
                            </div>
                            <div className="col-3">
                                <h6>Employee Type</h6>
                                <p>{stdData.employement_sector}</p>
                            </div>
                            <div className="col-3">
                                <h6>Employment Status</h6>
                                <p>{stdData.employment_stat}</p>
                            </div>
                            <div className="col-3">
                                <h6>Highest Qualification</h6>
                                <p>{stdData.highest_qual}</p>
                            </div>
                            <div className="col-3">
                                <h6>All Qualifications</h6>
                                <p>{stdData.qualifications}</p>
                            </div>
                        </div>
                    </div>

                    <div className='bg-info mt-4 p-5 shadow'>
                        <div>
                            <h2>Courses Registered</h2>
                        </div>
                        <div>
                            <table className="tableData table table-striped table-sm table-hover  ">
                                <thead>
                                    <tr>
                                        <th>S/N</th>
                                        <th>COURSE TITLE</th>
                                        <th>TRACK TITLE</th>
                                        <th>THEMATIC AREA</th>
                                        <th>STATUS</th>
                                        <th>GRADE</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        Array.isArray(registeredCourses) &&
                                        registeredCourses.map(course => {
                                            return (
                                                <tr key={registeredCourses.indexOf(course)} className='align-items-center '>
                                                    <td>{registeredCourses.indexOf(course) + 1}</td>
                                                    <td>{course.course_title}</td>
                                                    <td>{course.track_title}</td>

                                                    <td>{course.schematic_title}</td>
                                                    <td>{course.completed == 'completed' ?
                                                        <button className='btn btn-success btn-sm'>Completed</button> :
                                                        <button className='btn btn-danger btn-sm'>Not Completed</button>
                                                    }
                                                    </td>

                                                    <td>{course.grade}</td>

                                                    <td >
                                                        <div className='btn-group'>

                                                            <a href={`/registered/${course.launch_id}`} >
                                                                <button className='btn btn-sm btn-success'>View</button>
                                                            </a>
                                                            {
                                                                // dets.permission_type != 'View' &&
                                                                // <button onClick={() => deleteStud(`${course.id}`)} className="btn btn-sm btn-danger">
                                                                //     Delete
                                                                // </button>
                                                            }
                                                        </div>

                                                    </td>

                                                </tr>
                                            )
                                        })

                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            }
        </>

    )
}

export default EditStudentdet
