import { CChart } from "@coreui/react-chartjs";
import axios from "axios";
import { useEffect, useState } from "react";

const Studentfeedback = () => {
    const [date, setDate] = useState(' ');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [feedback, setFeedback] = useState('')

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/commentsPercentage?center_id=${dets.uniq_id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data;
                    setFeedback(data)
                    return data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData();
    }, [])
    setInterval(() => {
        const d = new Date();
        setDate(`${d.getUTCDate()}-${d.getMonth() + 1}-${d.getFullYear()}-${d.getSeconds()}`);
    }, 1000)

    // let dates = getMonth()
    return (<div className="">
        <div className="p-5">
            <p className=" fw-bold fs-4">Student Feedback</p>
            <p>LAST UPDATED ON: {date} </p>
            <div className="">
                {
                    feedback != '' && (
                        <CChart
                            type="doughnut"
                            data={{
                                labels: ['Very Satisfied', 'Satisfied', 'Dissatisfied', 'Very Disatisfied', "Haven't rated"],
                                datasets: [
                                    {
                                        backgroundColor: ['#41B883', '#6EEEBB', 'yellow', '#DD1B16', 'grey'],
                                        data: [feedback.very_sat, feedback.sat, feedback.dissat, feedback.very_dissat, feedback.not_rated],
                                    },
                                ],
                            }}
                        />
                    )
                }

            </div>
            <div>
                This score is calculated based on the user rating provided across students feedback.
            </div>
        </div>
    </div>);
}

export default Studentfeedback;