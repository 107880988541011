import React, { useRef } from 'react'
import Swal from 'sweetalert2';
import axios from "axios";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
// import { saveActivity } from '../../utilities/fetchutil'; /

import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';


function MoveNewLevel() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [notify, setNotify] = useState('')
    const [trainee, setTrainee] = useState('')
    const [traineeres, setTraineeres] = useState('')
    const [tracki, setTracki] = useState('');
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [multiple, setMultiple] = useState(true);
    const [Session, setSession] = useState('');
    const [assingInfo, Setassign] = useState({
        session: " ",
        semester: "",
        offering: " ",
    });

    function exampleToast(message, status) {
        return (
            <CToast
                className={status === false ? "fw-bold bg-danger text-white border border-danger me-auto" : "fw-bold border border-success me-auto"}>
                <CToastHeader closeButton>
                    <svg
                        className={status === false ? "fw-bold border border-danger me-auto" : "fw-bold border border-success me-auto"}
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        role="img"
                    >
                        <rect width="100%" height="100%" fill="#007aff"></rect>
                    </svg>
                    <div className={"fw-bold me-auto"}>Student Registration</div>
                    {/* <small>7 min ago</small> */}
                </CToastHeader>
                <CToastBody>{message}</CToastBody>
            </CToast>
        )
    }
    const [arrays, setArrays] = useState([])
    const [studArrays, setStudArrays] = useState([])
    const [selectedStud, setStud] = useState('');
    const [currentLevel, setCurrentLevel] = useState('100');
    const [movingLevel, setMovingLevel] = useState('');
    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;
            // const allCourses = `${process.env.REACT_APP_BASE_URL}/api/courses`;
            const allSession = `${process.env.REACT_APP_BASE_URL}/api/getallsession/${dets.uniq_id}`;
            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);
            const getallSession = axios.get(allSession);

            // const getallCourse = axios.get(allCourses);
            axios.all([getallSchema, getallTracks, getallSession]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    const allsession = allData[2].data;
                    // const allcourses = allData[2].data;
                    setTracks(alltracks)
                    setSchema(allScema)
                    setSession(allsession)
                    // setCourses(allcourses)

                })
            )
        }
        fetchData()
    }, [])
    useEffect(() => {
        setStudArrays([])
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("level", currentLevel);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/search_student_in_level`, requestOptions)
            .then(response => response.json())
            .then(result => setTraineeres(result))
            .catch(error => console.log('error', error));

    }, [currentLevel])
    // function searchTrainee() {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    //     var urlencoded = new URLSearchParams();
    //     urlencoded.append("center", dets.uniq_id);
    //     urlencoded.append("level", currentLevel);
    //     var requestOptions = {
    //         method: 'POST',
    //         headers: myHeaders,
    //         body: urlencoded,
    //         redirect: 'follow'
    //     };
    //     fetch(`${process.env.REACT_APP_BASE_URL}/api/search_student_in_level`, requestOptions)
    //         .then(response => response.json())
    //         .then(result => setTraineeres(result))
    //         .catch(error => console.log('error', error));

    // }
    function selectTrainee(id, studentname) {
        const stdData = {
            id: id,
            stdname: studentname,
        }
        setStud(stdData)
    }
    const [toast, addToast] = useState(0)
    const toaster = useRef()
    function removeStud(array) {
        let filtered = studArrays.filter(function (ele) {
            return ele != array;
        });

        if (setStudArrays(filtered)) {
            Swal.fire({
                title: 'Student Added',
                icon: 'success',
                confirmButtonText: 'close',
                timer: 1000,
            });
        };
    }
    function addStudent(id, stud_name) {
        const student_data = {
            stud_id: id,
            stud_name: stud_name,
        }
        const student = studArrays.find(stud => {
            if (stud.stud_id === student_data.stud_id) {
                return true;
            } else {
                return false;
            }
        });
        if (student != undefined) {
            removeStud(student);
        } else {
            setStudArrays(studArrays.concat(student_data))
        }

    }

    function submitMultiple() {
        if (studArrays.length === 0 || movingLevel === '') {
            Swal.fire({
                title: 'Kindly Select A Trainee and Target Level',
                icon: 'error',
                confirmButtonText: 'close'
            })
        } else {
            studArrays.map(student => {

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("student_id", student.stud_id);
                urlencoded.append("center_id", dets.uniq_id);
                urlencoded.append("level", movingLevel);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                };
                fetch(`${process.env.REACT_APP_BASE_URL}/api/promote_level`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        addToast(exampleToast(result.message, result.status))
                        const activity = `${result.message}`;
                        // saveActivity(activity);

                    })
                    .catch(error => console.log('error', error));
            })
        }
    }

    return (
        <>
            <CToaster ref={toaster} push={toast} placement="top-end" />
            {/* <button className='btn btn-primary' onClick={() => }>add toast</button> */}
            {
                notify === 'loading' && (
                    <p className="text-success  text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="pt-4 d-flex align-items-center">
                Move Students to New Level
            </h3>
            <p className='mb-4'><em>(note: this applies to undergraduate students in track E)</em></p>

            <div className='row justify-content-between align-items-end'>
                <div className="col-6 mb-3">
                    <label htmlFor="name">Select Current Level of moving Students</label>
                    <select value={currentLevel} onChange={(e) => {
                        setCurrentLevel(e.target.value)
                    }} required className="form-control">
                        <option selected value="none" key="">--- Select Current Level ----</option>
                        <option value="100" key="">100 || Level</option>
                        <option value="200" key="">200 || Level</option>
                        <option value="300" key="">300 || Level</option>
                        <option value="400" key="">400 || Level</option>
                        <option value="500" key="">500 || Level</option>
                    </select>
                </div>
                <div className="col-6 mb-3">
                    <label htmlFor="name">Select Target Level</label>
                    <select value={movingLevel} onChange={(e) => setMovingLevel(e.target.value)} required className="form-control">
                        <option selected value="none" key="">--- Select Target Level ----</option>
                        <option value="100" key="">100 || Level</option>
                        <option value="200" key="">200 || Level</option>
                        <option value="300" key="">300 || Level</option>
                        <option value="400" key="">400 || Level</option>
                        <option value="500" key="">500 || Level</option>
                    </select>

                </div>

            </div>

            <div className="card p-4" action="" >
                <div className='row mb-3 justify-content-between'>
                    <div className='col-6'>
                        <div>
                            Current Level : {currentLevel} Level
                        </div>
                        {Array.isArray(traineeres) && traineeres.map(trainee => {
                            return (
                                <><div>
                                    <input onClick={multiple ? () => { addStudent(trainee.id, trainee.first_name + trainee.last_name) } : () => {
                                        selectTrainee(`${trainee.id}`, `${trainee.first_name} ${trainee.last_name} `)
                                    }
                                    } className='me-2' key={`${trainee.id}`} value={trainee.id}
                                        type={multiple ? "checkbox" : "radio"} name='trainees' />
                                    <label> {trainee.first_name} {trainee.last_name} </label>
                                </div>

                                </>
                            )
                        })
                        }

                    </div>
                    <div className='col-6'>
                        <div>
                            Target Level : {movingLevel} Level
                        </div>
                        {Array.isArray(studArrays) && studArrays.map(trainee => {
                            return (
                                <><div>
                                    <input onClick={multiple ? () => { removeStud(trainee) } : () => {
                                        selectTrainee(`${trainee.id}`, `${trainee.first_name} ${trainee.last_name} `)
                                    }
                                    } className='me-2' key={trainee.id} value={trainee.id}
                                        type={multiple ? "checkbox" : "radio"} name='trainees' />
                                    <label> {trainee.stud_name} {trainee.last_name} </label>
                                </div>

                                </>
                            )
                        })
                        }


                    </div>
                </div>
                <hr />
                <div>
                    <div className=" text-center pt-3 singleSubmits">
                        <button onClick={submitMultiple} className="btn w-100 text-info rounded-0">
                            Move
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MoveNewLevel
