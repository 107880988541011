import React, { useRef } from 'react'
import Swal from 'sweetalert2';
import axios from "axios";
import { useState, useEffect } from "react";
import Home from "@mui/icons-material/Home";
import { CircularProgress } from "@mui/material";
import { Add, AddCircle, AddRounded, LensTwoTone, PlusOneOutlined, Remove, RemoveCircle } from "@mui/icons-material";
import { CToast, CToastBody, CToaster, CToastHeader } from '@coreui/react';
import { convertStringToArray, toOrdinal } from '../../../utilities/fetchutil';


function AssignCourses() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [notify, setNotify] = useState('')
    const [trainee, setTrainee] = useState('')
    const [traineeres, setTraineeres] = useState('')
    const [tracki, setTracki] = useState('');
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [courses, setCourses] = useState('');
    const [multiple, setMultiple] = useState('');
    const [Session, setSession] = useState('');
    const [coursesres, setCoursesres] = useState(true);
    const [assingInfo, Setassign] = useState({
        session: null,
        semester: "",
        offering: null,
    });
    const [currentOffering, setCurrentOffering] = useState('');
    const [userInfo, setUserInfo] = useState({
        lastname: " ",
        name: " ",
        email: " ",
        phone: " ",
        heighest_qualification: " ",
        age: " ",
        sex: "",
        Nationality: " ",
        employee: "Nil",
        employee_type: "Nil",
        employment_status: "Nil",
    });
    function exampleToast(message, status) {
        return (
            <CToast
                className={status === false ? "fw-bold bg-danger text-white border border-danger me-auto" : "fw-bold border border-success me-auto"}>
                <CToastHeader closeButton>
                    <svg
                        className={status === false ? "fw-bold border border-danger me-auto" : "fw-bold border border-success me-auto"}
                        width="20"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid slice"
                        focusable="false"
                        role="img"
                    >
                        <rect width="100%" height="100%" fill="#007aff"></rect>
                    </svg>
                    <div className={"fw-bold me-auto"}>Student Registration</div>
                    {/* <small>7 min ago</small> */}
                </CToastHeader>
                <CToastBody>{message}</CToastBody>
            </CToast>
        )
    }
    const [arrays, setArrays] = useState([])
    const [studArrays, setStudArrays] = useState([])
    const [selectedStud, setStud] = useState('');
    const [allTrainees, setAllTrainees] = useState([]);
    const [manualTrainees, setManualTrainees] = useState('');
    const [selectAll, setSelectAll] = useState(false)
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/students/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setAllTrainees(result))
            .catch(error => console.log('error', error));

        let splitted = convertStringToArray(manualTrainees);

        let selectedTrainee = [];

        for (let i = 0; i < splitted.length; i++) {
            const element = splitted[i];

            let showing = allTrainees.filter((item) => {
                return item.email_address === element
            })
            if (showing.length != 0) {
                selectedTrainee.push(showing[0])
            }
        }

        if (selectedTrainee.length >= 1) {
            setTraineeres(selectedTrainee)
        }
    }, [manualTrainees])
    useEffect(() => {
        const fetchData = () => {
            const allResults = `${process.env.REACT_APP_BASE_URL}/api/all_select_options/${dets.uniq_id}`;
            const getAllResults = axios.get(allResults);
            axios.all([getAllResults]).then(
                axios.spread((...allData) => {
                    const allRes = allData[0].data;
                    setTracks(allRes.tracks);
                    setSchema(allRes.schemas)
                    setSession(allRes.session)
                    let newArray = Array.from({ length: allRes.offers.offering_number }, (_, index) => index + 1);
                    setCurrentOffering(newArray)
                })
            )
        }
        fetchData()
    }, [])
    function searchTrainee(e) {
        const query = e.target.value
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("query", query);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchstudents`, requestOptions)
            .then(response => response.json())
            .then(result => setTraineeres(result))
            .catch(error => console.log('error', error));

    }
    const [selectedSchematic, setSelectedScematicArea] = useState(null);
    useEffect(() => {
        let track = tracki;
        let offering = assingInfo.offering;
        let schematic = selectedSchematic;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("id", track);
        urlencoded.append("offering", offering);
        urlencoded.append("schema", schematic);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/trackcourses`, requestOptions)
            .then(response => response.json())
            .then(result => setCoursesres(result))
            .catch(error => console.log('error', error));
    }, [tracki, assingInfo.offering, selectedSchematic])

    function getTrackCourses(e) {
        const track = e.target.value;
        setTracki(track);
        setArrays([]);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("id", track);
        urlencoded.append("offering", null);
        urlencoded.append("schema", selectedSchematic);


        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/trackcourses`, requestOptions)
            .then(response => response.json())
            .then(result => setCoursesres(result))
            .catch(error => console.log('error', error));
    }
    function selectTrainee(id, studentname) {
        const stdData = {
            id: id,
            stdname: studentname,
        }
        setStud(stdData)
    }
    function selectMultiple(id, studentname) {
    }
    const [toast, addToast] = useState(0)
    const [importTrainee, setImportTrainee] = useState(false)
    const toaster = useRef()
    // const checker = useRef()
    function removeStud(array) {
        let filtered = studArrays.filter(function (ele) {
            return ele != array;
        });

        if (setStudArrays(filtered)) {
            Swal.fire({
                title: 'Student Added',
                icon: 'success',
                confirmButtonText: 'close',
                timer: 1000,
            });
        };
    }
    function selectAllStudents(e) {
        let stat = e.target.checked;
        if (stat == true) {
            setStudArrays(prevStudArrays => {
                let updatedStudArrays = [];

                for (let index = 0; index < traineeres.length; index++) {
                    let trainee = traineeres[index];
                    const student_data = {
                        stud_id: trainee.id,
                        stud_name: trainee.first_name + trainee.last_name,
                    }
                    updatedStudArrays = updatedStudArrays.concat(student_data);
                }

                return updatedStudArrays;
            });
        } else {
            setStudArrays([]);
        }
    }
    function addStudent(id, stud_name) {
        const student_data = {
            stud_id: id,
            stud_name: stud_name,
        }
        const student = studArrays.find(stud => {
            if (stud.stud_id === student_data.stud_id) {
                return true;
            } else {
                return false;
            }
        });
        if (student != undefined) {
            removeStud(student);
        } else {
            setStudArrays(studArrays.concat(student_data))
        }

    }
    function removeCourse(array) {
        let filtered = arrays.filter(function (ele) {
            return ele != array
        })
        setArrays(filtered);
    }
    function addCourse(id, course_title) {
        const student_data = {
            course_id: id,
            course_title: course_title,
        }
        const student = arrays.find(course => {
            if (course.course_id === student_data.course_id) {
                return true;
            }
            return false;
        });
        if (student != undefined) {
            Swal.fire({
                title: 'Course Selected Already',
                icon: 'error',
                confirmButtonText: 'close'
            })

        } else {
            setArrays(arrays.concat(student_data))
        }

    }
    function showallTrainee() {
        setMultiple(!multiple);

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/students/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setTraineeres(result))
            .catch(error => console.log('error', error));
    }
    function submitFinalCourse() {
        if (assingInfo.session === null || assingInfo.session === "" || assingInfo.session === 'none') {
            Swal.fire({
                title: 'Kindly Select A Session',
                icon: 'error',
                confirmButtonText: 'close'
            })
        } else 
        if (arrays.length === 0 || selectedStud === "") {
            Swal.fire({
                title: 'Kindly Select A Trainee and Courses',
                icon: 'error',
                confirmButtonText: 'close'
            })
        } else {

            arrays.map(course => {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                var urlencoded = new URLSearchParams();
                urlencoded.append("student_id", selectedStud.id);
                urlencoded.append("launched_id", course.course_id);
                urlencoded.append("session", assingInfo.session);
                urlencoded.append("semester", assingInfo.semester);
                urlencoded.append("offering", assingInfo.offering);
                urlencoded.append("center_id", dets.uniq_id);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: urlencoded,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/api/assign_student`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        addToast(exampleToast(result.message, result.status))
                    })
                    .catch(error => console.log('error', error));
            })
        }
    }

    async function submitMultiple() {
        if (assingInfo.session === null || assingInfo.session === "" || assingInfo.session === 'none') {
            Swal.fire({
                title: 'Kindly Select A Session',
                icon: 'error',
                confirmButtonText: 'close'
            })
        } else if (arrays.length === 0 || studArrays.length === 0) {
            Swal.fire({
                title: 'Kindly Select A Trainee and Courses',
                icon: 'error',
                confirmButtonText: 'close'
            })
        } else {
            if (studArrays.lenght < 20) {
                var delay = 200;
            } else {
                var delay = 1000;
            }
            for (const student of studArrays) {
                for (const course of arrays) {
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

                    var urlencoded = new URLSearchParams();
                    urlencoded.append("student_id", student.stud_id);
                    urlencoded.append("launched_id", course.course_id);
                    urlencoded.append("session", assingInfo.session);
                    urlencoded.append("semester", assingInfo.semester);
                    urlencoded.append("offering", assingInfo.offering);
                    urlencoded.append("center_id", dets.uniq_id);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: urlencoded,
                        redirect: 'follow'
                    };

                    try {
                        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/assign_student`, requestOptions);
                        const result = await response.json();
                        addToast(exampleToast(result.message, result.status));
                    } catch (error) {
                        console.log('error', error);
                    }

                    // Introduce a delay of, for example, 500 milliseconds between requests
                    await new Promise(resolve => setTimeout(resolve, delay));
                }
            }
        }
    }



    return (
        <>
            <CToaster ref={toaster} push={toast} placement="top-end" />
            {/* <button className='btn btn-primary' onClick={() => }>add toast</button> */}
            {
                notify === 'loading' && (
                    <p className="text-success  text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="py-4 d-flex align-items-center">
                Register Trainee to Course
            </h3>

            {/* Course Number: {coursesres.length} */}
            <div className='d-flex justify-content-between mb-3'>
                <button onClick={() => {
                    setMultiple(true)
                    setImportTrainee(true)
                }} className='btn btn-danger'>Import Students Data</button>

                <button onClick={showallTrainee}
                    required type="text" name="name" className="btn btn-success" >
                    {
                        multiple ? "Select Single Students " : "Select Multiple Students"
                    }

                </button>
            </div>
            {
                importTrainee &&
                <div>
                    <textarea onChange={(e) => {
                        setManualTrainees(e.target.value);
                    }} cols="30" className='w-100' rows="10"></textarea>
                </div>
            }

            <div className='row justify-content-center'>
                <div className="col-6 mb-3">
                    <label htmlFor="name">Select Academic Session</label>
                    <select onChange={(e) => Setassign(
                        { ...assingInfo, session: e.target.value })} required className="form-control">
                        <option selected value="none" key="">--- select session ----</option>
                        {Array.isArray(Session) && Session.map(data => {
                            return (
                                <option value={data.id} key={data.id}>{data.title}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <div className="col-6 mb-3">
                    <label htmlFor="name">Schematic Areas</label>
                    <select onChange={(e) => {
                        setSelectedScematicArea(e.target.value);
                    }} className="form-control">
                        <option selected value="none" key="">--- select a schematic area ----</option>
                        {Array.isArray(schema) && schema.map(scheme => {
                            return (
                                <option value={scheme.id} key={scheme.id}>{scheme.title}</option>
                            )
                        })
                        }
                    </select>
                </div>
                <div className="col-6 mb-3">
                    <label htmlFor="name">Select Track</label>
                    <select onChange={getTrackCourses} className="form-control">
                        <option selected value="none" key="">--- select a track ----</option>
                        {Array.isArray(tracks) && tracks.map(tracks => {
                            return (
                                <option value={tracks.id} key={tracks.id}>{tracks.title}</option>
                            )
                        })
                        }
                    </select>
                </div>
                {
                    tracki === ' ' || tracki != 1 ? <></> : <>
                        <div className="col-6 mb-3">
                            <label htmlFor="name">Offering Number</label>
                            <select onChange={(e) => Setassign(
                                { ...assingInfo, offering: e.target.value })} required className="form-control">
                                <option selected value="none">--- select offering number ----</option>
                                {Array.isArray(currentOffering) && currentOffering.map((item) => {
                                    return (
                                        <option value={item}>{toOrdinal(item)} </option>
                                    )
                                })}

                            </select>

                        </div>
                    </>
                }
                {
                    tracki === ' ' || tracki != 5 ? <></> : <>
                        <div className="col-6 mb-3">
                            <label htmlFor="name">Select Semester</label>
                            <select onChange={(e) => Setassign(
                                { ...assingInfo, semester: e.target.value })} required className="form-control">
                                <option selected value="none">--- select semester ----</option>
                                <option value="rain">1st Semester</option>
                                <option value="harmattan">2nd Semester</option>
                            </select>
                        </div>
                    </>
                }

                <div className="col-6 mb-3">

                    <label htmlFor="name">Search Trainee Name</label>
                    <div className='input-group'>
                        <input onChange={searchTrainee}
                            required type="text" name="name" className="form-control" />

                    </div>

                </div>


            </div>

            <div className="card p-4" action="" >
                <div className='row mb-3 justify-content-between'>
                    <div className='mb-3'>
                        <input type="checkbox" checked={selectAll} onClick={(e) => {
                            setSelectAll(!selectAll)
                            selectAllStudents(e);
                        }} />
                    </div>
                    <div className='col-5' style={{ height: '500px', overflowY: 'scroll', }}>
                        {Array.isArray(traineeres) && traineeres.map(trainee => {
                            return (
                                <><div>
                                    {
                                        multiple ?
                                            <input checked={selectAll} onClick={multiple ? () => { addStudent(trainee.id, trainee.first_name + trainee.last_name) } : () => {
                                                selectTrainee(`${trainee.id}`, `${trainee.first_name} ${trainee.last_name} `)
                                            }
                                            } className='me-2' key={trainee.id} value={trainee.id}
                                                type={multiple ? "checkbox" : "radio"} name='trainees' />
                                            :
                                            <input onClick={multiple ? () => { addStudent(trainee.id, trainee.first_name + trainee.last_name) } : () => {
                                                selectTrainee(`${trainee.id}`, `${trainee.first_name} ${trainee.last_name} `)
                                            }
                                            } className='me-2' key={trainee.id} value={trainee.id}
                                                type={multiple ? "checkbox" : "radio"} name='trainees' />
                                    }

                                    <label> {trainee.first_name} {trainee.last_name} </label>
                                </div>

                                </>
                            )
                        })
                        }

                    </div>
                    <div className='col-7' style={{ height: '500px', overflowY: 'scroll', }}>
                        {Array.isArray(coursesres) && coursesres.map(coursesres => {
                            return (
                                <><div className='d-flex' onClick={() => {
                                    addCourse(`${coursesres.id}`, `${coursesres.course_title}(${coursesres.schematic_title})`)
                                }}>
                                    <AddCircle className='text-success' />
                                    <p key={coursesres.id} value={coursesres.course_id} > {coursesres.course_title}({coursesres.schematic_title} ) -- {coursesres.offering}</p>
                                </div>
                                </>
                            )

                        })

                        }

                    </div>
                </div>
                <hr />
                <div>
                    <div>Course Registration Details</div>

                    <div>
                        <div>Student Name <span className='fw-bold'>{multiple ? "multiple" : selectedStud.stdname}</span></div>
                        <div>Course Selected</div>
                        <div className='table-responsive'>
                            <table className='table table-hover table-sm '>
                                <thead>
                                    <th>S/N</th>
                                    <th>
                                        Course title
                                    </th>
                                </thead>
                                <tbody>
                                    {Array.isArray(arrays) && arrays.map(data => {
                                        return (
                                            <tr>
                                                <td>{arrays.indexOf(data) + 1}</td>
                                                <td className='row justify-content-between' value={data.id} key={data.id}>
                                                    <div className='col-10'>
                                                        {data.course_title}
                                                    </div>
                                                    <div className='col-2'>
                                                        <RemoveCircle
                                                            onClick={() => {
                                                                removeCourse(data)
                                                            }} className='text-danger' />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

                <div>
                    <div className=" text-center pt-3 singleSubmits">
                        <button onClick={multiple ? submitMultiple : submitFinalCourse} className="btn w-100 text-info rounded-0">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssignCourses
