import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { CircularProgress } from '@mui/material';

import RightsideCenters from '../../components/centers/loginComponent/rigthSide';

import speseeLogo from '../../image/spesee.png'
import worldbank from '../../image/worldBank.png'
import nuc from '../../image/nuclogo.png'
import FooterComp from '../../components/footer';
import { useNavigate } from 'react-router-dom';
import { isLoggenIn } from '../../utilities/auth';


function Otp() {
    const navigate = useNavigate()
    const [bearer_key, setBearer_key] = useState(' ');
    const [email, setEmail] = useState(' ');
    const [dets, setDets] = useState(localStorage.getItem('user'));
    const [state, setState] = useState({ otp: "" });
    // const [otpstate, setotpState] = useState([])
    const [notify, setNotify] = useState(' ')
    const [loading, setLoading] = useState(' ');
    const [userDetails, setUserDetails] = useState({
        id: " ",
        center_name: " ",
        center_code: " ",
        email: " ",
        phone_number: "",
        logo: ' ',
        state_id: ' ',
        lga_id: ' ',
        center_otp: " ",
        type: " ",
        added_at: " ",
        address: "",
        status: " ",
        bearer_toke: "",
    })

    useEffect(() => {
        if (window) {
            setBearer_key(window.localStorage.getItem("bearer_token"));
        }
    }, []);
    const [otp, setOtp] = useState([])
    const [filled, setFilled] = useState([])
    useEffect(() => {
        setOtp(filled.join(''))

    }, [filled])

    let inputArray = []
    function handleInput(e) {
        let inputVal = e.target.value;
        if (inputVal != [] && inputVal.length == 1) {
            if (e.target.nextSibling != null) {
                e.target.nextSibling.focus()
                setFilled(filled.concat(inputVal))
            } else {
                inputArray.push(inputVal)
                setFilled(filled.concat(inputVal))
            }
        } else {
            if (e.target.previousSibling != null) {
                e.target.previousSibling.focus()
            } else {
                setFilled([])
            }
        }
    }
    function redirect() {
        navigate('/dashboard');
    }
    const verifyOtp = async () => {
        setNotify(' ')

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("token", bearer_key);
        urlencoded.append("otp", otp);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/verifycenterOtp`, requestOptions)
        const data = await response.json()
        const status = response.status
        if (status == 200) {
            setNotify(' ')
            Swal.fire({
                title: 'OTP Verified Successfully',
                icon: 'success',
                timer: 1000,
            })
            redirect()

            // const res = await signIn('credentials', {
            //     token: 'verified',
            //     redirect: false,
            // })
            // if (res.error == null && res.status == 200) {
            //     redirect()
            // }
        } else if (status == 201) {
            Swal.fire({
                title: 'Incorrect OTP!',
                text: 'kindly check your mail for the correct OTP',
                icon: 'error',
                timer: 1000,
            })
            setLoading(' ')
        } else {
            setNotify('No Authorization')
            Swal.fire({
                title: 'No Authorization!',
                text: 'Invalid Request',
                icon: 'error',
                timer: 1000,
            })
        }
    }

    function resendOtp() {

        let token = bearer_key;

        // console.log(token)

        var formdata = new FormData();
        formdata.append("token", `${token}`);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/resendOtpCenters`, requestOptions)
            .then(response => response.text())
            .then(result => {
                Swal.fire({
                    title: 'success',
                    text: 'Otp Resent',
                    timer: 1000
                })
                // console.log(result)
            })
            .catch(error => console.log('error', error));
    }
    isLoggenIn();

    return (
        <>
            <div className="conttt">

                <div className="loginCont  py-sm-0">
                    <div className="row align-items-stretch">
                        <div className="col-12 col-lg-6 d-flex  order-1 order-lg-0 justify-content-center py-sm-0">
                            <div className="center_login p-2 p-lg-5">
                                <div className="">
                                    <div className="col-12 order-1 order-lg-0">
                                        <h3 className="mb-5 fw-bold">CENTER FOR EXCELLENCE PORTAL (SPESSE)</h3>
                                        {
                                            notify != " " && (
                                                <p className="text-danger fw-bold">{notify}</p>)
                                        }
                                        <h5 className='fw-bold'>ENTER OTP</h5>

                                        <div className="">
                                            <div className="d-flex OtpComponent">
                                                <input maxLength={1} onChange={handleInput} className="" type="text" />
                                                <input maxLength={1} onChange={handleInput} className="" type="text" />
                                                <input maxLength={1} onChange={handleInput} className="" type="text" />
                                                <input maxLength={1} onChange={handleInput} className="" type="text" />
                                                <input maxLength={1} onChange={handleInput} className="" type="text" />
                                            </div>
                                        </div>
                                        <div className='btn-group mt-3 p-2 '>
                                            <button onClick={verifyOtp} className='btn btn-dark align-items-center justify-content-center d-flex p-2'>
                                                {
                                                    loading == 'loading' && (<CircularProgress size='1.5rem' color="inherit" />)
                                                }
                                                verify Otp
                                            </button>
                                            <button onClick={resendOtp} className='btn btn-light p-2'>
                                                Resend Otp
                                            </button>
                                        </div>

                                    </div>

                                </div>
                                <div className="text-center mt-5 pt-5">
                                    <FooterComp />
                                </div>
                            </div>

                        </div>
                        <div className="col-12 carousellogos col-lg-6 d-flex justify-content-center order-0 py-5 order-lg-2">
                            <RightsideCenters spesee={speseeLogo} nuclogo={nuc} worldBank={worldbank} />
                        </div>
                    </div>

                </div >
            </div>
        </ >
    );
}
export default Otp;