
import { useEffect, useState } from "react";
import AllNavs from "../../../components/allNavs";
import AssignCourses from "../../../components/centers/course/assignCourses";
import NewtopNAv from "../../../components/centers/dashboardtwo/newtopNav";
import Secondnav from "../../../components/centers/dashboardtwo/secondsidenav";
import MoveNewLevel from "../../../components/centers/gradandlevel/moveNewLevel";
import TopPilsItems from "../../../components/centers/toppills";
import { isLoggenIn } from "../../../utilities/auth";



function CurrentLevelItem() {
    const [stdQual, setStdQual] = useState(' ')

    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [showNav, setShowNav] = useState(false)
    function navState(ClickedNav) {
        setShowNav(ClickedNav)
    }
    isLoggenIn()
    return <>
        <div className="container-fluid">
            <div>
                <div className="p-3">
                    <NewtopNAv naviState={navState} />
                </div>
            </div>
            <div className="row ">
                <div className={(showNav == true) ? `d-block d-lg-none col-md-3 d-flex allNavSide` : `d-none`}>
                    <AllNavs />
                </div>
                <div className="col-1 subNav row">
                    <AllNavs />
                </div>
                <div className="col-12 col-lg-11 regMain">
                    <div className="p-2">
                        <TopPilsItems />
                    </div>

                    <div className="row pt-3">
                        <div className="d-none d-lg-block col-2 border bg-info border-1">
                            <Secondnav />
                        </div>

                        <div className="col-12 col-lg-10 p-lg-3 regMain">
                            <MoveNewLevel />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default CurrentLevelItem;

