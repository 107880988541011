import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import AllNavs from "../../../../components/allNavs";
import NewtopNAv from "../../../../components/centers/dashboardtwo/newtopNav";
import Secondnav from "../../../../components/centers/dashboardtwo/secondsidenav";
import EditLect from "../../../../components/centers/students/editLectComp";
import EditStudentComp from "../../../../components/centers/students/editStudComp";
import TopPilsItems from "../../../../components/centers/toppills";

function EditFacl() {

    const [bearer_key, setBearer_key] = useState(' ');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    useEffect(() => {
        if (window) {
            setBearer_key(window.localStorage.getItem("bearer_token"));

        }
    }, []);

    const [showNav, setShowNav] = useState(false)
    function navState(ClickedNav) {
        setShowNav(ClickedNav)
    }
    const [storedTime, setStoredTime] = useState(new Date(localStorage.getItem('sessionTime')));
    var currentTime = new Date();

    var timeDifference = currentTime - storedTime;
    var expirationTime = 60 * 60 * 1000; // 1 hour

    if (timeDifference > expirationTime || dets == null || dets == '') {
        console.log('Session expired');
        window.location.href = "/";
    }
    return <>
        <div className="container-fluid">
            <div>
                <div className="p-3">
                    <NewtopNAv naviState={navState} />
                </div>
            </div>
            <div className="row ">
                <div className={(showNav == true) ? `d-block d-lg-none col-md-3 d-flex allNavSide` : `d-none`}>
                    <AllNavs />
                </div>
                <div className="col-1 subNav row">
                    <AllNavs />
                </div>
                <div className="col-12 col-lg-11 regMain">
                    <div className="p-2">
                        <TopPilsItems />
                    </div>
                    <div className="row pt-3">
                        <div className="d-none d-lg-block col-2 border bg-info border-1">
                            <Secondnav />
                        </div>
                        <div className="col-12 col-lg-10 p-lg-3">
                            <EditLect />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    return (
        <div className="justify-content-center">
            <div className="text-center ">
                <CircularProgress />
            </div>
        </div>
    )
}

export default EditFacl;
