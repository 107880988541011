import { USER_DATA } from "./auth";


export function saveActivity(activity) {
    var formdata = new FormData();
    if (USER_DATA.permission_type == 'supper') {
        formdata.append("username", "Super Admin");
    } else {
        formdata.append("username", USER_DATA.name);
    }
    formdata.append("activity", activity)
    formdata.append("center_id", USER_DATA.uniq_id);

    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };
    fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
}



export function convertStringToArray(emailString) {

    const dataArray = emailString.split(/[,\s\n]+/);
    const emailArray = dataArray.filter(entry => /\S+@\S+\.\S+/.test(entry));

    return emailArray;
}

export function toOrdinal(number) {
    let lastDigit = number % 10;
    let suffix = "";
    if (lastDigit === 1 && number !== 11) {
        suffix = "st";
    } else if (lastDigit === 2 && number !== 12) {
        suffix = "nd";
    } else if (lastDigit === 3 && number !== 13) {
        suffix = "rd";
    } else {
        suffix = "th";
    }

    return number + suffix;
}
