import { CircularProgress } from "@mui/material";


import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function CreatCourseComp(props) {
    const navigate = useNavigate();
    const [notify, setNotify] = useState(' ');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const [courseInfo, setCourseInfo] = useState({
        course_title: " ",
        course_code: " ",
        credits: "",
        offerings: "",

    });
    const createCourse = async (e) => {

        e.preventDefault()
        setNotify('loading')
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("Cookie", "nucapi_session=eyJpdiI6ImpUa0dkS2paM0tlai9KVXVwR0hESVE9PSIsInZhbHVlIjoibG5DU1JheTd0dHFJWUwrWXIwdWUvTzdWQ3RjS2VKeEV5M1NlYTlvK3c2TGI2SzYwSjBZOTBPNVFGaVBYSVJsRjJtOW53VWxpMVNKdEwzWEhLbElsa0xtQzlmZHVtc1NtOTJvUGUrVUpEWlFTVnpobkF4ekYwNHBOVFJPV3BuY0EiLCJtYWMiOiIxM2M5ZjI3MWVjMWIwMDg4NWU5ODY5NGYzYTM3MDM3NGM3MzQxYTJlYjc1NDk2NWQ5NTc4YmJkOTAyYWE1MDk2IiwidGFnIjoiIn0%3D");
        var urlencoded = new URLSearchParams();
        urlencoded.append("course_code", courseInfo.course_code);
        urlencoded.append("course_title", courseInfo.course_title);
        urlencoded.append("center_id", dets.uniq_id);
        urlencoded.append("credits", courseInfo.credits);
        urlencoded.append("offerings", courseInfo.offerings);

        const createCourse = async () => {
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/create_course`, requestOptions)
            const data = await response.json()
            const status = response.status;
            if (status === 200) {
                setNotify('Course Created Succesfully')
                Swal.fire({
                    title: 'Course Created Successfully',
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                navigate('/allcourses')
            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close'
                })
            }
        }
        createCourse();
    };
    return (
        <>
            {
                notify === 'loading' && (
                    <p className="text-success text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="py-4">
                Create Course
            </h3>
            <form className="card p-4" action="" onSubmit={createCourse}>
                <div className="mb-3">
                    <label htmlFor="session">Course Title</label>
                    <input onChange={(e) => setCourseInfo(
                        { ...courseInfo, course_title: e.target.value })} required type="text" name="course_title" className="form-control" />
                </div>
                <div className="mb-3">
                    <label htmlFor="session_end">Course Code</label>
                    <input onChange={(e) => setCourseInfo(
                        { ...courseInfo, course_code: e.target.value })} required type="text" name="course_code" className="form-control" />
                </div>
                <div className="mb-3">
                    <label htmlFor="session_end">Number of Credits</label>
                    <input onChange={(e) => setCourseInfo(
                        { ...courseInfo, credits: e.target.value })} required placeholder="Enter total number of credits if available" type="number" name="course_code" className="form-control" />
                </div>
                <div className="mb-3">
                    <label htmlFor="session_end">Offerings per year</label>
                    <input onChange={(e) => setCourseInfo(
                        { ...courseInfo, offerings: e.target.value })} required placeholder="Enter number of offerings if available" type="number" name="course_code" className="form-control" />
                </div>
                <div className="col-5 m-auto singleSubmits">
                    <button type="submit" className="btn rounded-0  text-info w-100">Save</button>
                </div>
            </form>
        </>

    );
}
export default CreatCourseComp;