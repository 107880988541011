import { CloseSharp } from '@mui/icons-material';
import axios, { Axios } from 'axios';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';

function ImportStu() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [showedFailed, setFailed] = useState(false)

    const [file, setFile] = useState({
        file: null,
    })
    const [courses, setCourses] = useState({
        file: null,
    })
    const [faculties, setFaculties] = useState({
        file: null,
    })

    const [failedImports, setFailedImports] = useState()
    // console.log(file.file);
    const uploadfile = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('filll', file.file,);
        formData.append('center_id', dets.uniq_id);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/importdata`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            let data = response.data
            console.log(response.data);
            if (data.status == 'success') {
                Swal.fire({
                    title: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                setFailedImports('')
            } else {
                Swal.fire({
                    title: response.data.message,
                    icon: 'warning',
                    confirmButtonText: 'close'
                })
                setFailedImports(data.failedImports)
            }

        } catch (error) {
            console.error(error);
        }
    };

    const uploadcourses = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('filll', courses.file,);
        formData.append('center_id', dets.uniq_id);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/importcourses`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            let data = response.data
            console.log(response.data);
            if (data.status == 'success') {
                Swal.fire({
                    title: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                setFailedImports('')

            } else {
                Swal.fire({
                    title: response.data.message,
                    icon: 'warning',
                    confirmButtonText: 'close'
                })
                setFailedImports(data.failedImports)

            }
        } catch (error) {
            console.error(error);
        }
    };
    const uploadfaculty = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('filll', faculties.file,);
        formData.append('center_id', dets.uniq_id);

        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/importfaculty`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            let data = response.data
            console.log(response.data);
            if (data.status == 'success') {
                Swal.fire({
                    title: response.data.message,
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                setFailedImports('')

            } else {
                Swal.fire({
                    title: response.data.message,
                    icon: 'warning',
                    confirmButtonText: 'close'
                })
                setFailedImports(data.failedImports)


            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <>

            {
                showedFailed &&
                <div className='p-5 d-flex justify-content-center align-items-center' style={{
                    position: 'absolute',
                    backgroundColor: 'white',
                    zIndex: 100,
                    top: 0,
                    right: 0,
                }}>
                    <div>
                        <div className='text-end'>
                            <div onClick={() => { setFailed(false) }}>
                                <CloseSharp />
                            </div>
                        </div>
                        <div>
                            <h5 className='text-danger'>Failed Imports!</h5>
                        </div>
                        {Array.isArray(failedImports) && failedImports.map((item) => {
                            return <p>{item}</p>
                        })}
                    </div>

                </div>
            }

            <div className='pb-5'>
                Kindly Select the file you want to import(note the file should be saved in csv format*)
            </div>
            <div className='card p-4'>
                {Array.isArray(failedImports) &&
                    < div >
                        <button className='btn btn-sm btn-danger' onClick={() => { setFailed(true) }}>Show Failed Imports</button>
                    </div>
                }

                <div className='p-4'>
                    <div className='text-dark'>
                        <form onSubmit={uploadfile} method="POST" enctype="multipart/form-data">
                            <div className='mb-3'>
                                <label htmlFor='upload_student'>Upload Students data</label>
                                <input accept='.xlsx' onChange={(e) => setFile(
                                    { ...file, file: e.target.files[0] })
                                } type="file" className='form-control' name='upload_stundent' />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <a className='btn btn-success btn-sm' href="https://host.spesse.edu.ng/images/traineestemplate.xlsx">Download Sample</a>
                                <div className='singleSubmits'>
                                    <button className='btn rounded-0'>submit</button>
                                </div>
                            </div>

                        </form>
                    </div>

                </div>

                <div className='p-4'>
                    <div className='text-dark'>
                        <form onSubmit={uploadfaculty} method="POST" enctype="multipart/form-data">
                            <div className='mb-3'>
                                <label htmlFor='upload_student'>Upload Faculties data</label>
                                <input accept='.xlsx' onChange={(e) => setFaculties(
                                    { ...file, file: e.target.files[0] })
                                } type="file" className='form-control' name='upload_stundent' />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <a className='btn btn-success btn-sm' href="https://host.spesse.edu.ng/images/facultiestemplate.xlsx">Download Sample</a>
                                <div className='singleSubmits'>
                                    <button className='btn rounded-0'>submit</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                <div className='p-4'>
                    <div className='text-dark'>
                        <form onSubmit={uploadcourses} method="POST" enctype="multipart/form-data">
                            <div className='mb-3'>
                                <label htmlFor='upload_student'>Upload Courses data</label>
                                <input accept='.xlsx' onChange={(e) => setCourses(
                                    { ...file, file: e.target.files[0] })
                                } type="file" className='form-control' name='upload_stundent' />
                            </div>
                            <div className='d-flex justify-content-between align-items-center'>
                                <a className='btn btn-success btn-sm' href="https://host.spesse.edu.ng/images/coursestemplate.xlsx">Download Sample</a>
                                <div className='singleSubmits'>
                                    <button className='btn rounded-0'>submit</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ImportStu
