

import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ActivityComponent() {
    const navigate = useNavigate()

    const [display, SetDisplay] = useState('activity');

    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [activity, setactivity] = useState('')
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/getlogsactivity/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setactivity(result.logs)

            })
            .catch(error => console.log('error', error));

    }, []);
    function userchange() {
        navigate('/settings/addusers');
    }
    function profileChange() {
        navigate('/settings');
    }



    return (
        <>
            <div className='my-5 py-4 row bg-white shadow'>
                <div className='col-2'>

                    <p className={display === 'profile' ? 'border-end rounded-2 border-2 border-success p-2' : ' '} onClick={profileChange}>
                        Profile Settings
                    </p>
                    {dets.permission_type != 'View' &&
                        <>
                            <p className={display === 'activity' ? ' ' : 'border-end rounded-2 border-2 border-success p-2'} onClick={userchange}>
                                User Permission
                            </p>
                            <p className={display === 'activity' && 'border-end rounded-2 border-2 border-success p-2'} onClick={() => {
                                navigate('/activitylogs')
                            }}>
                                Activity Logs
                            </p>
                        </>
                    }
                </div>
                <div className='col-9'>
                    <div className='px-5'>
                        <h3>Logs</h3>

                        {
                            Array.isArray(activity) && (activity.map(item => {
                                return (
                                    <>
                                        <li>{item.username} --- {item.activity_carried} -- {item.created_at}</li>
                                    </>
                                )
                            }))
                        }
                    </div>


                </div>
            </div >
        </>
    )
}

export default ActivityComponent
