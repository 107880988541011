import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: false,
  lastLogin: "",
  userData: null,
};

export const authStore = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    login: (state, payload) => {
      state.isLoggedIn = true;
      state.lastLogin = new Date().toISOString();
    },
    logout: (state) => {
      state.userData = null;
      state.isLoggedIn = false;
    },
  },
});

export const {
  login,
  logout,
} = authStore.actions;

export default authStore.reducer;
