
import { useEffect, useState } from "react"

import { CircularProgress } from "@mui/material"
import Secondnav from "../../../components/centers/dashboardtwo/secondsidenav"
import FirstsideNav from "../../../components/centers/dashboardtwo/firstSidenav"
import NewtopNAv from "../../../components/centers/dashboardtwo/newtopNav"
import AllNavs from "../../../components/allNavs"
import TopPilsItems from "../../../components/centers/toppills"
import CreatCourseComp from "../../../components/centers/course/createCourse"
import FooterComp from "../../../components/footer"



function CreateCourse
    () {
    const [showNav, setShowNav] = useState(false)
    function navState(ClickedNav) {
        // alert(ClickedNav)
        setShowNav(ClickedNav)
    }
    const [bearer_key, setBearer_key] = useState(' ');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    useEffect(() => {
        if (window) {
            setBearer_key(window.localStorage.getItem("bearer_token"));
            // setDets(JSON.parse(window.localStorage.getItem('user')));
        }
    }, []);
    const [storedTime, setStoredTime] = useState(new Date(localStorage.getItem('sessionTime')));
    var currentTime = new Date();

    var timeDifference = currentTime - storedTime;
    var expirationTime = 60 * 60 * 1000; // 1 hour

    if (timeDifference > expirationTime || dets == null || dets == '') {
        console.log('Session expired');
        window.location.href = "/";
    }
    return <>
        <div className="container-fluid">
            <div>
                <div className="p-3">
                    <NewtopNAv naviState={navState} />
                </div>
            </div>
            <div className="row ">
                <div className={(showNav == true) ? `d-block d-lg-none col-md-3 d-flex allNavSide` : `d-none`}>
                    <AllNavs />
                </div>
                <div className="col-1 subNav row">
                    <AllNavs />
                </div>
                <div className="col-12 col-lg-11 regMain">
                    <div className="pb-4 px-2">
                        <TopPilsItems />
                    </div>
                    <div className="row pt-3">
                        <div className="d-none d-lg-block col-2 border bg-info border-1">
                            <Secondnav />
                        </div>
                        <div className="col-12 col-lg-10 p-lg-3">
                            <CreatCourseComp details={dets} bearer={bearer_key} />

                            {/* <CreatCourse /> */}
                        </div>
                    </div>
                </div>
                <div className="container text-center mt5  pt-5">
                    <FooterComp />
                </div>

            </div>
        </div>
    </>
    // return (
    //     <div className="justify-content-center">
    //         <div className="text-center ">
    //             <CircularProgress />
    //         </div>
    //     </div>
    // )
}

export default CreateCourse
