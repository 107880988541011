
import { CircularProgress, Input } from '@mui/material';

import { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';



function AllLecturers(props) {
    // const { details, bearer } = props
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [delay, setDelay] = useState(' ')
    const [lecturers, setLecturers] = useState(' ')
    const [del, setDe] = useState('')

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/faculties/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setLecturers(result.reverse()))
            .catch(error => console.log('error', error));
    }, [del])
    // console.log(lecturers);
    function deleteLec(param) {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const deleteFac = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/delete_faculty/ ${param}`, requestOptions)
            const data = await response.json()
            // console.log(response.status)
            if (response.status == 200) {
                // setNotify('Faculty Deleted Successfully')
                Swal.fire({
                    title: 'Faculty Deleted Succesfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                setDe(Math.random());
            } else if (response.status == 400) {
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close',
                    timer: 1000
                })
            }
            return data;
        }

        deleteFac()
    }

    function searchFaculty(e) {
        let query = e.target.value;

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("query", query);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchfaculty`, requestOptions)
            .then(response => response.json())
            .then(result => setLecturers(result))
            .catch(error => console.log('error', error));
    }
    return (<div>
        <div className='d-flex align-items-center justify-content-between py-4'>
            <input onChange={searchFaculty} type="text" className='form-control w-50' placeholder='search faculty by details' />
        </div>
        <div className="bg-info p-4 shadow rounded-0 table-responsive">

            <div>
                <h6 className="fw-bold">Total No of Faculties/Trainers: {lecturers.length}</h6>
            </div>
            <table className="tableData table table-sm  table-striped table-hover">
                <thead>
                    <tr>
                        <th>FIRST NAME</th>
                        <th>OTHER NAME</th>

                        <th>LAST NAME</th>
                        <th>EMAIL</th>
                        <th>PHONE</th>
                        <th>HIGHEST QUALIFICATION</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {lecturers == ' ' ? <p> Add/Import Faculties to see list</p> :
                        lecturers.map(lecturer => {
                            return (
                                <tr key={lecturers.indexOf(lecturer)} className='align-items-center '>
                                    <td>{lecturer.first_name}</td>
                                    <td>{lecturer.other_name}</td>

                                    <td>{lecturer.last_name}</td>
                                    <td>{lecturer.email_address}</td>
                                    <td>{lecturer.phone_number}</td>
                                    <td>{lecturer.highest_qual}</td>
                                    <td >
                                        <div className='btn-group'>

                                            <button className='btn btn-sm btn-success'>
                                                <a href={`/viewfaculty/${lecturer.id}`} >
                                                    View
                                                </a>
                                            </button>
                                            {dets.permission_type != 'View' &&

                                                <button onClick={() => deleteLec(`${lecturer.id}`)} className='btn btn-sm btn-danger'>
                                                    Delete
                                                </button>
                                            }
                                        </div>


                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    </div >);
}
export default AllLecturers;