import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Add, PlusOneOutlined, Remove, RemoveCircle } from "@mui/icons-material";
import Home from "@mui/icons-material/Home";
import { useNavigate, useParams } from "react-router-dom";

function EditStudentComp(props) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [bearer_key, setBearer_key] = useState(' ');
    const [notify, setNotify] = useState(' ');
    const [programs, setProgram] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [department, setDepartment] = useState([]);
    const [qual, setqual] = useState([]);
    const [Inst, setInst] = useState([]);

    const [userInfo, setUserInfo] = useState({
        lastname: " ",
        name: " ",
        other_name: " ",
        email: " ",
        phone: " ",
        heighest_qualification: " ",
        qualifications: "",
        age: " ",
        current_level: " ",
        track: "",
        student_status: " ",
        sex: "",
        Nationality: " ",
        employee: "Nil",
        employee_type: "Nil",
        employment_status: "Nil",
    });
    const [loading, setloading] = useState(false);
    const editstudent = async (e) => {
        e.preventDefault()
        setloading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("first_name", userInfo.name);
        urlencoded.append("other_name", userInfo.other_name);

        urlencoded.append("phone_number", userInfo.phone);
        urlencoded.append("last_name", userInfo.lastname);
        urlencoded.append("email_address", userInfo.email);
        urlencoded.append("gender", userInfo.sex);
        urlencoded.append("age", userInfo.age);
        urlencoded.append("track", userInfo.track);
        urlencoded.append("student_status", userInfo.student_status);
        urlencoded.append("current_level", userInfo.current_level);
        urlencoded.append("nationality", userInfo.Nationality);
        urlencoded.append("highest_qual", userInfo.heighest_qualification);
        urlencoded.append("qualifications", userInfo.qualifications);
        urlencoded.append("employment_stat", userInfo.employment_status);
        urlencoded.append("employement_sector", userInfo.employee_type);
        urlencoded.append("employer", userInfo.employee);
        urlencoded.append("center_id", dets.uniq_id);
        urlencoded.append("user_id", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/update_student`, requestOptions)
        const data = response.json()
        if (response.status == 200) {
            setloading(false);
            Swal.fire({
                title: 'Student Updated Succesfully',
                icon: 'success',
                confirmButtonText: 'close'

            })
            navigate('/trainees/all');
        } else {
            Swal.fire({
                title: 'An error Occured Kindly Check your inputs',
                icon: 'error',
                confirmButtonText: 'close'
            })
        }
    }
    useEffect(() => {
        const editstudent = async () => {
            var requestOptions = {
                method: 'get',
                redirect: 'follow'
            };

            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/single_student/${id}`, requestOptions)
            const data = await response.json()
            if (response.status == 200) {

                setUserInfo({
                    lastname: data.last_name,
                    other_name: data.other_name,
                    name: data.first_name,
                    email: data.email_address,
                    phone: data.phone_number,
                    heighest_qualification: data.highest_qual,
                    age: data.age,
                    sex: data.gender,
                    current_level: data.current_level,
                    track: data.track,
                    student_status: data.student_status,
                    Nationality: data.nationality,
                    qualifications: data.qualifications,
                    employee: data.employer,
                    employee_type: data.employement_sector,
                    employment_status: data.employment_stat,
                })
                // console.log(data)

            } else {

            }
        }
        editstudent()
    }, [])

    return (
        <>
            {
                notify == 'loading' && (
                    <p className="text-success  text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="py-4 d-flex align-items-center">
                Edit Trainee Information
            </h3>
            <form className="card p-4" action="" onSubmit={editstudent} >
                <fieldset className="row">
                    <legend>Student Status</legend>
                    <div className="col-6 mb-3">
                        <label htmlFor="name">New or Continuing Student</label>
                        <select required name="nationality" onChange={(e) => setUserInfo(
                            { ...userInfo, student_status: e.target.value })} value={userInfo.student_status} class="form-select" aria-label="New or continuing student selection"  >
                            <option selected value={0}>--is student new or continuing--</option>
                            <option value={'New'}>New</option>
                            <option value={'Continuing'}>Continuing</option>
                        </select>
                    </div>
                    {userInfo.student_status !== '' &&
                        <>
                            <div className="col-6 mb-3">
                                <label htmlFor="name">Track</label>
                                <select required name="nationality" onChange={(e) => setUserInfo(
                                    { ...userInfo, track: e.target.value })} value={userInfo.track} class="form-select" aria-label="track select"  >
                                    <option selected value={0}>--select student track--</option>
                                    <option value={1}>Track A</option>
                                    <option value={2}>Track B</option>
                                    <option value={3}>Track C</option>
                                    <option value={4}>Track D</option>
                                    <option value={5}>Track E</option>

                                </select>
                            </div>
                            {
                                userInfo.track === '5' && userInfo.student_status === 'Continuing' &&
                                <div className="col-6 mb-3">
                                    <label htmlFor="name">Level</label>
                                    <select name="programme" onChange={(e) => setUserInfo(
                                        { ...userInfo, current_level: e.target.value })} value={userInfo.current_level} class="form-select" aria-label="student level select ">
                                        <option selected>Select Level</option>
                                        <option value="100" key="">100 || Level</option>
                                        <option value="200" key="">200 || Level</option>
                                        <option value="300" key="">300 || Level</option>
                                        <option value="400" key="">400 || Level</option>
                                        <option value="500" key="">500 || Level</option>
                                    </select>
                                </div>
                            }

                        </>
                    }
                </fieldset>
                <fieldset className="row">
                    <legend>Personal Data</legend>

                    <div className="col-6 mb-3">
                        <label htmlFor="name">First Name</label>
                        <input value={userInfo.name} onChange={(e) => setUserInfo(
                            { ...userInfo, name: e.target.value })}
                            required type="text" name="name" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="name">Other Name</label>
                        <input value={userInfo.other_name} onChange={(e) => setUserInfo(
                            { ...userInfo, other_name: e.target.value })}
                            required type="text" name="name" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="lastName">Last Name</label>
                        <input value={userInfo.lastname} onChange={(e) => setUserInfo(
                            { ...userInfo, lastname: e.target.value })}
                            required type="text" name="lastName" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="phone">Telephone number</label>
                        <input value={userInfo.phone} onChange={(e) => setUserInfo(
                            { ...userInfo, phone: e.target.value })} type="number" required name="phone" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="email">Email</label>
                        <input value={userInfo.email} onChange={(e) => setUserInfo(
                            { ...userInfo, email: e.target.value })} type="text" required name="email" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="age">Age</label>
                        <input value={userInfo.age} required onChange={(e) => setUserInfo(
                            { ...userInfo, age: e.target.value })} type="number" name="age" className="form-control"  >
                        </input>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="nationality">Nationality</label>
                        <select value={userInfo.Nationality} required name="nationality" onChange={(e) => setUserInfo(
                            { ...userInfo, Nationality: e.target.value })} class="form-select" aria-label="Default select example"  >
                            <option selected value={0}>Select your Nationality</option>
                            <option value={'Nigeria'}>Nigeria</option>
                            <option value={'Foreign'}>Foreign</option>
                        </select>
                    </div>
                    {/* {
                    userInfo.Nationality == 'Nigeria' && <>
                        <div className="col-6 mb-3">
                            <label htmlFor="state">State of Origin</label>
                            <select required name="state" onChange={(e) => setUserInfo(
                                { ...userInfo, state: e.target.value })} class="form-select" aria-label="Default select example"  >
                                <option selected value={0}>Select your State</option>
                                {
                                    state.map(State => {
                                        return (
                                            <option value={State.id}>{State.title}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-6 mb-3">
                            <label htmlFor="lga">LGA</label>
                            <select required name="lga" onChange={(e) => setUserInfo(
                                { ...userInfo, lga: e.target.value })} class="form-select" aria-label="Default select example"  >
                                <option selected>Select your LGA</option>
                                {
                                    lga.map(lga => {
                                        return (
                                            <option value={lga.id}>{lga.Lga}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                    </>
                } */}


                    <div className=" col-6 mb-3">
                        <label htmlFor="sex">Gender</label>
                        <select value={userInfo.sex} required onChange={(e) => setUserInfo(
                            { ...userInfo, sex: e.target.value })} type="text" name="address" className="form-control" >
                            <option value="none" selected>Select your gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="highest_qualifcation">Highest Qualifcation</label>
                        <select value={userInfo.heighest_qualification} required name="highest_qualifcation" onChange={(e) => setUserInfo(
                            { ...userInfo, heighest_qualification: e.target.value }
                        )} class="form-select" aria-label="Default select example">

                            <option value={'None'}>Select your qualification</option>
                            <option value="SSCE">SSCE(Senior School Certificate Examination)</option>
                            <option value="ND">ND(National Diploma)</option>
                            <option value="HND">HND(Higher National Diploma)</option>
                            <option value="BTECH">BTECH(Bachelor of Technology)</option>
                            <option value="BENG">BENG(Bachelor of Engineering)</option>
                            <option value="BSC">BSC(Bachelor of Science)</option>
                            <option value="MSC">MSC(Master of Science)</option>
                            <option value="MBA">MBA(Master of Business Adminstration)</option>
                            <option value="PHD">PHD(Philosophiae Doctor)</option>
                        </select>
                    </div>
                    <div className="col mb-3">
                        <label htmlFor="qualifcations">All Qualifications</label>
                        <input value={userInfo.qualifications} placeholder="enter your qualifications seperated by comma" required name="qualifcations" onChange={(e) => setUserInfo(
                            { ...userInfo, qualifications: e.target.value }
                        )} class="form-control" aria-label="Default select example" />
                    </div>
                    {/* <div className="mb-3">
                        <label htmlFor="address">Address</label>
                        <textarea value={userInfo.address} required onChange={(e) => setUserInfo(
                            { ...userInfo, address: e.target.value })} type="text" name="address" className="form-control" > </textarea>
                    </div> */}

                </fieldset>
                {/* Educaional Background */}
                {/* <fieldset>
                <legend>
                    Educational Information
                </legend>
                <div className="mb-3 row ">
                    <div className="col-6 mb-3">
                        <label htmlFor="falculty">Faculty</label>
                        <select required name="department" onChange={(e) => setUserInfo(
                            { ...userInfo, faculty_id: e.target.value })} class="form-select" aria-label="Default select example"  >

                            <option selected value={0}>Select your Faculty</option>
                            {
                                faculties.map(faculty => {
                                    return (
                                        <option value={faculty.id}>{faculty.title}</option>

                                    )
                                })

                            }

                        </select>
                    </div>

                    <div className="col-6 mb-3">
                        <label htmlFor="department">Department</label>
                        <select required name="department" onChange={(e) => setUserInfo(
                            { ...userInfo, department_id: e.target.value })} class="form-select" aria-label="Default select example">

                            <option selected value={0}>Select your Department</option>
                            {
                                department.map(department => {
                                    return (
                                        <option value={department.id}>{department.title}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="programme">Course</label>
                        <select required name="programme" onChange={(e) => setUserInfo(
                            { ...userInfo, programme_id: e.target.value })} class="form-select" aria-label="Default select example">

                            <option selected>Select your course</option>
                            {
                                programs.map(program => {
                                    return (
                                        <option value={program.id}>{program.title}</option>

                                    )
                                })

                            }

                        </select>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="level">Level </label>
                        <select required onChange={(e) => setUserInfo(
                            { ...userInfo, level: e.target.value })} type='text' name="level" className="form-control" >

                            <option value={'2020'}>Kindly select your level</option>
                            <option value="100">Year 1</option>
                            <option value="200">Year 2</option>
                            <option value="300">Year 3</option>
                            <option value="400">Year 4</option>
                            <option value="500">Year 5</option>

                        </select>
                    </div>
                    <form className="qualification date">
                        <fieldset><legend>Educational Qualifications</legend>
                            <div className="row align-items-center">
                                <div className="col-6 mb-3">
                                    <label htmlFor="highest_qualifcation">Highest Qualifcation</label>
                                    <select onClick={looper} required name="highest_qualifcation" onChange={(e) => setUserInfo(
                                        { ...userInfo, heighest_qualification: e.target.value }
                                    )} class="form-select" aria-label="Default select example">

                                        <option value={'None'}>Select your qualification</option>
                                        <option value="SSCE">SSCE</option>
                                        <option value="HND">HND</option>
                                        <option value="BSC">BSC</option>
                                        <option value="MSC">MSC</option>
                                        <option value="PHD">PHD</option>
                                    </select>
                                </div>
                                <div className="col-6 mb-3">
                                    <label htmlFor="year_finished">Year Finished</label>
                                    <select required onChange={(e) => setUserInfo(
                                        { ...userInfo, heighest_qualification_year: e.target.value })} type='text' name="year_finished" className="form-control" >
                                        {loope == ' ' ? <option > none </option> :
                                            loope.map(program => {
                                                return (
                                                    <option value={`${program.i}`}>{program.i}</option>

                                                )
                                            })

                                        }
                                    </select>
                                </div>



                            </div>
                            <div className="row align-items-center">
                                <div className="col-3 mb-3">
                                    <label htmlFor="qualification">Qualification</label>
                                    <select required name="qualification" onChange={(e) => setQualArray(
                                        { ...qaulArray, qualification_id: e.target.value }
                                    )} class="form-select" aria-label="Default select example">

                                        <option selected onClick={event => setQualname(event, 'Select your Qualification')}>Select your qualification</option>
                                        {
                                            qual.map(qual => {

                                                return (
                                                    <option onClick={event => setQualname(event, qual.qualification)} value={qual.id}>{qual.qualification}</option>

                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-3 mb-3">
                                    <label htmlFor="academic">Year Finished</label>
                                    <select required onChange={(e) => setQualArray(
                                        { ...qaulArray, year: e.target.value })} type='text' name="year_finished" className="form-control">
                                        {loope == ' ' ?
                                            <option value='2020'>Select Year Finished</option> :
                                            loope.map(program => {
                                                return (
                                                    <option value={`${program.i}`}>{program.i}</option>

                                                )
                                            })

                                        }
                                    </select>
                                  
                                </div>
                                <div className="col-3 mb-3">
                                    <label htmlFor="institute">Institution</label>
                                    <select required name="institute" onChange={(e) => setQualArray(
                                        { ...qaulArray, institution_id: e.target.value })} class="form-select" aria-label="Default select example">
                                        <option selected>Select your institution</option>
                                        {
                                            Inst.map(Inst => {
                                                return (
                                                    <option onClick={event => setInstname(event, Inst.name)} value={Inst.id}>{Inst.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-3 text-center"> <button onClick={handleQualAdd} className="btn  btn-dark"><Add /></button>
                                </div>
                            </div>
                            <hr />
                            {
                                arrayys.map(array => {
                                    return (
                                        <div className="row">
                                            <div className="col-3 mb-3">
                                                <input required value={array.qualification_name} type="text" name="academic" className="form-control" />
                                            </div>
                                            <div className="col-3 mb-3">
                                                <input required value={array.year} type="text" name="academic" className="form-control" />
                                            </div>
                                            <div className="col-3 mb-3">
                                                <input required value={array.institution_name} type="text" name="academic" className="form-control" />
                                            </div>
                                            <div className="col-3 text-center">
                                                <button onClick={e => removeQual(e, array)} className=" btn border border-0 text-danger"><RemoveCircle /></button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </fieldset>
                    </form>
                </div>
            </fieldset> */}

                <fieldset>
                    <legend>
                        Employment Information
                    </legend>
                    <div className="mb-3">
                        <label htmlFor="employmentStatus">Employment Status</label>
                        <select value={userInfo.employment_status} required name="employmentStatus" onChange={(e) => setUserInfo(
                            { ...userInfo, employment_status: e.target.value })} class="form-select" aria-label="employement status" >
                            <option selected  >What's your employent Status</option>
                            <option value={'Employed'}>Employed</option>
                            <option value={'Unemployed'} >Unemployed</option>
                        </select>
                    </div>
                    {
                        userInfo.employment_status == 'Employed' &&
                        <div className="mb-3 row ">
                            <div className="col-6 mb-3">
                                <label htmlFor="Employee">Employee</label>
                                <input value={userInfo.employee} placeholder="where do you work" required name="employee" onChange={(e) => setUserInfo(
                                    { ...userInfo, employee: e.target.value })} class="form-control" aria-label="" />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="EmployeeType">Employee Type</label>
                                <select value={userInfo.employee_type} required name="employeeType" onChange={(e) => setUserInfo(
                                    { ...userInfo, employee_type: e.target.value })} class="form-control" aria-label="Default select example">
                                    <option selected value={'None'}> What type of organization do you work for</option>
                                    <option value="Private">Private</option>
                                    <option value="Public">Public</option>
                                </select>
                            </div>
                        </div>
                    }
                    <div className="col-5 m-auto singleSubmits">
                        {
                            loading ? <p class="text-center"><CircularProgress
                                color="inherit" /></p> : ' '
                        }
                        <button type="submit" className="btn rounded-0  text-info w-100">
                            Submit</button>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
export default EditStudentComp;