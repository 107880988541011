import { AccountBoxRounded, AddBox, Apartment, Book, BookOnline, GifBox, School } from "@mui/icons-material";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Progress } from "reactstrap";

function Useractivity() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const [activity, setActivity] = useState('')

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/studentActivity?center_id=${dets.uniq_id}`,
            headers: {
                // 'Authorization': `Bearer ${bearer}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data;
                    setActivity(data)
                    return data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData();
    }, [])
    return (<div className="  ">
        {
            activity != "" && (
                <div className="p-4">
                    <p className="fw-bold fs-5">Portal Summary</p>
                    <p>Total number of Student: {activity.total}</p>
                    <div className="py-4">
                        <Progress
                            style={{
                                height: '35px',
                                borderRadius: "0px"

                            }}
                            multi>
                            <Progress
                                bar
                                value={activity.male}
                            />
                            <Progress
                                bar
                                color="success"
                                value={activity.female}
                            />
                        </Progress>
                    </div>
                    <div className="d-flex ">
                        <div className={`p-2 col-${(activity.male / 100 * 12).toFixed(0) - 2}`}>
                            <span className="fw-bold fs-6">{activity.male.toFixed(2)}%</span>
                            <div className="d-flex activityIcons justify-content-start align-items-center">
                                {/* <div>
                                    <AddBox />
                                </div> */}
                                <span>Male Trainees</span>
                            </div>
                        </div>
                        <div className={`p-2  col-${Number((activity.female / 100 * 12).toFixed(0)) + 2}`}>
                            <span className="fw-bold fs-6">{activity.female.toFixed(2)}%</span>
                            <div className="d-flex activityIcons justify-content-start align-items-center">
                                {/* <div>
                                    <AddBox />
                                </div> */}
                                <span>Female Trainees</span>
                            </div>
                        </div>
                    </div>
                    <div className="py-4">
                        <Progress
                            style={{
                                height: '35px',
                                borderRadius: "0px"
                            }}
                            multi>
                            <Progress
                                bar
                                color="success"
                                value={activity.faculty_male}
                            />
                            <Progress
                                bar
                                color="danger"
                                value={activity.faculty_female}
                            />
                        </Progress>
                    </div>
                    <div className="d-flex ">
                        <div className={`p-2 col-${(activity.faculty_male / 100 * 12).toFixed(0) - 2}`}>
                            <span className="fw-bold fs-6">{activity.faculty_male.toFixed(2)}%</span>
                            <div className="d-flex activityIcons justify-content-start align-items-center">
                                {/* <div>
                                    <AddBox />
                                </div> */}
                                <span>Male Faculties</span>
                            </div>
                        </div>
                        <div className={`p-2  col-${Number((activity.faculty_female / 100 * 12).toFixed(0)) + 2}`}>
                            <span className="fw-bold fs-6">{activity.faculty_female.toFixed(2)}%</span>
                            <div className="d-flex activityIcons justify-content-start align-items-center">
                                {/* <div>
                                    <AddBox />
                                </div> */}
                                <span>Female faculties</span>
                            </div>
                        </div>
                    </div>
                    <div className="py-4">
                        <div className="d-flex align-items-center">
                            <span className="col-3 d-flex justify-content-between">Students <span><School /></span></span>
                            <span className="col-7 px-2"><Progress
                                color="danger"
                                style={{
                                    height: '10px',
                                }}
                                value={activity.female}
                            /></span>
                            <span className="col-2">{activity.total}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="col-3 d-flex justify-content-between">Courses <span><Book /></span> </span>
                            <span className="col-7 px-2"><Progress
                                color="primary"
                                animated

                                style={{
                                    height: '10px',
                                }}
                                value={54}
                            /></span>
                            <span className="col-2">{activity.courses}</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="col-3 d-flex justify-content-between">Faculty <span><Apartment /></span> </span>
                            <span className="col-7 px-2"><Progress
                                color="newcol3"
                                style={{
                                    height: '10px',
                                }}
                                value={85}
                            /></span>
                            <span className="col-2">{activity.faculties}</span>
                        </div>
                    </div>
                </div>
            )
        }
    </div >);
}

export default Useractivity;
