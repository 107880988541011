import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toOrdinal } from "../../utilities/fetchutil";


function EditProgramlaunching(props) {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    // const { details, bearer } = props
    const { id } = useParams();
    const [notify, setNotify] = useState(' ');
    const [tracks, setTracks] = useState('');
    const [schema, setSchema] = useState('');
    const [courses, setCourses] = useState('');
    const [cert, setCerts] = useState(' ');

    const [currentOffering, setCurrentOffering] = useState('');
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/get_offer/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.offers == null) {
                    setCurrentOffering([])
                } else {
                    let newArray = Array.from({ length: result.offers.offering_number }, (_, index) => index + 1);
                    setCurrentOffering(newArray)
                }
            })
            .catch(error => console.log('error', error));
    }, []);


    const [launchInfo, setlaunchInfo] = useState({
        announcement_date: " ",
        announcement_link: " ",
        schema_id: " ",
        track_id: " ",
        level: 1,
        start_date: " ",
        end_date: " ",
        first_delivery_date: " ",
        offerings: " ",
        course_id: " ",
        mode_of_delivery: " ",
        certificate_id: ' ',
        other_media_link: " ",
    });

    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;
            const singleLaunched = `${process.env.REACT_APP_BASE_URL}/api/singlelaunched/${id}`;
            const allCourses = `${process.env.REACT_APP_BASE_URL}/api/courses/${dets.uniq_id}`;
            const allCert = `${process.env.REACT_APP_BASE_URL}/api/certificate/${dets.uniq_id}`;

            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);
            const getallCourse = axios.get(allCourses);
            const getallCert = axios.get(allCert);
            const getLaunched = axios.get(singleLaunched);


            axios.all([getallSchema, getallTracks, getallCourse, getallCert, getLaunched]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    const allcourses = allData[2].data;
                    const allCerts = allData[3].data;
                    const launchedSing = allData[4].data;
                    setTracks(alltracks)
                    setSchema(allScema)
                    setCourses(allcourses)
                    setCerts(allCerts)
                    setlaunchInfo({
                        announcement_date: launchedSing.announcement_date,
                        announcement_link: launchedSing.announcement_link,
                        schema_id: launchedSing.schematic_id,
                        track_id: launchedSing.track_id,
                        mode_of_delivery: launchedSing.mode_of_delivery,
                        start_date: launchedSing.start_date,
                        end_date: launchedSing.end_date,
                        level: launchedSing.level,

                        first_delivery_date: launchedSing.first_delivery,
                        offerings: launchedSing.offerings,
                        course_id: launchedSing.course_id,
                        certificate_id: launchedSing.certificate_id,
                        other_media_link: launchedSing.other_media_link,
                    })

                })
            )
        }
        fetchData()
    }, [])

    const programlaunch = async (e) => {
        e.preventDefault()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("announcement_date", launchInfo.announcement_date);
        urlencoded.append("announcement_link", launchInfo.announcement_link);
        urlencoded.append("schema_id", launchInfo.schema_id);
        urlencoded.append("track_id", launchInfo.track_id);
        urlencoded.append("start_date", launchInfo.start_date);
        urlencoded.append("end_date", launchInfo.end_date);
        urlencoded.append("first_delivery", launchInfo.first_delivery_date);
        urlencoded.append("offerings", launchInfo.offerings);
        urlencoded.append("other_media_link", launchInfo.other_media_link);
        urlencoded.append("course_id", launchInfo.course_id);
        urlencoded.append("center_id", dets.uniq_id);
        urlencoded.append("certificate_id", launchInfo.certificate_id);
        urlencoded.append("level", launchInfo.level);
        urlencoded.append("mode_of_delivery", launchInfo.mode_of_delivery);
        urlencoded.append("launched_id", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        setNotify('loading')

        const programlauncher = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/editlaunch_course`, requestOptions)
            const data = await response.json()
            const status = response.status;

            if (data.status == 'success') {

                setNotify('Program Update')
                Swal.fire({
                    title: 'Program Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                window.location.href = '/launchcourse/launchedcourses';
            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured!!!',
                    icon: 'error',
                    confirmButtonText: 'close',
                    timer: 1000
                })
            }
        }
        programlauncher()
    }
    return (<>
        {
            notify == 'loading' && (
                <p className="text-success text-center fw-bold"><CircularProgress /></p>
            )
        }
        {
            notify != ' ' && (
                <p className="text-success text-center fw-bold">{notify}</p>)
        }
        <h3 className="py-4">
            Edit Launched Course
        </h3>
        <form className="card p-4" action="" onSubmit={programlaunch}>
            <div className="row">
                <div className="mb-3 col-6">
                    <label htmlFor="programme">Schematic Areas</label>
                    <select name="programme" value={launchInfo.schema_id} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, schema_id: e.target.value })} required class="form-select" aria-label="Default select example">

                        <option selected>Select schematic area</option>
                        {Array.isArray(schema) && schema.map(schema => {
                            return (
                                <option key={schema.id} value={schema.id}>{schema.title}</option>)
                        })

                        }
                    </select>
                </div>
                <div className="mb-3 col-6">
                    <label htmlFor="programme">Tracks</label>
                    <select name="programme" value={launchInfo.track_id} disabled
                        onChange={(e) => setlaunchInfo(
                            { ...launchInfo, track_id: e.target.value })} required
                        class="form-select" aria-label="Default select example">
                        <option selected>Select track</option>
                        {Array.isArray(tracks) && tracks.map(tracks => {
                            return (
                                <option key={tracks.id} value={tracks.id}>{tracks.title}</option>)
                        })}
                    </select>

                </div>
                {
                    launchInfo.track_id == ' ' || launchInfo.track_id != 5 ? <></> : <>
                        <div className="mb-3 col-6">
                            <label htmlFor="programme">Level</label>
                            <select name="programme" value={launchInfo.level} required onChange={(e) => setlaunchInfo(
                                { ...launchInfo, level: e.target.value })} class="form-select" aria-label="Default select example">
                                <option selected>Select Level</option>
                                <option value="100" key="">100 || Level</option>
                                <option value="200" key="">200 || Level</option>
                                <option value="300" key="">300 || Level</option>
                                <option value="400" key="">400 || Level</option>
                                <option value="500" key="">500 || Level</option>

                            </select>
                        </div>
                    </>
                }
                {
                    launchInfo.track_id == ' ' || launchInfo.track_id == 1 ? <></> : <>
                        <div className="mb-3 col-6">
                            <label htmlFor="programme">Certificate Title</label>
                            <select name="programme" value={launchInfo.certificate_id} required onChange={(e) => setlaunchInfo(
                                { ...launchInfo, certificate_id: e.target.value })} class="form-select" aria-label="Default select example">
                                <option selected>Select Certificate</option>
                                {Array.isArray(cert) && cert.map(certs => {
                                    return (
                                        <option key={certs.id} value={certs.id}>{certs.title}</option>)
                                })

                                }
                            </select>

                        </div>
                    </>
                }
                <div className="mb-3 col-6">
                    <label htmlFor="programme">Course</label>
                    <select name="programme" value={launchInfo.course_id} required onChange={(e) => setlaunchInfo(
                        { ...launchInfo, course_id: e.target.value })} class="form-select" aria-label="Default select example">

                        <option selected>Select course</option>
                        {Array.isArray(schema) && courses.map(courses => {
                            return (
                                <option key={courses.id} value={courses.id}>{courses.course_title}</option>)
                        })

                        }

                    </select>
                </div>
                <div className="mb-3 col-6">
                    <label htmlFor="delivery">Mode of Delivery</label>
                    <select name="delivery" value={launchInfo.mode_of_delivery} required onChange={(e) => setlaunchInfo(
                        { ...launchInfo, mode_of_delivery: e.target.value })} class="form-select" aria-label="Default select example">
                        <option selected>Select Mode of Delivery</option>
                        <option value={'Physical'}>Physical</option>
                        <option value={'Online'}>Online</option>
                        <option value={'Hybrid'}>Hybrid</option>

                    </select>
                </div>

                <div className="mb-3 col-6">
                    <label htmlFor="date">Anouncement Date</label>
                    <input value={launchInfo.announcement_date} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, announcement_date: e.target.value })} required
                        type="date" name="date" className="form-control" />
                </div>
                <div className="mb-3 col-6">
                    <label htmlFor="startdate">Start Date</label>
                    <input value={launchInfo.start_date} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, start_date: e.target.value })} required
                        type="date" name="startdate" className="form-control" />
                </div>
                <div className="mb-3 col-6">
                    <label htmlFor="enddate">End Date</label>
                    <input value={launchInfo.end_date} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, end_date: e.target.value })} required
                        type="date" name="enddate" className="form-control" />
                </div>

                <div className="mb-3 col-6">
                    <label htmlFor="date">First Delivery Date</label>
                    <input value={launchInfo.first_delivery_date} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, first_delivery_date: e.target.value })} required
                        type="date" name="date" className="form-control" />
                </div>
                <div className="mb-3 col-6">

                    <label htmlFor="name">Offering Number</label>
                    <select value={launchInfo.offerings} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, offerings: e.target.value })} required className="form-control">
                        <option selected value="none">--- select offering number ----</option>
                        {Array.isArray(currentOffering) && currentOffering.map((item) => {
                            return (
                                <option value={item}>{toOrdinal(item)} </option>
                            )
                        })}
                    </select>


                    {/* <label htmlFor="date">Offerings</label>
                    <input onChange={(e) => setlaunchInfo(
                        { ...launchInfo, offerings: e.target.value })} required
                        type="number" name="date" className="form-control" /> */}
                </div>
                <div className="mb-3 col-6">
                    <label htmlFor="link">Announcement Link </label>
                    <input value={launchInfo.announcement_link} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, announcement_link: e.target.value })} required type="text" name="link" className="form-control" />
                </div>
                <div className="mb-3 col-6">
                    <label htmlFor="mediaLink">Other Media Link </label>
                    <input value={launchInfo.other_media_link} onChange={(e) => setlaunchInfo(
                        { ...launchInfo, other_media_link: e.target.value })} required type="text" name="mediaLink" className="form-control" />
                </div>
            </div>




            <div className="col-5 m-auto singleSubmits">
                <button type="submit" className="btn rounded-0  text-info w-100"> Submit</button>
            </div>
        </form>
    </>);
}

export default EditProgramlaunching;