import { Eject } from '@mui/icons-material';
import { CircularProgress, Input } from '@mui/material';
import axios from 'axios';


import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { saveActivity } from '../../utilities/fetchutil';
import CourseFeedback from '../centers/dashboardone/coursefeedback';



function CourseDetailRegister(props) {
    const { id } = useParams();
    const [searchResult, setSearchResult] = useState('')
    const [trainees, settrainees] = useState('');
    const [faculties, setfaculties] = useState('');
    const [selected, setSelected] = useState('trainees');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [newUpdate, setNewUpdate] = useState('');

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/courseregistrant/${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data;
                    setSearchResult(data.course)
                    settrainees(data.students)
                    setfaculties(data.faculties)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData();
    }, [newUpdate])
    function markCompleted(stud_id, std_name, std_email) {
        var formdata = new FormData();
        formdata.append("id", stud_id);
        formdata.append("course_id", id);
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/markcompleted`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 'success') {
                    const activity = `Marked  ${std_name}(${std_email}) as Finishing ${searchResult.course_title}`;
                    saveActivity(activity);
                    setNewUpdate(Math.random())
                }
                else {
                    console.log(result.message)
                }
            }
            )
            .catch(error => console.log('error', error));
    }
    function deleteRegistrant(stud_id, std_name, std_email) {

        if (selected == 'trainees') {
            var url = 'deletecourseregistrant';
        } else {
            var url = 'deletefacultyregistrant'
        }
        var requestOptions = {
            method: 'get',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/${url}/${stud_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 'success') {
                    const activity = `Removed  ${std_name}(${std_email}) from ${searchResult.course_title}`;
                    saveActivity(activity);
                    Swal.fire({
                        icon: 'success',
                        title: 'Deleted',
                        text: 'Successfully',
                        timer: 1000,

                    })
                    setNewUpdate(Math.random())
                }
                else {
                    console.log(result.message)
                }
            }
            )
            .catch(error => console.log('error', error));
    }
    return (<div>
        <div className='bg-white p-4 shadow'>
            {searchResult != ' ' &&
                <div className='row align-items-center'>
                    <div className='col-12 col-lg-6'>
                        <div className='mb-3'>
                            <span className='fw-bold' > Track:</span>  {searchResult.track_title}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Schematic Area: </span>   {searchResult.schematic_title}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Course Name: </span>   {searchResult.course_title}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' >  Mode of Delivery:</span>   {searchResult.mode_of_delivery}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Course Start date:</span>  {searchResult.start_date}
                        </div>
                        <div className='mb-3'>
                            <span className='fw-bold' > Course End date:</span>  {searchResult.end_date}
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <CourseFeedback />
                    </div>

                    <hr />
                </div>

            }
            <div className='d-flex align-items-center justify-content-between py-4'>
                <button onClick={() => {
                    setSelected('trainees')
                }} className='btn btn-outline-success'>
                    View Trainees
                </button>
                <button onClick={() => {
                    setSelected('faculties')
                }} className='btn btn-outline-success'>
                    View Faculties
                </button>
            </div>
        </div>
        <div className="bg-info p-4 shadow rounded-0">
            <div className=''>
                <div className=''>
                    <div className='text-end mb-3'>
                        {/* <button className='btn btn-success'><Eject /> Export Data</button> */}
                    </div>
                    <div className='text-dark table-responsive'>

                        {

                            selected == 'trainees' ?
                                <>
                                    <table className='table table-sm table-hover'>
                                        <thead className='fw-bold'>
                                            <th className='fw-bold'>No</th>
                                            <th className='fw-bold'>Trainee Name</th>
                                            <th className='fw-bold'>Phone Number</th>
                                            <th className='fw-bold'>Email</th>
                                            <th className='fw-bold'>Gender </th>
                                            <th className='fw-bold' >Age</th>
                                            <th className='fw-bold'>Nationality</th>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(trainees) && trainees.map(Result => {
                                                return (
                                                    <tr key={Result.id}>
                                                        <td>
                                                            {trainees.indexOf(Result) + 1}
                                                        </td>
                                                        <td>
                                                            <a href={`/viewtrainee/${Result.trainee_id}`} > {Result.first_name + ' ' + Result.last_name}</a>
                                                        </td>
                                                        <td>{Result.phone_number}</td>

                                                        <td>
                                                            {Result.email_address}
                                                        </td>
                                                        <td>
                                                            {Result.gender}
                                                        </td>
                                                        <td>{Result.age}</td>
                                                        <td>{Result.nationality}</td>
                                                        <td>
                                                            {
                                                                Result.completed_status != 'completed' ?
                                                                    <button onClick={() => {
                                                                        markCompleted(Result.id, Result.first_name, Result.email_address)
                                                                    }} className="btn btn-sm btn-success">
                                                                        Mark Completed
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-sm btn-primary">
                                                                        Completed
                                                                    </button>
                                                            }

                                                        </td>
                                                        <td>
                                                            {
                                                                Result.ratings != null ?
                                                                    <button className="btn btn-sm btn-success">
                                                                        Has rated
                                                                    </button>
                                                                    :
                                                                    <button className="btn btn-sm btn-danger">
                                                                        Not rated
                                                                    </button>
                                                            }

                                                        </td>
                                                        <td>
                                                            <button className='btn btn-danger btn-sm' onClick={() => {
                                                                deleteRegistrant(Result.id, Result.first_name, Result.email_address)
                                                            }}>
                                                                Remove
                                                            </button>
                                                        </td>


                                                    </tr>

                                                )
                                            })}

                                        </tbody>
                                    </table>
                                </>

                                :
                                <>
                                    <table className='table table-sm table-hover'>
                                        <thead className='fw-bold'>
                                            <th className='fw-bold'>S/N</th>
                                            <th className='fw-bold'>Faculty Name</th>
                                            <th className='fw-bold'>Phone Number</th>
                                            <th className='fw-bold'>Email</th>
                                            <th className='fw-bold'>Gender </th>
                                            <th className='fw-bold' >Age</th>
                                            <th className='fw-bold'>Highest Qualification</th>
                                            <th className='fw-bold'>Nationality</th>
                                            <th className='fw-bold'>Partnership</th>

                                        </thead>
                                        <tbody>
                                            {Array.isArray(faculties) && faculties.map(Result => {
                                                return (

                                                    <tr key={Result.id}>
                                                        <td>
                                                            {faculties.indexOf(Result) + 1}
                                                        </td>
                                                        <td>
                                                            {Result.first_name}  {Result.last_name}
                                                        </td>
                                                        <td>{Result.phone_number}</td>
                                                        <td>
                                                            {Result.email_address}
                                                        </td>

                                                        <td>
                                                            {Result.gender}
                                                        </td>
                                                        <td>{Result.age}</td>
                                                        <td>{Result.highest_qual}</td>
                                                        <td>{Result.nationality}</td>
                                                        <td>{Result.partnership}</td>
                                                        <td>
                                                            <button className='btn btn-danger btn-sm' onClick={() => {
                                                                deleteRegistrant(Result.id, Result.first_name, Result.email_address)
                                                            }}>
                                                                Remove
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </>
                        }

                    </div>
                </div>
            </div>
            {/* <div>

            </div>
            <table className="tableData table table-sm table-striped table-responsive table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>TITLE</th>
                        <th>CODE</th>
                        <th className='text-center'>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Array.isArray(faculty) && faculty.map(data => {
                            return (
                                <tr className='align-items-center '>
                                    <td><span><img src="" alt="" /></span> {faculty.indexOf(data) + 1}</td>
                                    <td>{data.title}</td>
                                    <td>{data.code}</td>
                                    <td className='text-center'><div className='btn-group'>
                                        <button className='btn btn-primary btn-sm p-2'>
                                            <Link href={`/centers/department/edit/${data.id}`}>
                                                Edit
                                            </Link>

                                        </button>
                                        <button onClick={() => deleteDept(`${data.id}`)} className='btn btn-sm btn-danger p-2'>
                                            Delete
                                        </button>
                                    </div></td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table> */}
        </div>
    </div >);
}
export default CourseDetailRegister;