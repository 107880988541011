import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { saveActivity } from '../../utilities/fetchutil';


function EditCertDets() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    const { id } = useParams();
    const [notify, setNotify] = useState(' ');


    const [tracks, setTracks] = useState('');

    const [stdData, setStdData] = useState(' ')
    const [stdQual, setStdQual] = useState(' ')

    const [certinfo, setcertinfo] = useState({
        cert_title: " ",
        credits: " ",
        number_of_courses: " ",
        dateapproved: " ",
        approvedby: " ",
        nucdateapproval: " ",
        launchdate: " ",
        track: " ",
        schedule: " ",
        doc: null,
    });
    useEffect(() => {
        const fetchData = () => {
            const allSchematicAreas = `${process.env.REACT_APP_BASE_URL}/api/schematic_areas`;
            const allTracks = `${process.env.REACT_APP_BASE_URL}/api/tracks`;
            const allCourses = `${process.env.REACT_APP_BASE_URL}/api/courses/${dets.uniq_id}`;
            const allCert = `${process.env.REACT_APP_BASE_URL}/api/certificate/${dets.uniq_id}`;

            const getallSchema = axios.get(allSchematicAreas);
            const getallTracks = axios.get(allTracks);
            const getallCourse = axios.get(allCourses);
            const getallCert = axios.get(allCert);

            axios.all([getallSchema, getallTracks, getallCourse, getallCert]).then(
                axios.spread((...allData) => {
                    const allScema = allData[0].data;
                    const alltracks = allData[1].data;
                    const allcourses = allData[2].data;
                    const allCerts = allData[3].data;
                    setTracks(alltracks)
                })
            )
        }
        fetchData()
    }, [])
    // console.log(certinfo)
    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/singlecert/${id}`,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };
        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    const data = response.data[0];
                    setcertinfo({
                        cert_title: data.title,
                        credits: data.credits,
                        number_of_courses: data.number_of_courses,
                        dateapproved: data.dateapproved,
                        approvedby: data.approvedby,
                        nucdateapproval: data.nucdateapproval,
                        launchdate: data.launchdate,
                        track: data.track,
                    })
                    console.log(data)
                    return data;

                })
                .catch(function (error) {
                    console.log(error);
                });
        }

        fetchData()
    }, [])
    const editCert = async (e) => {

        e.preventDefault()
        setNotify('loading')

        const formData = new FormData();
        formData.append("cert_title", certinfo.cert_title);
        formData.append("center_id", dets.uniq_id);
        formData.append("credits", certinfo.credits);
        formData.append("number_of_courses", certinfo.number_of_courses);
        formData.append("dateapproved", certinfo.dateapproved);
        formData.append("approvedby", certinfo.approvedby);
        formData.append("nucdateapproval", certinfo.nucdateapproval);
        formData.append("launchdate", certinfo.launchdate);
        formData.append("track", certinfo.track);
        formData.append("file", certinfo.doc);
        formData.append("schedule", certinfo.schedule);
        formData.append("cert_id", id);


        const editCert = async () => {
            try {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/update_cert`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                const activity = `Edited ${certinfo.cert_title} Certificate`;
                saveActivity(activity);
                setNotify('Certification Updated Succesfully')
                Swal.fire({
                    title: 'Certification Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                window.location.href = '/certificates/all'
            } catch (error) {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close'
                })
            }
        }
        editCert();
    };
    return (
        <>
            {
                notify == 'loading' && (
                    <p className="text-success text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="py-4">
                Edit Certificate
            </h3>
            <form className="card p-4" action="" onSubmit={editCert}>
                <div className="row">
                    <div className="mb-3 col-6">
                        <label htmlFor="session">Certificate Title/Diploma/Degree</label>
                        <input onChange={(e) => setcertinfo(
                            { ...certinfo, cert_title: e.target.value })} value={certinfo.cert_title} required type="text" name="cert_title" className="form-control" />
                    </div>
                    <div className="mb-3 col-6">
                        <label htmlFor="session_end">Track </label>
                        <select name="programme"
                            onChange={(e) => setcertinfo(
                                { ...certinfo, track: e.target.value })} value={certinfo.track}
                            required className="form-select" aria-label="Default select example">
                            <option selected>Select track</option>
                            {Array.isArray(tracks) && tracks.map(tracks => {
                                return (
                                    <option key={tracks.id} value={tracks.id}>{tracks.title}</option>)
                            })

                            }
                        </select>
                    </div>
                    {/* <div className="mb-3 col-6">
                        <label htmlFor="session_end">Total Number of Credits</label>
                        <input value={certinfo.credits} onChange={(e) => setcertinfo(
                            { ...certinfo, credits: e.target.value })} placeholder="leave empty if not available" type="number" name="course_code" className="form-control" />
                    </div> */}
                    {
                        certinfo.track == '1' || certinfo.track == '2' ?
                            <div className="mb-3 col-6">
                                <label htmlFor="session_end">Total Number of Courses</label>
                                <input onChange={(e) => setcertinfo(
                                    { ...certinfo, number_of_courses: e.target.value })} placeholder="leave empty if not available" type="number" name="number_of_courses" className="form-control" />
                            </div>
                            :
                            <div className="mb-3 col-6">
                                <label htmlFor="session_end">Total Number of Credits</label>
                                <input onChange={(e) => setcertinfo(
                                    { ...certinfo, credits: e.target.value })} placeholder="leave empty if not available" type="number" name="course_code" className="form-control" />
                            </div>

                    }

                    <div className="mb-3 col-6">
                        <label htmlFor="session_end">Date Approved by FU</label>
                        <input value={certinfo.dateapproved} onChange={(e) => setcertinfo(
                            { ...certinfo, dateapproved: e.target.value })} placeholder="leave empty if not available" type="date" name="course_code" className="form-control" />
                    </div>
                    <div className="mb-3 col-6">
                        <label htmlFor="session_end">Aprroved By</label>
                        <input value={certinfo.approvedby} onChange={(e) => setcertinfo(
                            { ...certinfo, approvedby: e.target.value })} placeholder="leave empty if not available" type="text" name="course_code" className="form-control" />
                    </div>
                    <div className="mb-3 col-6">
                        <label htmlFor="session_end">Date of NUC Approval </label>
                        <input value={certinfo.nucdateapproval} onChange={(e) => setcertinfo(
                            { ...certinfo, nucdateapproval: e.target.value })} placeholder="leave empty if not available" type="date" name="course_code" className="form-control" />
                    </div>
                    <div className="mb-3 col-6">
                        <label htmlFor="session_end">Academic Schedule</label>
                        <select onChange={(e) => setcertinfo(
                            { ...certinfo, schedule: e.target.value })} value={certinfo.schedule} placeholder="leave empty if not available" type="number" name="course_code" className="form-control">

                            <option value="none" key="">---select academic schedule---</option>
                            <option value="part-time" key="">Part Time</option>
                            <option value="full-time" key="">Full Time</option>

                        </select>
                    </div>
                    <div className="mb-3 col-6">
                        <label htmlFor="session_end">Date of Actual Launch</label>
                        <input onChange={(e) => setcertinfo(
                            { ...certinfo, launchdate: e.target.value })} value={certinfo.launchdate} placeholder="leave empty if not available" type="date" name="course_code" className="form-control" />
                    </div>
                </div>

                {
                    certinfo.track == 1 || certinfo.track == 2 ? '' :
                        <div className="mb-3 p-5" >
                            <label htmlFor="session_end">Upload Supporting Document of Approval</label>
                            <input onChange={(e) => setcertinfo(
                                { ...certinfo, doc: e.target.files[0] })} placeholder="leave empty if not available" type="file" name="course_code" className="form-control" required />
                        </div>
                }
                <div className="col-5 m-auto singleSubmits">
                    <button type="submit" className="btn rounded-0  text-info w-100">Update</button>
                </div>
            </form>
        </>

    )
}

export default EditCertDets
