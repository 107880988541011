import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";



function EditSessionComp() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [notify, setNotify] = useState(' ');
    const [sesInfo, setsesInfo] = useState({
        center_id: " ",
        session: " ",
        session_start: " ",
        session_end: " ",
    });
    useEffect(() => {
        const sessionFetcher = () => {
            const session = `${process.env.REACT_APP_BASE_URL}/api/singsession/${id}`
            const getsession = axios.get(session);


            axios.all([getsession]).then(
                axios.spread((...allData) => {
                    // const allcourses = allData[0].data.session;
                    const singses = allData[0].data;
                    // setsession(allcourses)


                    setsesInfo({
                        session: singses.title,
                        session_start: singses.start_date,
                        session_end: singses.end_date,
                    })
                })
            )

        }

        sessionFetcher();
    }, [])


    const handleEditSession = async (e) => {
        e.preventDefault()

        var urlencoded = new URLSearchParams();
        urlencoded.append("center_id", dets.uniq_id);
        urlencoded.append("session", sesInfo.session);
        urlencoded.append("session_start", sesInfo.session_start);
        urlencoded.append("session_end", sesInfo.session_end);
        urlencoded.append("session_id", id);



        var requestOptions = {
            method: 'POST',
            body: urlencoded,
            redirect: 'follow'
        };
        setNotify('loading')

        const editSession = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/editsession`, requestOptions)
            const data = await response.json()
            const status = response.status;
            if (status == 200) {
                setNotify('Session Edited Succesfully')

                var formdata = new FormData();
                if (dets.permission_type == 'supper') {
                    formdata.append("username", "Super Admin");
                } else {
                    formdata.append("username", dets.name);
                }
                formdata.append("activity", `Edited a Session`);
                formdata.append("center_id", dets.uniq_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));

                Swal.fire({
                    title: 'Session Edited Successfully',
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                navigate('/session/all');
            } else {
                setNotify('Error Occured!!!')
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close'
                })
            }
        }
        editSession()
    };


    return (<>
        {
            notify == 'loading' && (
                <p className="text-success text-center fw-bold"><CircularProgress /></p>
            )
        }
        {
            notify != ' ' && (
                <p className="text-success text-center fw-bold">{notify}</p>)
        }
        <h3 className="py-4">
            Edit Session
        </h3>
        <form className="card p-4" action="" onSubmit={handleEditSession}>
            <div className="mb-3">
                <label htmlFor="session">Session</label>
                <input value={sesInfo.session} onChange={(e) => setsesInfo(
                    { ...sesInfo, session: e.target.value })} type="text" name="session" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="session_start">Session Start</label>
                <input value={sesInfo.session_start} onChange={(e) => setsesInfo(
                    { ...sesInfo, session_start: e.target.value })} type="date" name="session_start" className="form-control" />
            </div>
            <div className="mb-3">
                <label htmlFor="session_end">Session End</label>
                <input value={sesInfo.session_end} onChange={(e) => setsesInfo(
                    { ...sesInfo, session_end: e.target.value })} type="date" name="session_end" className="form-control" />
            </div>
            <div className="col-5 m-auto singleSubmits">
                <button type="submit" className="btn rounded-0  text-info w-100"> Update</button>
            </div>
        </form>
    </>);
}

export default EditSessionComp;