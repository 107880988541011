import { Check } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";



function AllSession(props) {
    const { details, bearer } = props;
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));


    const [sessiony, setSessiony] = useState(' ')

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/getallsession/${dets.uniq_id}`,
            headers: {
                'Authorization': `Bearer ${bearer}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        const fetchData = () => {
            axios(config)
                .then(function (response) {



                    setSessiony(response.data.reverse())
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData()

    }, [loading])

    // console.log(sessiony)
    // SetSession Current
    function setSessionCont(param) {
        setLoading(true)

        var requestOptions = {
            method: 'get',

            redirect: 'follow'
        };

        const fetcher = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/makeactive/${param}`, requestOptions)
            const data = await response.json()
            setLoading(false)

            var formdata = new FormData();
            if (dets.permission_type == 'supper') {
                formdata.append("username", "Super Admin");
            } else {
                formdata.append("username", dets.name);
            }
            formdata.append("activity", `Made A Session Active`);
            formdata.append("center_id", dets.uniq_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
            return data;
        }
        fetcher()
    }
    function hideSession(param) {

        setLoading(true)

        var requestOptions = {
            method: 'get',
            redirect: 'follow'
        };

        const fetcher = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/deletesession/${param}`, requestOptions)
            const data = await response.json()
            setLoading(false)
            var formdata = new FormData();
            if (dets.permission_type == 'supper') {
                formdata.append("username", "Super Admin");
            } else {
                formdata.append("username", dets.name);
            }
            formdata.append("activity", `Deleted a Session`)
            formdata.append("center_id", dets.uniq_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));
            return data;
        }
        fetcher()
    }
    return (<>
        <div className='d-flex align-items-center justify-content-between py-4'>
            <p>Registered Sessions</p>
        </div>
        <div className="bg-info p-4 shadow rounded-0 table-responsive">
            <table className="tableData table table-striped table-sm table-hover  ">
                <thead>
                    <tr>
                        <th>SESSION</th>
                        <th>START DATE</th>
                        <th>END DATE</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(sessiony) &&
                        sessiony.map(session => {
                            return (
                                <tr className='align-items-center '>
                                    <td>{session.title}</td>
                                    <td>{session.start_date}</td>
                                    <td>{session.end_date} </td>
                                    <td>
                                        {session.status == 'inactive' ?
                                            <button onClick={() => setSessionCont(`${session.id}`)} className='btn btn-sm btn-success d-flex align-items-center'>
                                                {
                                                    loading ? <span><CircularProgress size='1rem' color="inherit" /></span> :
                                                        <span></span>
                                                }make current</button> :

                                            <p className="text-success">Current Session</p>

                                        }

                                    </td>
                                    {
                                        dets.permission_type != 'View' &&
                                        <>
                                            <td>


                                                <a href={`/editsession/${session.id}`}>
                                                    <button className="btn btn-primary btn-sm">
                                                        Edit Session
                                                    </button>
                                                </a>

                                            </td>
                                            <td>
                                                <button onClick={() =>
                                                    hideSession(`${session.id}`)} className="btn btn-danger btn-sm">
                                                    Delete
                                                </button>
                                            </td>
                                        </>
                                    }
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    </>);
}

export default AllSession;