import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { saveActivity } from "../../utilities/fetchutil";

function SettingsComponent() {
    const navigate = useNavigate();
    const [display, SetDisplay] = useState("profile");

    const [dets, setDets] = useState(JSON.parse(localStorage.getItem("user")));

    const [users, setUsers] = useState("");
    const [rand, setRand] = useState("");

    const [correct, setCorrect] = useState(false);
    const [profile, setProfile] = useState({
        name: dets.name,
        email: dets.email,
        Password: "",
        address: dets.address,
        zone: dets.zone,
        phone_number: dets.phone_number,
    });
    const [newuser, SetUser] = useState({
        name: "",
        email: " ",
        password: " ",
        type: "View",
        address: dets.address,
        zone: dets.zone,
        phone_number: "",
    });
    useEffect(() => {
        var requestOptions = {
            method: "GET",
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/getcenteruser/${dets.uniq_id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setUsers(result.data);
            })
            .catch((error) => console.log("error", error));
    }, [rand]);
    function userchange() {
        navigate("/settings/addusers");
    }
    function profileChange() {
        navigate("/settings");
        // SetDisplay('profile');
    }
    function con_Password(e) {
        let query = e.target.value;

        if (query != profile.Password) {
            setCorrect(false);
        } else {
            setCorrect(true);
        }
    }

    function editProfile(event) {
        event.preventDefault();

        var formdata = new FormData();
        formdata.append("email", profile.email);
        formdata.append("id", dets.uniq_id);
        formdata.append("name", profile.name);
        formdata.append("address", profile.address);
        formdata.append("type", dets.main_type);
        formdata.append("phone_number", profile.phone_number);
        formdata.append("center_user_id", dets.id);


        if (profile.Password == "") {
        } else {
            formdata.append("password", profile.Password);
        }
        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/updateProfileCenters`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                if (result.status == 'success') {
                    localStorage.setItem("user", JSON.stringify(result.data));
                    const activity = `${dets.email}(${dets.name}) Modified and Changed Their Password`;
                    saveActivity(activity);
                    window.location.reload();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Update Error",
                        text: "The Profile has not been updated! ",
                        timer: 1000,
                    });
                }
            })
            .catch((error) => console.log("error", error));
    }
    function handleReg(e) {
        e.preventDefault();

        var formdata = new FormData();
        formdata.append("name", newuser.name);
        formdata.append("email", newuser.email);
        formdata.append("address", dets.address);
        formdata.append("phone_number", newuser.phone_number);
        formdata.append("zone", dets.zone);
        formdata.append("password", newuser.password);
        formdata.append("id", dets.uniq_id);
        formdata.append("type", newuser.type);

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/creatCenterUser`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                Swal.fire({
                    icon: "Success",
                    title: "user added",
                    text: "user added ",
                    timer: 1000,
                });
                setRand(Math.random());
            })
            .catch((error) => console.log("error", error));
    }

    function deleteuser(id) {
        var requestOptions = {
            method: "get",
            // body: formdata,
            redirect: "follow",
        };

        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/deleteCenterUser/${id}`,
            requestOptions
        )
            .then((response) => response.json())
            .then((result) => {
                setRand(Math.random());

                Swal.fire({
                    icon: "success",
                    title: "Successful",
                    text: "User Deleted",
                    timer: 1000,
                });
            })
            .catch((error) => console.log("error", error));
    }
    return (
        <>
            <div className="my-5 py-4 row bg-white shadow">
                <div className="col-2">
                    <p
                        className={
                            display === "profile"
                                ? "border-end rounded-2 border-2 border-success p-2"
                                : " "
                        }
                        onClick={profileChange}
                    >
                        Profile Settings
                    </p>
                    {dets.permission_type != "View" && (
                        <>
                            <p
                                className={
                                    display === "profile"
                                        ? " "
                                        : "border-end rounded-2 border-2 border-success p-2"
                                }
                                onClick={userchange}
                            >
                                User Permission
                            </p>
                            <p
                                className={
                                    display === "profile"
                                        ? " "
                                        : "border-end rounded-2 border-2 border-success p-2"
                                }
                                onClick={() => {
                                    navigate("/activitylogs");
                                }}
                            >
                                Activity Logs
                            </p>
                        </>
                    )}
                </div>
                <div className="col-9">
                    <div className={display === "profile" ? " " : "d-none"}>
                        {/* Profile Div */}

                        <div>
                            {" "}
                            <h2>{dets.uniq_id}</h2>
                        </div>
                        <form className="" onSubmit={editProfile}>
                            <div className="mb-3">
                                <label>Name</label>
                                <input
                                    value={profile.name}
                                    onChange={(e) =>
                                        setProfile({ ...profile, name: e.target.value })
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="enter name"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Email Address</label>
                                <input
                                    value={profile.email}
                                    onChange={(e) =>
                                        setProfile({ ...profile, email: e.target.value })
                                    }
                                    type="email"
                                    className="form-control"
                                    placeholder="enter email address"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Phone Number </label>
                                <input
                                    value={profile.phone_number}
                                    onChange={(e) =>
                                        setProfile({ ...profile, phone_number: e.target.value })
                                    }
                                    type="number"
                                    className="form-control"
                                    placeholder="enter mobile number"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Address</label>
                                <input
                                    value={profile.address}
                                    onChange={(e) =>
                                        setProfile({ ...profile, address: e.target.value })
                                    }
                                    type="text"
                                    className="form-control"
                                    placeholder="enter  address"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Zone</label>
                                <input
                                    value={profile.zone}
                                    onChange={(e) =>
                                        setProfile({ ...profile, zone: e.target.value })
                                    }
                                    type="text"
                                    className="form-control"
                                    disabled
                                    placeholder="enter  address"
                                />
                            </div>
                            <div className="mb-3">
                                <label>
                                    Old Password -- leave blank if you are not changing--
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="enter old password"
                                />
                            </div>
                            <div className="mb-3">
                                <label>New Password</label>
                                <input
                                    autocomplete="new-password"
                                    type="password"
                                    onChange={(e) =>
                                        setProfile({ ...profile, Password: e.target.value })
                                    }
                                    className="form-control"
                                    placeholder="enter new password"
                                />
                            </div>
                            <div className="mb-3">
                                <label>Confirm New Password</label>
                                <input
                                    type="password"
                                    onChange={con_Password}
                                    className={
                                        correct != true
                                            ? "form-control bg-danger"
                                            : "form-control bg-success"
                                    }
                                    placeholder="confirm password"
                                />
                            </div>

                            <button className="btn btn-success shadow">Save</button>
                        </form>
                    </div>

                    <div className={display === "user" ? " " : "d-none"}>
                        {/* User Permission */}

                        <div className="row">
                            <div className="col-3">
                                <h5>Add new user</h5>
                                <form className="" onSubmit={handleReg}>
                                    <div className="mb-3">
                                        <label>Name</label>
                                        <input
                                            onChange={(e) =>
                                                SetUser({ ...newuser, name: e.target.value })
                                            }
                                            type="text"
                                            className="form-control"
                                            required
                                            placeholder="enter name"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Email</label>
                                        <input
                                            type="email"
                                            onChange={(e) =>
                                                SetUser({ ...newuser, email: e.target.value })
                                            }
                                            className="form-control"
                                            required
                                            placeholder="enter email address"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Phone Number</label>
                                        <input
                                            type="number"
                                            onChange={(e) =>
                                                SetUser({ ...newuser, phone_number: e.target.value })
                                            }
                                            className="form-control"
                                            required
                                            placeholder="enter mobile number"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Password</label>
                                        <input
                                            type="text"
                                            onChange={(e) =>
                                                SetUser({ ...newuser, password: e.target.value })
                                            }
                                            className="form-control"
                                            required
                                            placeholder="enter your password"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label>Type</label>
                                        <select
                                            type="text"
                                            onChange={(e) =>
                                                SetUser({ ...newuser, type: e.target.value })
                                            }
                                            className="form-control"
                                            required
                                            placeholder="select user type"
                                        >
                                            <option selected value="View" key="">
                                                View
                                            </option>
                                            <option value="Edit" key="">
                                                Edit
                                            </option>
                                            <option value="supper" key="">
                                                Supper
                                            </option>
                                        </select>
                                    </div>

                                    <button className="btn btn-primary">Save</button>
                                </form>
                            </div>

                            <div className="col-9 table-responsive">
                                <h5> Admin Users</h5>

                                <table className="table table-sm table-hover">
                                    <thead>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Permission type</th>
                                        <th>Actions</th>
                                    </thead>
                                    <tbody>
                                        {Array.isArray(users) &&
                                            users.map((row, key) => {
                                                return (
                                                    <tr key="">
                                                        <td>{row.name}</td>
                                                        <td>{row.email}</td>
                                                        <td>{row.permission_type}</td>

                                                        <td>
                                                            <div className="btn-group">
                                                                <button className="btn   btn-sm btn-primary">
                                                                    Edit
                                                                </button>
                                                                <button
                                                                    onClick={() => deleteuser(row.id)}
                                                                    className=" btn-sm btn btn-danger"
                                                                >
                                                                    Delete
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SettingsComponent;
