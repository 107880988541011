import { Edit, Email, Phone } from "@mui/icons-material";
import { Avatar, CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AllNavs from "../../../components/allNavs";
import NewtopNAv from "../../../components/centers/dashboardtwo/newtopNav";
import Secondnav from "../../../components/centers/dashboardtwo/secondsidenav";
import TopPilsItems from "../../../components/centers/toppills";
import EditCertDets from "../../../components/othercomp/editCert";
// import Logos from '../../../public/image/spesee.ng'


function CertEdit() {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [notify, setNotify] = useState(' ');
    const [tracks, setTracks] = useState('');

    const [stdData, setStdData] = useState(' ')
    const [stdQual, setStdQual] = useState(' ')

    const [certinfo, setcertinfo] = useState({
        cert_title: " ",
        credits: " ",
        dateapproved: " ",
        approvedby: " ",
        nucdateapproval: " ",
        launchdate: " ",
        track: " ",
        schedule: " ",
    });

    const [showNav, setShowNav] = useState(false)
    function navState(ClickedNav) {
        setShowNav(ClickedNav)
    }
    const [storedTime, setStoredTime] = useState(new Date(localStorage.getItem('sessionTime')));
    var currentTime = new Date();

    var timeDifference = currentTime - storedTime;
    var expirationTime = 60 * 60 * 1000; // 1 hour

    if (timeDifference > expirationTime || dets == null || dets == '') {
        console.log('Session expired');
        window.location.href = "/";
    }
    return <>
        <div className="container-fluid">
            <div>
                <div className="p-3">
                    <NewtopNAv naviState={navState} />
                </div>
            </div>
            <div className="row ">
                <div className={(showNav == true) ? `d-block d-lg-none col-md-3 d-flex allNavSide` : `d-none`}>
                    <AllNavs />
                </div>
                <div className="col-1 subNav row">
                    <AllNavs />
                </div>
                <div className="col-12 col-lg-11 regMain">
                    <div className="p-2">
                        <TopPilsItems />
                    </div>

                    <div className="row pt-3">
                        <div className="d-none d-lg-block col-2 border bg-info border-1">
                            <Secondnav />
                        </div>

                        <div className="col-12 col-lg-10 p-lg-3 regMain">
                            <EditCertDets />



                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default CertEdit;