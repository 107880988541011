import { useState, useEffect } from "react";
import React from "react";
import Swal from 'sweetalert2';
import { RemoveRedEye, Visibility, VisibilityOff } from '@mui/icons-material'
import { CircularProgress } from "@mui/material";
import FooterComp from "../components/footer";

import RightsideCenters from "../components/centers/loginComponent/rigthSide";
import speseeLogo from '../image/spesee.png'
import worldbank from '../image/worldBank.png'
import nuc from '../image/nuclogo.png'
import { useNavigate } from "react-router-dom";

function LoginComponent() {
    const navigate = useNavigate();
    const [showPass, setShowPass] = useState(false)
    const [notify, setNotify] = useState(' ');
    const [loading, setLoading] = useState(' ');
    const [userInfo, setUserInfo] = useState({ email: " ", password: " " });
    function redirect() {
        navigate('/otp')
    }
    var currentTime = new Date();
    const handleSubmit = async (e) => {
        e.preventDefault()
        var urlencoded = new URLSearchParams();
        urlencoded.append("email", userInfo.email);
        urlencoded.append("password", userInfo.password);

        var requestOptions = {
            method: 'POST',
            body: urlencoded,
            redirect: 'follow'
        };
        setLoading('loading')
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/logincenter`, requestOptions)
        const data = await response.json()
        const status = response.status;
        // console.log(data)
        if (status == 200) {
            setNotify('')
            Swal.fire({
                title: 'Logged In Succesfully',
                icon: 'success',
                confirmButtonText: 'close',
                timer: 1000,
            })
            localStorage.setItem("bearer_token", data.bearer_token);
            localStorage.setItem("user", JSON.stringify(data.user));
            localStorage.setItem('sessionTime', currentTime);
            redirect()
        } else if (status == 201) {
            setNotify(data.status)
            setLoading('')
            Swal.fire({
                title: data.status,
                icon: 'error',
                confirmButtonText: 'close',
                timer: 1000,
            })
        } else {
            setNotify(data.status)
        }
    }

    return (<>
        <div className="conttt">

            <div className="loginCont py-sm-0">
                <div className="row align-items-stretch">
                    <div className="col-12 col-lg-6 order-1 0rder-lg-0  p-5">
                        <div className="center_login">
                            <div className="row align-items-center">
                                <div className="col-12">
                                    <h3 className="mb-5 fw-bold">
                                        SPESSE Centres of Excellence Portal
                                    </h3>
                                    {
                                        notify != ' ' && (
                                            <p className="text-danger fw-bold">{notify}</p>)
                                    }
                                    <h2>Sign In</h2>
                                    <p>Enter the email and password provided to log in.</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-4  col-12 col-lg-6 ">
                                            <label htmlFor="email">Email</label>
                                            <input value={userInfo.email} type="email" name="email"
                                                onChange={(e) => setUserInfo(
                                                    { ...userInfo, email: e.target.value })}
                                                // value={userInfo.email}
                                                placeholder='enter your email address'
                                                className="form-control rounded-0" id="" />
                                        </div>
                                        <div className="mb-4 col-12 col-lg-6 ">
                                            <label htmlFor="password">Password  </label>
                                            <div className="input-group">
                                                <input type={showPass ? 'text' : 'password'} name="password"
                                                    onChange={(e) => setUserInfo(
                                                        { ...userInfo, password: e.target.value })}
                                                    // value={userInfo.password}
                                                    className="form-control rounded-0 " id="" />
                                                <span className="btn btn-dark" onClick={() => { setShowPass(!showPass) }}>
                                                    {showPass ? <VisibilityOff /> : <Visibility />}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <div className="form-check mb-4 form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                            <label htmlFor=""> Remember Me</label> 
                                        </div> */}
                                        <div className="mb-4 col-12 col-lg-6 text-center">
                                            <button className="btn w-100 d-flex justify-content-center align-items-center rounded-0"
                                                type="submit" name="" id="">
                                                {
                                                    loading == 'loading' && (<CircularProgress size='1.5rem' color="inherit" />)
                                                }Login
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="text-center mt-5 pt-5 ">
                            <FooterComp />
                        </div>
                    </div>
                    <div className="col-12 carousellogos col-lg-6 d-flex justify-content-center order-0 py-5 order-lg-2">
                        <RightsideCenters spesee={speseeLogo} nuclogo={nuc} worldBank={worldbank} />
                    </div>
                </div>



                {/* <div>
                    <Chatbot />
                </div> */}
            </div >
        </div>

    </>

    );
}
export default LoginComponent;