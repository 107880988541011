export const USER_DATA = JSON.parse(localStorage.getItem('user'))
export const BEARER_TOKEN = localStorage.getItem('bearer_token')


export async function isLoggenIn() {
    const storedTime = new Date(localStorage.getItem('sessionTime'));
    var currentTime = new Date();
    var timeDifference = currentTime - storedTime;
    var expirationTime = 60 * 60 * 1000; // 1 hour
    if (timeDifference > expirationTime || USER_DATA == null || USER_DATA == '') {
        window.location.href = "/";
        return false;
    } else {
        return {
            status: true,
            user: USER_DATA,
            token: BEARER_TOKEN,
        }
    }
}

export const API_URL = 'https://host.spesse.edu.ng';



