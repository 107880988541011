import { CModal, CModalBody, CModalHeader } from '@coreui/react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import speseeLogo from '../../image/spesee.png'


function Ratings() {
    const navigate = useNavigate()
    const [modal, setModal] = useState(false);
    const [courseDet, setCourstDet] = useState({
        id: '',
        ratings: '',
    });
    const toggle = () => {
        setModal(!modal);
    }
    // const { email } = router.query;
    const [email, setEmail] = useState(window.localStorage.getItem("ratingsFeed"));
    useEffect(() => {
        if (window) {
            setEmail(window.localStorage.getItem("ratingsFeed"));
        }
    }, []);
    const [display, setDisplay] = useState('not_rated')
    const [student, setStudent] = useState('')
    const [Center, setCenter] = useState('')
    const [rand, SetRand] = useState('');
    const [notRatedCourses, setNoneRatedCourses] = useState('');

    // console.log(notRatedCourses)
    const [Ratedcourses, setRatedCourses] = useState('');
    const [answers, setAnswers] = useState({
        completed_course: "",
        expectations: '',
        mode_of_delivery_convenient: "",
        duration_reasonable: "",
        course_relevant: '',
        where_would_it_be_relevant: 'Nill',
        skills_applicable: '',
        delivery_appropiate: '',
        which_delivery_appropiate: 'nil',
        ratings_mode_of_delivery: '',
        ratings_course_quality: '',
        ratings_course_duration: '',
        harrasment: '',
        harrasment_kind: 'Nil',
        report_harrasment: 'Nil',
        harrasment_resolved: 'Nil',
        harrasment_suggestion: 'Nil',
    });

    console.log(answers);
    const isAnswersNotEmpty = () => {
        for (const key in answers) {
            if (answers[key] === '') {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {

        var formdata = new FormData();
        formdata.append("email", email);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/showdetails`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setStudent(result.user);
                setCenter(result.center)
                setRatedCourses(result.ratedcourse);
                setNoneRatedCourses(result.noneratedcourse)
                // console.log(result)
            })
            .catch(error => console.log('error', error));
    }, [rand, email]);
    function handleSubmiit() {
        var id = courseDet.id;
        var rating = courseDet.ratings;


        var formdata = new FormData();
        formdata.append("id", id);
        formdata.append("rating", rating);
        formdata.append("student_id", student.id);
        formdata.append("completed_course", answers.completed_course);
        formdata.append("expectations", answers.expectations);
        formdata.append("mode_of_delivery_convenient", answers.mode_of_delivery_convenient);
        formdata.append("duration_reasonable", answers.duration_reasonable);
        formdata.append("course_relevant", answers.course_relevant);
        formdata.append("where_would_it_be_relevant", answers.where_would_it_be_relevant);
        formdata.append("skills_applicable", answers.skills_applicable);
        formdata.append("delivery_appropiate", answers.delivery_appropiate);
        formdata.append("which_delivery_appropiate", answers.which_delivery_appropiate);
        formdata.append("ratings_mode_of_delivery", answers.ratings_mode_of_delivery);
        formdata.append("ratings_course_quality", answers.ratings_course_quality);
        formdata.append("ratings_course_duration", answers.ratings_course_duration);
        formdata.append("harrasment", answers.harrasment);
        formdata.append("harrasment_kind", answers.harrasment_kind);
        formdata.append("report_harrasment", answers.report_harrasment);
        formdata.append("harrasment_resolved", answers.harrasment_resolved);
        formdata.append("harrasment_suggestion", answers.harrasment_suggestion);
        formdata.append("overall_satisfaction", answers.rating);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        if (isAnswersNotEmpty()) {

            fetch(`${process.env.REACT_APP_BASE_URL}/api/giveratings`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    Swal.fire({
                        title: 'Success',
                        text: result,
                        timer: 1000,
                    })
                    // toggle()
                    // SetRand(Math.random())

                    // console.log(result)
                }
                )
                .catch(error => console.log('error', error));

        } else {
            Swal.fire({
                title: 'Kindly Fill Your Form Properly',
                text: 'Some Response are not recorded',
                timer: 1000,
            })

        }
    }

    function ratingSet(id, rating) {
        toggle()
        setCourstDet(
            {
                ratings: rating,
                id: id,
            }
        )
        setAnswers({ overall_satisfaction: rating })
    }


    if (email == null || email == '') {
        navigate('/studentfeedback');
    }
    return (
        <>
            <div>
                <div className='d-flex shadow-sm p-3 align-items-center justify-content-between'>
                    <div>
                        <img height={70} width={50} src={speseeLogo} className="img-fluid" alt="SPESSE logo" />
                        {Center.name}
                    </div>
                    <div>
                        {student.first_name}
                        {' ' + student.last_name}
                        <div>
                            {student.phone_number}
                        </div>
                        <div>
                            {student.email_address}
                        </div>
                        <div>
                            {student.gender}
                        </div>
                        <div>
                            <button onClick={() => {
                                window.localStorage.removeItem('ratingsFeed');
                                window.location.reload();
                            }} className='btn btn-danger'>Log Out</button>
                        </div>
                    </div>

                </div>

                <div className='py-3'>
                    <div className='d-flex justify-content-around'>
                        <button onClick={() => {
                            setDisplay('not_rated')
                        }} className={display == 'not_rated' ? 'btn btn-success btn-sm shadow-sm' : 'btn btn-outline-success btn-sm shadow-sm'} >Not Yet Rated</button>
                        <button onClick={() => {
                            setDisplay('rated')
                        }} className={display == 'rated' ? 'btn btn-success btn-sm shadow-sm' : 'btn btn-outline-success btn-sm shadow-sm'}>Rated Courses</button>

                    </div>
                </div>
                <div className={display == 'rated' ? 'px-3' : 'd-none'}>
                    <table className='table table-sm table-hover '>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Course Title</th>
                                <th>Course Code</th>
                                <th>Feedback</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                Array.isArray(Ratedcourses) && Ratedcourses.map(data => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{Ratedcourses.indexOf(data) + 1}</td>
                                                <td>
                                                    {data.course_title}
                                                </td>
                                                <td>
                                                    {data.course_code}
                                                </td>
                                                <td>
                                                    {data.ratings}
                                                    {/* <div className='btn-group'>
                                                            <button onClick={() => {
                                                                ratingSet(data.rating_id, 'Very Satisfied')
                                                            }} className='btn btn-outline-success btn-sm'>Very Satisfied</button>
                                                            <button onClick={() => {
                                                                ratingSet(data.rating_id, 'Satisfied')
                                                            }} className='btn btn-outline-success btn-sm'>Satisfied</button>
                                                            <button onClick={() => {
                                                                ratingSet(data.rating_id, 'Not Satisfied')
                                                            }} className='btn btn-outline-primary btn-sm'>Not Satisfied</button>
                                                            <button onClick={() => {
                                                                ratingSet(data.rating_id, 'Very Dissatisfied')
                                                            }} className='btn btn-outline-danger btn-sm'>Very Dissatisfied</button>
                                                        </div> */}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                )}
                        </tbody>
                    </table>



                </div>
                <div className={display == 'not_rated' ? 'px-3' : 'd-none'}>
                    <table className='table table-sm table-hover '>
                        <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Course Title</th>
                                <th>Course Code</th>
                                <th>Action</th>
                            </tr>

                        </thead>
                        <tbody>
                            {
                                Array.isArray(notRatedCourses) && notRatedCourses.map(data => {
                                    return (
                                        <>
                                            <tr>
                                                <td>{notRatedCourses.indexOf(data) + 1}</td>
                                                <td>
                                                    {data.course_title}
                                                </td>
                                                <td>
                                                    {data.course_code}
                                                </td>
                                                <td>2
                                                    <div className='btn-group'>
                                                        <button onClick={() => {
                                                            ratingSet(data.launched_course_id, 'Very Satisfied')
                                                        }} className='btn btn-outline-success btn-sm'>Very Satisfied</button>
                                                        <button onClick={() => {
                                                            ratingSet(data.launched_course_id, 'Satisfied')
                                                        }} className='btn btn-outline-success btn-sm'>Satisfied</button>
                                                        <button onClick={() => {
                                                            ratingSet(data.launched_course_id, 'Not Satisfied')
                                                        }} className='btn btn-outline-primary btn-sm'>Not Satisfied</button>
                                                        <button onClick={() => {
                                                            ratingSet(data.launched_course_id, 'Very Dissatisfied')
                                                        }} className='btn btn-outline-danger btn-sm'>Very Dissatisfied</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                }
                                )}
                        </tbody>
                    </table>

                </div>


            </div>

            <div className={modal == true ? 'd-block modalDesign py-4 position-absolute top-0 z-3  ' : 'modalDesign d-none'}>

                <form className='row m-auto p-3 col-6 bg-light shadow overflow-scroll'>
                    <button type='button' className='btn mb-3 btn-danger col-3 btn-sm' onClick={() => {
                        toggle()
                    }}>Close</button>
                    <div className="mb-3">
                        <label htmlFor="name">Did you complete the course you enrolled for? </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, completed_course: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>

                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name">Did course content meet your
                            expectations?
                        </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, expectations: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name">Was the mode of delivery convenient for learning? </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, mode_of_delivery_convenient: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name">Was the duration of the course reasonable? </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, duration_reasonable: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name">Is the course content relevant for your work/Career development? </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, course_relevant: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    {
                        answers.course_relevant == 'no' &&
                        <div className="mb-3">
                            <label htmlFor="name">If “No”, what aspect do you think would be useful for your work/career but were not covered? </label>
                            <textarea onChange={(e) => setAnswers(
                                { ...answers, where_would_it_be_relevant: e.target.value })}
                                type="text" name="name" className="form-control" >
                            </textarea>
                        </div>
                    }
                    <div className="mb-3">
                        <label htmlFor="name">Are you willing to apply the new skills you have learnt from this course going forward?  </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, skills_applicable: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name">Was the mode of delivery used appropriate for the course you enrolled?   </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, delivery_appropiate: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>
                        </select>
                    </div>
                    {
                        answers.delivery_appropiate == 'no' &&
                        <div className="mb-3">
                            <label htmlFor="name">If “No”, what mode of delivery would have been most appropriate?  </label>
                            <textarea onChange={(e) => setAnswers(
                                { ...answers, which_delivery_appropiate: e.target.value })}
                                type="text" name="name" className="form-control" >
                            </textarea>
                        </div>
                    }

                    <div className="mb-3">
                        <label htmlFor="name">Are you willing to apply the new skills you have learnt from this course going forward?  </label>
                        <select onChange={(e) => setAnswers(
                            { ...answers, skills_applicable: e.target.value })}
                            required type="text" name="name" className="form-control" >
                            <option selected value="" key="">Kindly select an option</option>
                            <option value="yes" key="">Yes</option>
                            <option value="no" key="">No</option>


                        </select>
                    </div>

                    <div className='mb-4'>
                        <h4>
                            How would you rate the effectiveness and delivery of the course you took
                        </h4>
                    </div>

                    <div>


                        <div className="mb-3">
                            <label htmlFor="name">How would you rate the mode of delivery for the online course you enrolled</label>
                            <select onChange={(e) => setAnswers(
                                { ...answers, ratings_mode_of_delivery: e.target.value })}
                                required type="text" name="name" className="form-control" >
                                <option selected value="" key="">Kindly select an option</option>
                                <option value="Very Satisfied" key="">Very Satisfied
                                </option>
                                <option value="Satisfied" key="">Satisfied</option>
                                <option value="Fair" key="">Fair</option>
                                <option value="Not Satisfied" key="">Not Satisfied</option>
                                <option value="Very Dissatisfied" key="">Very Dissatisfied</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="name">How would you rate the quality course content? </label>
                            <select onChange={(e) => setAnswers(
                                { ...answers, ratings_course_quality: e.target.value })}
                                required type="text" name="name" className="form-control" >
                                <option selected value="" key="">Kindly select an option</option>
                                <option value="Very Satisfied" key="">Very Satisfied
                                </option>
                                <option value="Satisfied" key="">Satisfied</option>
                                <option value="Fair" key="">Fair</option>
                                <option value="Not Satisfied" key="">Not Satisfied</option>
                                <option value="Very Dissatisfied" key="">Very Dissatisfied</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="name">How would you rate the duration of the course?  </label>
                            <select onChange={(e) => setAnswers(
                                { ...answers, ratings_course_duration: e.target.value })}
                                required type="text" name="name" className="form-control" >
                                <option selected value="" key="">Kindly select an option</option>
                                <option value="Very Satisfied" key="">Very Satisfied
                                </option>
                                <option value="Satisfied" key="">Satisfied</option>
                                <option value="Fair" key="">Fair</option>
                                <option value="Not Satisfied" key="">Not Satisfied</option>
                                <option value="Very Dissatisfied" key="">Very Dissatisfied</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="name">Did you face any form of harassment or discrimination by faculties since you enrolled for the program?  </label>
                            <select onChange={(e) => setAnswers(
                                { ...answers, harrasment: e.target.value })}
                                required type="text" name="name" className="form-control" >
                                <option selected value="" key="">Kindly select an option</option>
                                <option value="yes" key="">Yes</option>
                                <option value="no" key="">No</option>

                            </select>
                        </div>
                        {answers.harrasment == 'yes' &&


                            <div>
                                <div className="mb-3">
                                    <label htmlFor="name">What kind for harassment did you face? </label>
                                    <textarea onChange={(e) => setAnswers(
                                        { ...answers, harrasment_kind: e.target.value })}
                                        required type="text" name="name" className="form-control" >

                                    </textarea>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="name">Did you report such incident to appropriate authorities? </label>
                                    <select onChange={(e) => setAnswers(
                                        { ...answers, report_harrasment: e.target.value })}
                                        required type="text" name="name" className="form-control" >
                                        <option selected value="" key="">Kindly select an option</option>
                                        <option value="yes" key="">Yes</option>
                                        <option value="no" key="">No</option>

                                    </select>
                                </div>


                                {answers.report_harrasment == 'yes' &&

                                    <div>
                                        <div className="mb-3">
                                            <label htmlFor="name">If “Yes”, was the issue resolved? </label>
                                            <select onChange={(e) => setAnswers(
                                                { ...answers, harrasment_resolved: e.target.value })}
                                                required type="text" name="name" className="form-control" >
                                                <option selected value="" key="">Kindly select an option</option>
                                                <option value="yes" key="">Yes</option>
                                                <option value="no" key="">No</option>

                                            </select>
                                        </div>
                                    </div>
                                }
                                <div className="mb-3">
                                    <label htmlFor="name">What will you suggest to be done differently to improve grievance redress mechanism in the centers? </label>
                                    <textarea onChange={(e) => setAnswers(
                                        { ...answers, harrasment_suggestion: e.target.value })}
                                        type="text" name="name" className="form-control" >

                                    </textarea>
                                </div>
                            </div>

                        }
                        <div className="mb-3">
                            <label htmlFor="name">Overall, how would you rate your satisfaction of the PES track course you have enrolled for?</label>
                            <select value={answers.overall_satisfaction} onChange={(e) => setAnswers(
                                { ...answers, overall_satisfaction: e.target.value })}
                                disabled type="text" name="name" className="form-control" >
                                <option selected value="Very Satisfied" key="">Very Satisfied
                                </option>
                                <option value="Satisfied" key="">Satisfied</option>
                                <option value="Fair" key="">Fair</option>
                                <option value="Not Satisfied" key="">Not Satisfied</option>
                                <option value="Very Dissatisfied" key="">Very Dissatisfied</option>
                            </select>
                        </div>

                    </div>

                    <button type='button' onClick={handleSubmiit} className='btn btn-sm btn-success'>Submit</button>
                </form>
            </div>

        </>
    )


}

export default Ratings
