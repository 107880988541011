import { Key, Menu, Notifications, NotificationsActiveOutlined, Settings } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { isLoggenIn } from "../../../utilities/auth";

function NewtopNAv(props) {
    const { naviState } = props;
    const [navOpener, setNavOpener] = useState(false)
    function openNav() {
        setNavOpener(!navOpener)
        naviState(navOpener)
    }
    const [bearer_key, setBearer_key] = useState(' ');
    const [dets, setDets] = useState({});
    isLoggenIn().then((response) => {
        if (response.status == true) {
            setDets(response.user)
            setBearer_key(response.token)
        }
    })
    return (<>
        <div className="row justify-content-between align-items-center">
            <div className="d-flex col-12 col-md-6 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="SecondNavLogo">
                        <img width={200} height={200} src={'https://host.spesse.edu.ng/images/logo.png'}
                            alt="Logo Img" />
                    </div>
                    <div>
                        <span className="fs-4 fw-bold">SPESSE Project Dashboard</span>
                    </div>
                </div>

                <button onClick={openNav} className=" border border-1  d-md-none rounded-1 p-2">
                    <span><Menu /></span>
                </button>
            </div>
            <div className="d-flex col-12 col-md-6 align-items-center justify-content-between">

                <a href={'/settings'}>
                    <div className="d-flex align-items-center">
                        <Avatar />
                        <div className="row px-3">
                            <span className="col-12">{dets.name}</span>
                        </div>
                    </div>
                </a>
                {/* <div className="col-2">
                    <Link href={'/centers/settings'}>
                        <span><Settings /></span>
                    </Link>
                </div> */}
                <div>
                    <button className="btn btn-sm btn-outline-dark" onClick={() => {
                        window.localStorage.removeItem('user')
                        window.location.reload()
                    }} type="button"><Key /> Sign Out</button>
                </div>
                <div onClick={openNav} className=" border border-1 d-none d-md-block d-lg-none rounded-1 p-2">
                    <span><Menu /></span>
                </div>
            </div>
        </div>
    </>);
}

export default NewtopNAv;