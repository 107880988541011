
import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function AllCreatedCourses() {
    const [courses, setCourses] = useState([])
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [update, setUpdate] = useState('');
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/courses/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setCourses(result.reverse()))
            .catch(error => console.log('error', error));
    }, [update])


    function search(e) {
        const query = e.target.value
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("query", query);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchcourses`, requestOptions)
            .then(response => response.json())
            .then(result => setCourses(result.reverse()))
            .catch(error => console.log('error', error));
    }
    function deletecourse(param) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/deletecourse/${param}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                Swal.fire({
                    title: 'Certification Updated Successfully',
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                setUpdate(Math.random());
            })
            .catch(error => console.log('error', error));
    }
    return (<>
        <div>
            <div className='d-flex align-items-center justify-content-between py-4'>
                <p>All Courses</p>

                <input onChange={search} type="text" className='form-control w-50' placeholder='search with course title or code' />
            </div>
            <div className="bg-info p-4 shadow rounded-0  table-responsive ">

                <div>
                    <h6 className="fw-bold">Total No of Created Courses: {courses.length}</h6>
                </div>
                <table className="tableData table-hover table-striped table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>COURSE TITLE</th>
                            <th>COURSE CODE</th>
                            <th>COURSE CREDIT</th>

                            {dets.permission_type != 'View' &&

                                <th>ACTIONS</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {courses === ' ' ? <p><CircularProgress /></p> :
                            courses.map(course => {
                                return (
                                    <tr className='align-items-center '>
                                        <td>{courses.indexOf(course) + 1}</td>
                                        <td>{course.course_title}</td>
                                        <td>{course.course_code}</td>
                                        <td>{course.credits}</td>

                                        {dets.permission_type != 'View' &&

                                            <td>
                                                <div className='btn-group'>
                                                    <a href={`/editcourse/${course.id}`}>
                                                        <button className='btn btn-sm btn-success p-2'>
                                                            Edit
                                                        </button>
                                                    </a>
                                                    <button onClick={() => deletecourse(course.id)}
                                                        className='btn btn-sm btn-danger p-2'>
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </div >

    </>)
}

export default AllCreatedCourses;