
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Secondnav() {
    const regx = /\d|1\d|2[0-9]/g;
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [path, setPath] = useState('');
    useEffect(() => {
        const path = window.location.pathname
        setPath(path);
    }, []);

    if (path.includes("change-level") || path.includes("graduating-students"))
        return (<>
            <div className="secondNav">
                <ul>
                    {dets.permission_type != 'View' &&
                        <Link to={'/change-level'}>
                            <li className={(path == '/change-level' ? 'activated' : '')}>
                                Manage Current Level
                            </li>
                        </Link>
                    }
                    {/* <Link to={'/graduating-students'}>
                        <li className={(path.includes('/graduating-students') ? 'activated' : '')}>
                            Manage Graduating Students
                        </li>
                    </Link> */}
                </ul>
            </div>
        </>)
    if (path.includes("trainee"))
        return (<>
            <div className="secondNav">
                <ul>
                    {dets.permission_type != 'View' &&
                        <Link to={'/trainees'}>
                            <li className={(path == '/trainees' ? 'activated' : '')}>
                                Add Trainee
                            </li>
                        </Link>
                    }
                    <Link to={'/trainees/all'}>
                        <li className={(path.includes('/trainees/all') ? 'activated' : '')}>
                            All Trainees
                        </li>
                    </Link>
                </ul>
            </div>
        </>)
    if (path.includes("session"))
        return (<>
            <div className="secondNav">
                <ul>
                    {dets.permission_type != 'View' &&
                        <Link to={'/session'}>
                            <li className={(path === '/session' ? 'activated' : '')}>
                                Add Session
                            </li>
                        </Link>
                    }
                    <Link to={'/session/all'}>
                        <li className={(path === '/session/all' ? 'activated' : '')}>
                            View Sessions
                        </li>
                    </Link>
                </ul>
            </div >
        </>)
    if (path.includes("faculties") || path === "/faculties")
        return (<>
            <div className="secondNav">
                <ul>
                    {dets.permission_type != 'View' &&
                        <Link to={'/faculties'}>
                            <li className={(path === '/faculties' ? 'activated' : '')}>
                                Add Faculties
                            </li>
                        </Link>
                    }
                    <Link to={'/faculties/all'}>
                        <li className={(path === '/faculties/all' ? 'activated' : '')}>
                            View Faculties
                        </li>
                    </Link>
                </ul>
            </div >
        </>)

    if (path === "/course" || path.includes(`/editcourse`) || path.includes(`/editlaunchedcourse`) || path.includes('registered') || path.includes('launchcourse') || path.includes('/allcourses') || path.includes('certificates') || path.includes('offerings'))
        return (<>
            <div className="secondNav">
                {dets.permission_type != 'View' &&

                    <Link to={'/offerings'}>
                        <li className={(path === '/offerings' ? 'activated' : '')}>
                            Modify Offerings
                        </li>
                    </Link>
                }
                {dets.permission_type != 'View' &&

                    <Link to={'/certificates'}>
                        <li className={(path === '/certificates' ? 'activated' : '')}>
                            Create Certificate
                        </li>
                    </Link>
                }
                <Link to={'/certificates/all'}>
                    <li className={(path === '/certificates/all' ? 'activated' : '')}>
                        All Certificate
                    </li>
                </Link>
                {
                    dets.permission_type != 'View' &&

                    <Link to={'/course'}>
                        <li className={(path === '/course' ? 'activated' : '')}>
                            Create Course
                        </li>
                    </Link>
                }
                <Link to={'/allcourses'}>
                    <li className={(path === '/allcourses' ? 'activated' : '')}>
                        All Courses
                    </li>
                </Link>
                {
                    dets.permission_type != 'View' &&

                    <Link to={'/launchcourse'}>
                        <li className={(path === '/launchcourse' ? 'activated' : '')}>
                            Launch Course
                        </li>
                    </Link>
                }
                <Link to={'/launchcourse/launchedcourses'}>
                    <li className={(path === '/launchcourse/launchedcourses' ? 'activated' : '')}>
                        Courses Launched
                    </li>
                </Link>
            </div >
        </>)
    if (path.includes('/studentcoursereg') || path === '/facultycoursereg')
        return (
            <>   <div className="secondNav">

                <Link to={'/studentcoursereg'}>
                    <li className={(path === '/studentcoursereg' ? 'activated' : '')}>
                        Student Registration
                    </li>
                </Link>
                <Link to={'/facultycoursereg'}>
                    <li className={(path === '/facultycoursereg' ? 'activated' : '')}>
                        Faculty Registration
                    </li>
                </Link>
            </div ></>
        )

    if (path.includes('/trackreports'))
        return (
            <>
                <div className="secondNav">
                    {/* <Link to={'/trackreports/tracka'}>
                        <li className={(path === '/trackreports/tracka' ? 'activated' : '')}>
                            Track A
                        </li>
                    </Link>
                    <Link to={'/trackreports/trackb'}>
                        <li className={(path === '/trackreports/tracka' ? 'activated' : '')}>
                            Track B
                        </li>
                    </Link> */}
                </div></>
        )
    if (path.includes('/import'))
        return (
            <>
                <div className="secondNav">
                    <Link to={'/importdata'}>
                        <li className={(path === '/importdata' ? 'activated' : '')}>
                            Import Data
                        </li>
                    </Link>
                </div></>
        )
    if (path.includes('partners'))
        return (<>
            <div className="secondNav pb-3">
                <Link to={'/partners'}>
                    <li className={(path === '/partners' ? 'activated' : '')}>
                        Add Academic Partners
                    </li>
                </Link>
                <Link to={'/partners/all'}>
                    <li className={(path === '/partners/all' ? 'activated' : '')}>
                        View All Academic Partners
                    </li>
                </Link>
            </div >
        </>)
}
export default Secondnav;