import { useState, useEffect } from "react";
import Swal from 'sweetalert2';
import axios from "axios";
import { CircularProgress } from "@mui/material";
import { Add, PlusOneOutlined, Remove, RemoveCircle } from "@mui/icons-material";
import Home from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";

function StudentRegistration(props) {
    const navigate = useNavigate()
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [notify, setNotify] = useState(' ');

    const [userInfo, setUserInfo] = useState({
        lastname: " ",
        name: " ",
        other_name: " ",
        email: " ",
        phone: " ",
        heighest_qualification: " ",
        qualifications: "",
        age: "",
        sex: "",
        Nationality: " ",
        current_level: "100",
        track: "",
        student_status: "",
        employee: "Nil",
        employee_type: "Nil",
        employment_status: "Nil",
    });
    const [loading, setloading] = useState(false);

    const handleStudentReg = async (e) => {
        e.preventDefault()
        setloading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("first_name", userInfo.name);
        urlencoded.append("other_name", userInfo.other_name);

        urlencoded.append("phone_number", userInfo.phone);
        urlencoded.append("last_name", userInfo.lastname);
        urlencoded.append("email_address", userInfo.email);
        urlencoded.append("gender", userInfo.sex);
        urlencoded.append("age", userInfo.age);
        urlencoded.append("track", userInfo.track);
        urlencoded.append("student_status", userInfo.student_status);
        urlencoded.append("current_level", userInfo.current_level);
        urlencoded.append("nationality", userInfo.Nationality);
        urlencoded.append("highest_qual", userInfo.heighest_qualification);
        urlencoded.append("qualifications", userInfo.qualifications);
        urlencoded.append("employment_stat", userInfo.employment_status);
        urlencoded.append("employement_sector", userInfo.employee_type);
        urlencoded.append("employer", userInfo.employee);
        urlencoded.append("center_id", dets.uniq_id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/add_student`, requestOptions)
        const data = response.json()
        if (response.status === 200) {
            setloading(false);
            Swal.fire({
                title: 'Student Added Succesfully',
                icon: 'success',
                confirmButtonText: 'close'

            })
            navigate('/trainees/all');
        } else {
            Swal.fire({
                title: 'Student Already Registered',
                icon: 'error',
                confirmButtonText: 'close'
            })
        }
    }

    useEffect(() => {
        if (userInfo.student_status === 'New') {
            setUserInfo({ ...userInfo, current_level: '100' })
        }
    }, [userInfo.student_status,])
    return (
        <>
            {
                notify === 'loading' && (
                    <p className="text-success  text-center fw-bold"><CircularProgress /></p>
                )
            }
            {
                notify != ' ' && (
                    <p className="text-success text-center fw-bold">{notify}</p>)
            }
            <h3 className="py-4 d-flex align-items-center">
                Trainee Registration Information
            </h3>
            <form className="card p-4" action="" onSubmit={handleStudentReg} >
                <fieldset className="row">
                    <legend>Student Status</legend>
                    <div className="col-6 mb-3">
                        <label htmlFor="name">New or Continuing Student</label>
                        <select required name="nationality" onChange={(e) => {
                            setUserInfo({ ...userInfo, student_status: e.target.value })
                        }
                        } class="form-select" aria-label="New or continuing student selection"  >
                            <option selected value={0}>--is student new or continuing--</option>
                            <option value={'New'}>New</option>
                            <option value={'Continuing'}>Continuing</option>
                        </select>
                    </div>
                    {userInfo.student_status !== '' &&
                        <>
                            <div className="col-6 mb-3">
                                <label htmlFor="name">Track</label>
                                <select required name="nationality" onChange={(e) => setUserInfo(
                                    { ...userInfo, track: e.target.value })} class="form-select" aria-label="track select"  >
                                    <option selected value={0}>--select student track--</option>
                                    <option value={1}>Track A</option>
                                    <option value={2}>Track B</option>
                                    <option value={3}>Track C</option>
                                    <option value={4}>Track D</option>
                                    <option value={5}>Track E</option>

                                </select>
                            </div>
                            {
                                userInfo.track === '5' && userInfo.student_status === 'Continuing' &&
                                <div className="col-6 mb-3">
                                    <label htmlFor="name">Level</label>
                                    <select name="programme" onChange={(e) => setUserInfo(
                                        { ...userInfo, current_level: e.target.value })} class="form-select" aria-label="student level select ">
                                        <option selected>Select Level</option>
                                        {/* <option value="100" key="">100 || Level</option> */}
                                        <option value="200" key="">200 || Level</option>
                                        <option value="300" key="">300 || Level</option>
                                        <option value="400" key="">400 || Level</option>
                                        <option value="500" key="">500 || Level</option>
                                    </select>
                                </div>
                            }

                        </>
                    }
                </fieldset>
                <fieldset className="row">
                    <legend>Personal Data</legend>


                    <div className="col-6 mb-3">
                        <label htmlFor="name">First Name</label>
                        <input onChange={(e) => setUserInfo(
                            { ...userInfo, name: e.target.value })}
                            required type="text" name="name" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="name">Other Name</label>
                        <input onChange={(e) => setUserInfo(
                            { ...userInfo, other_name: e.target.value })}
                            required type="text" name="other_name" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="lastName">Last Name</label>
                        <input onChange={(e) => setUserInfo(
                            { ...userInfo, lastname: e.target.value })}
                            required type="text" name="lastName" className="form-control" />
                    </div>

                    <div className="col-6 mb-3">
                        <label htmlFor="phone">Telephone number</label>
                        <input onChange={(e) => setUserInfo(
                            { ...userInfo, phone: e.target.value })} type="number" required name="phone" className="form-control" />
                    </div>

                    <div className="col-6 mb-3">
                        <label htmlFor="email">Email</label>
                        <input onChange={(e) => setUserInfo(
                            { ...userInfo, email: e.target.value })} type="text" required name="email" className="form-control" />
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="age">Age</label>
                        <input required onChange={(e) => setUserInfo(
                            { ...userInfo, age: e.target.value })} type="number" name="age" className="form-control"  >
                        </input>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="nationality">Nationality</label>
                        <select required name="nationality" onChange={(e) => setUserInfo(
                            { ...userInfo, Nationality: e.target.value })} class="form-select" aria-label="Default select example"  >
                            <option selected value={0}>Select your Nationality</option>
                            <option value={'Nigeria'}>Nigeria</option>
                            <option value={'Foreign'}>Foreign</option>
                        </select>
                    </div>



                    <div className=" col-6 mb-3">
                        <label htmlFor="sex">Gender</label>
                        <select required onChange={(e) => setUserInfo(
                            { ...userInfo, sex: e.target.value })} type="text" name="address" className="form-control" >
                            <option value="none" selected>Select your gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                    <div className="col-6 mb-3">
                        <label htmlFor="highest_qualifcation">Highest Qualifcation</label>
                        <select required name="highest_qualifcation" onChange={(e) => setUserInfo(
                            { ...userInfo, heighest_qualification: e.target.value }
                        )} class="form-select" aria-label="Default select example">
                            <option value={'None'}>Select your qualification</option>
                            <option value="SSCE">SSCE(Senior School Certificate Examination)</option>
                            <option value="ND">ND(National Diploma)</option>
                            <option value="HND">HND(Higher National Diploma)</option>
                            <option value="BTECH">BTECH(Bachelor of Technology)</option>
                            <option value="BENG">BENG(Bachelor of Engineering)</option>
                            <option value="BSC">BSC(Bachelor of Science)</option>
                            <option value="MSC">MSC(Master of Science)</option>
                            <option value="MBA">MBA(Master of Business Adminstration)</option>
                            <option value="PHD">PHD(Philosophiae Doctor)</option>
                        </select>
                    </div>
                    <div className="col mb-3">
                        <label htmlFor="qualifcations">All Qualifications</label>
                        <input placeholder="enter your qualifications seperated by comma" required name="qualifcations" onChange={(e) => setUserInfo(
                            { ...userInfo, qualifications: e.target.value }
                        )} class="form-control" aria-label="Default select example" />


                    </div>


                </fieldset>

                <fieldset>
                    <legend>
                        Employment Information
                    </legend>
                    <div className="mb-3">
                        <label htmlFor="employmentStatus">Employment Status</label>
                        <select required name="employmentStatus" onChange={(e) => setUserInfo(
                            { ...userInfo, employment_status: e.target.value })} class="form-select" aria-label="employement status" >
                            <option selected  >What's your employent Status</option>
                            <option value={'Employed'}>Employed</option>
                            <option value={'Unemployed'} >Unemployed</option>
                        </select>
                    </div>
                    {
                        userInfo.employment_status === 'Employed' &&
                        <div className="mb-3 row ">
                            <div className="col-6 mb-3">
                                <label htmlFor="Employee">Employee</label>
                                <input placeholder="where do you work" required name="employee" onChange={(e) => setUserInfo(
                                    { ...userInfo, employee: e.target.value })} class="form-control" aria-label="" />
                            </div>
                            <div className="col-6 mb-3">
                                <label htmlFor="EmployeeType">Employee Type</label>
                                <select required name="employeeType" onChange={(e) => setUserInfo(
                                    { ...userInfo, employee_type: e.target.value })} class="form-control" aria-label="Default select example">
                                    <option selected value={'None'}> What type of organization do you work for</option>
                                    <option value="Private">Private</option>
                                    <option value="Public">Public</option>
                                </select>
                            </div>
                        </div>
                    }
                    <div className="col-5 m-auto singleSubmits">
                        {
                            loading ? <p class="text-center"><CircularProgress
                                color="inherit" /></p> : ' '
                        }
                        <button type="submit" className="btn rounded-0  text-info w-100">
                            Submit</button>
                    </div>
                </fieldset>
            </form>
        </>
    );
}
export default StudentRegistration;