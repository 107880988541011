import { CircularProgress } from "@mui/material";

import { useEffect, useState } from "react";
import Swal from "sweetalert2";

function AllCert() {
    const [selected, setSelec] = useState('');
    const [cert, setCert] = useState([])
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));
    const [update, setUpdate] = useState('');
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/certificate/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setCert(result.reverse()))
            .catch(error => console.log('error', error));

    }, [update])
    function search(e) {
        const query = e.target.value
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("query", query);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchcert`, requestOptions)
            .then(response => response.json())
            .then(result => setCert(result.reverse()))
            .catch(error => console.log('error', error));
    }

    function deletecert(param) {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var requestOptions = {
            method: 'get',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/deletecert/${param}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                var formdata = new FormData();
                if (dets.permission_type == 'supper') {
                    formdata.append("username", "Super Admin");
                } else {
                    formdata.append("username", dets.name);
                }
                formdata.append("activity", `Deleted ${selected} Certificate`)
                formdata.append("center_id", dets.uniq_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${process.env.REACT_APP_BASE_URL}/api/activitylogger`, requestOptions)
                    .then(response => response.text())
                    .then(result => console.log(result))
                    .catch(error => console.log('error', error));

                Swal.fire({
                    title: 'Certificate Deleted Succesfully',
                    icon: 'success',
                    confirmButtonText: 'close'
                })
                setUpdate(Math.random())
            }
            )
            .catch(error => console.log('error', error));
    }

    return (<>
        <div>
            <div className='d-flex align-items-center justify-content-between py-4'>
                <p>All Certificate</p>

                <input onChange={search} type="text" className='form-control w-50' placeholder='search with certificate title' />
            </div>
            <div className="bg-info p-4 shadow rounded-0  table-responsive ">

                <div>
                    <h6 className="fw-bold">Total No of Created cert: {cert.length}</h6>
                </div>
                <table className="tableData table-hover table-striped table">
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>CERTIFICATE TITLE</th>
                            <th>TOTAL CREDITS/NO OF COURSES</th>
                            <th>DATE APPROVED</th>
                            <th>APPROVAL DOC</th>
                            <th>LAUNCH DATE</th>
                            <th>TRACK</th>
                            {dets.permission_type != 'View' &&
                                <th>ACTIONS</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {cert === ' ' ? <p><CircularProgress /></p> :
                            cert.map(certi => {
                                return (
                                    <tr className='align-items-center '>
                                        <td>{cert.indexOf(certi) + 1}</td>
                                        <td>{certi.title}</td>
                                        <td>{certi.credits}/{certi.number_of_courses}</td>
                                        <td>{certi.dateapproved}</td>
                                        <td><a target={'_blank'} href={`${process.env.REACT_APP_BASE_URL}/approvals/${certi.approve_doc}`}>{certi.approve_doc}</a></td>

                                        <td>{certi.launchdate}</td>
                                        <td>{certi.track_title}</td>
                                        {dets.permission_type != 'View' &&

                                            <td>
                                                <div className='btn-group'>
                                                    <a href={`/editcertificates/${certi.id}`} >
                                                        <button className='btn btn-sm  btn-success p-2'>
                                                            Edit
                                                        </button>
                                                    </a>

                                                    <button onClick={() => {
                                                        setSelec(certi.title)
                                                        deletecert(certi.id)
                                                    }}
                                                        className='btn btn-sm btn-danger p-2'>
                                                        Delete
                                                    </button>
                                                </div>
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
            </div>
        </div >

    </>)
}

export default AllCert;