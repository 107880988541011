import { Check } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";



function AllPartnersComp(props) {
    const { details, bearer } = props;
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));


    const [sessiony, setSessiony] = useState(' ')
    const [partners, setPartners] = useState('')


    const [loading, setLoading] = useState(false)

    useEffect(() => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/allcenterpartners/${dets.uniq_id}`,
            headers: {
                'Authorization': `Bearer ${bearer}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        const fetchData = () => {
            axios(config)
                .then(function (response) {

                    console.log(response)
                    setPartners(response.data.message)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData()

    }, [loading])
    function hideSession(id) {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_BASE_URL}/api/deletecenterpartner/${id}`,
            headers: {
                'Authorization': `Bearer ${bearer}`,
                "Content-Type": "application/x-www-form-urlencoded",
            },
        };

        const fetchData = () => {
            axios(config)
                .then(function (response) {
                    Swal.fire({
                        title: 'Deleted',
                        text: response.data.message,
                        timer: 1000,
                    })

                    setLoading(true)
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        fetchData()
    }
    return (<>
        <div className='d-flex align-items-center justify-content-between py-4'>
            <p>Registered Partners</p>
        </div>
        <div className="bg-info p-4 shadow rounded-0 table-responsive">
            <table className="tableData table table-striped table-sm table-hover  ">
                <thead>
                    <tr>
                        <th>LOGO</th>
                        <th>NAME</th>
                        <th>WEBSITE</th>
                        <th>MOU</th>
                    </tr>
                </thead>
                <tbody>
                    {Array.isArray(partners) &&
                        partners.map(partner => {
                            return (
                                <tr className='align-items-center '>
                                    <td className="col-1">
                                        <a href={`${process.env.REACT_APP_BASE_URL}/centerpartners/logo/${partner.logo}`}>
                                            <img className="img-fluid" height={50} src={`${process.env.REACT_APP_BASE_URL}/centerpartners/logo/${partner.logo}`} alt="Logo" />
                                        </a></td>
                                    <td>{partner.name}</td>
                                    <td>{partner.web_url} </td>
                                    <td> <a href={`${process.env.REACT_APP_BASE_URL}/centerpartners/mou/${partner.mou}`}>{partner.mou}</a></td>
                                    {
                                        dets.permission_type != 'View' &&
                                        <>
                                            <td>


                                                <a href={`/editpartners/${partner.id}`}>
                                                    <button className="btn btn-primary btn-sm">
                                                        Edit Partner
                                                    </button>
                                                </a>

                                            </td>
                                            <td>
                                                <button onClick={() =>
                                                    hideSession(`${partner.id}`)} className="btn btn-danger btn-sm">
                                                    Delete
                                                </button>
                                            </td>
                                        </>
                                    }
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    </>);
}

export default AllPartnersComp;