import HomeIcon from '@mui/icons-material/Home';
import { Apartment, AppRegistration, Book, CalendarMonth, ChangeCircle, DepartureBoard, Handshake, Home, HomeMax, HouseSiding, ImportExport, Key, LeaderboardRounded, List, LocalFireDepartment, MapSharp, MenuBook, Note, NoteAddOutlined, People, PeopleOutlineOutlined, Person2Outlined, Person3Sharp, PersonOffOutlined, PunchClock, Report, RocketLaunch, School, Settings, SwitchAccessShortcutSharp, Watch } from '@mui/icons-material';
import { CTooltip } from '@coreui/react';
import { useEffect, useState } from 'react';

function FirstsideNav() {
    const [path, setPath] = useState('');
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    useEffect(() => {
        const path = window.location.pathname
        setPath(path);
    }, []);
    return (<>
        <ul className='firstSide text-center p-2'>

            <CTooltip content="Dashboard" placement='right'>
                <li className={(path.includes('dashboard') ? 'activated' : '')}>
                    <a href='/dashboard'>
                        <span><HomeIcon /></span>
                    </a>
                </li>
            </CTooltip>
            <CTooltip content="Academic Session" placement='right'>
                <li className={(path.includes('session') ? 'activated' : '')}>
                    <a href='/session/all'>
                        <span><PunchClock /></span>
                    </a>

                </li>
            </CTooltip>
            <CTooltip content="Course" placement='right'>
                <li className={(path.includes('launchcourse') || (path.includes('launchedcourse')) || (path.includes('certificates')) || (path.includes('/editcourse')) || (path == '/allcourses') || (path == '/course') ? 'activated' : '')}>
                    <a href="/launchcourse/launchedcourses">
                        <span><RocketLaunch /></span>
                    </a>
                </li>
            </CTooltip>
            <CTooltip content="Trainees" placement='right'>
                <li className={(path.includes('trainees') || (path.includes('/viewtrainee')) || (path.includes('/edittrainee')) ? 'activated' : '')}>
                    <a href='/trainees/all'><span><PeopleOutlineOutlined /></span>
                    </a>
                </li>
            </CTooltip>
            <CTooltip content="Faculty" placement='right'>
                <li className={(path.includes('faculties') ? 'activated' : '')}>
                    <a href='/faculties/all'>
                        <span><School /></span>
                    </a>

                </li>
            </CTooltip>
            {dets.permission_type != 'View' &&

                <CTooltip content="Course Registration" placement='right'>
                    <li className={(path.includes('coursereg') ? 'activated' : '')}>
                        <a href='/studentcoursereg'>
                            <span><AppRegistration /></span>
                        </a>

                    </li>
                </CTooltip>
            }

            {dets.permission_type != 'View' &&

                <CTooltip content="Partners" placement='right'>
                    <li className={(path.includes('partners') ? 'activated' : '')}>
                        <a href='/partners/all'>
                            <span><Handshake /></span>
                        </a>

                    </li>
                </CTooltip>
            }

            <CTooltip content="Reports for Tracks" placement='right'>
                <li className={(path.includes('trackreports') ? 'activated' : '')}>
                    <a href='/trackreports'>
                        <span><Note /></span>
                    </a>

                </li>
            </CTooltip>
            {dets.permission_type != 'View' &&

                <CTooltip content="Import/Export Data" placement='right'>
                    <li className={(path.includes('import') ? 'activated' : '')}>
                        <a href='/importdata'>
                            <span><ImportExport /></span>
                        </a>
                    </li>
                </CTooltip>
            }
            {dets.permission_type != 'View' &&
                <CTooltip content="Manage Level and Graduation Status" placement='right'>
                    <li className={(path.includes('change-level') || path.includes('graduating-students') ? 'activated' : '')}>
                        <a href='/change-level'>
                            <span><SwitchAccessShortcutSharp /></span>
                        </a>
                    </li>
                </CTooltip>
            }
        </ul>

        <ul className='firstsidebottom text-center p-2 '>
            <CTooltip content="Profile" placement='right'>

                <li className={(path.includes('profile') ? 'activated' : '')}>
                    <a href="/settings">
                        <Person3Sharp />
                    </a>
                </li>
            </CTooltip>
            <CTooltip content="Settings" placement='right'>

                <li className={(path.includes('settings') ? 'activated settings' : 'settings')}>
                    <a href="/settings">
                        <Settings />
                    </a>
                </li>
            </CTooltip>
        </ul>
    </>);
}

export default FirstsideNav;