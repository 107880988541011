import { Avatar, CircularProgress, Input } from '@mui/material';

import { Component, useEffect, useState } from 'react';
import axios from 'axios';
import { Add, EmailOutlined, FiveG } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';


// Main Func
function StudentsList(props) {
    const [dets, setDets] = useState(JSON.parse(localStorage.getItem('user')));

    // const { details, bearer } = props
    const [programs, setProgram] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [department, setDepartment] = useState([]);
    const [filter, setfilter] = useState(false);
    const [Inst, setInst] = useState([]);
    const [filterData, setFilterData] = useState(' ');
    const [datali, setDatali] = useState(' ');
    const [delay, setDelay] = useState(' ')
    const [del, setDe] = useState('')


    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(20);



    const totalPages = Math.ceil(datali.length / itemsPerPage);

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/students/${dets.uniq_id}`, requestOptions)
            .then(response => response.json())
            .then(result => setDatali(result.reverse()))
            .catch(error => console.log('error', error));
    }, [del])

    const currentItems = datali.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handlePageClick = selectedPage => {
        setCurrentPage(selectedPage.selected);
    };

    function deleteStud(param) {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        const deleteStudent = async () => {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/delete_student/ ${param}`, requestOptions)
            const data = await response.json()
            if (response.status === 200) {
                Swal.fire({
                    title: 'Student Deleted Succesfully',
                    icon: 'success',
                    confirmButtonText: 'close',
                    timer: 1000
                })
                setDe(Math.random());
            } else if (response.status === 400) {
                Swal.fire({
                    title: 'An Error Occured',
                    icon: 'error',
                    confirmButtonText: 'close',
                    timer: 1000
                })
            }
            return data;
        }
        deleteStudent()
    }

    function searchStudent(e) {
        let query = e.target.value;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("center", dets.uniq_id);
        urlencoded.append("query", query);
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/searchstudents`, requestOptions)
            .then(response => response.json())
            .then(result => setDatali(result))
            .catch(error => console.log('error', error));
    }
    return (
        <div>
            <div className='d-flex align-items-center justify-content-between py-4'>
                <p>Registered Trainees</p>
                <div className='text-end'>
                    <div className='d-flex p-2'>
                        <input onChange={searchStudent} type="text" placeholder='search for trainee' className='form-control' />
                    </div>
                </div>

            </div>
            <div className="bg-info p-4 shadow rounded-0 table-responsive">

                <div>
                    <h6 className="fw-bold">Total No of Trainees:  {datali.length}</h6>
                </div>
                <table className="tableData table table-striped table-sm table-hover  ">
                    <thead>
                        <tr>
                            <th>S/N</th>

                            <th>FIRST NAME</th>
                            <th>OTHER NAME</th>
                            <th>LAST NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>TRACK</th>
                            <th>LEVEL</th>
                            <th>ACTIONS</th>
                            {/* <th>REGISTER COURSES</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {
                            Array.isArray(currentItems) &&
                            currentItems.map(student => {
                                return (
                                    <tr key={student.id} className='align-items-center '>
                                        <td>{datali.indexOf(student) + 1}</td>
                                        <td>{student.first_name}</td>
                                        <td>{student.other_name}</td>

                                        <td>{student.last_name}</td>

                                        <td>{student.email_address}</td>
                                        <td>{student.phone_number}</td>
                                        <td>{student.track_title}</td>
                                        <td>{student.track === 5 ? student.current_level : 'N/A'}</td>

                                        <td >
                                            <div className='btn-group'>

                                                <a href={`/viewtrainee/${student.id}`} >
                                                    <button className='btn btn-sm btn-success'>View</button>
                                                </a>
                                                {
                                                    dets.permission_type != 'View' &&
                                                    <button onClick={() => deleteStud(`${student.id}`)} className="btn btn-sm btn-danger">
                                                        Delete
                                                    </button>
                                                }
                                            </div>

                                        </td>
                                    </tr>
                                )
                            })

                        }

                    </tbody>
                </table>
                {totalPages > 1 && (
                    <ReactPaginate
                        previousLabel={'Previous'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        pageCount={totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                )}
            </div>
        </div>
    )

}

export default StudentsList;