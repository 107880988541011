// import logo from './logo.svg';
import React, { useState } from 'react';
import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginComponent from './pages/Login';
import Otp from './pages/centers/Otp';

// import Feedback from './studentfeedback/FeedBack'
import ErrorPage from './error-page';
import Dashboard from './pages/centers/dashboard';
import CreateCourse from './pages/centers/course';
import CourseEdit from './pages/centers/course/[id]';
import AllCoursesCre from './pages/centers/course/allcourses';
import { routesCreated } from './routesObjects';


const router = createBrowserRouter(
  routesCreated
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
